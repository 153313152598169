var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from "./styles";
import { StepHeader } from "../StepHeader";
import { InfoPeriodDate } from "../RequestInfo/components/Details/components/InfoPeriodDate";
import { formatToBrazilianDate } from "../../../../../utils";
import { useVacationsPeriods } from "./hooks/useVacationsPeriods";
import { Chip, FormControl, makeStyles, MenuItem, Select, } from "@material-ui/core";
import { FaRegCalendarAlt } from "react-icons/fa";
export var useStyles = makeStyles(function () { return ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        "&. MuiSelect-select": {
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
        },
    },
}); });
export function SelectHistoryPeriod(props) {
    var acquisitionPeriods = useVacationsPeriods({
        employeeId: props.employeeId,
    }).acquisitionPeriods;
    var classes = useStyles();
    var handleChange = function (startDate) {
        var period = acquisitionPeriods === null || acquisitionPeriods === void 0 ? void 0 : acquisitionPeriods.find(function (period) { return period.startDate === startDate; });
        if (period) {
            props.onChangePeriod(period);
        }
    };
    return (_jsxs(S.Container, { children: [_jsxs(S.Content, { children: [_jsx(StepHeader, { title: "Per\u00EDodo", description: "Indique qual o per\u00EDodo aquisitivo para qual deseja adicionar um hist\u00F3rico de f\u00E9rias." }), _jsx(FormControl, __assign({ variant: "outlined", size: "small", fullWidth: true }, { children: _jsx(Select, __assign({ classes: { root: classes.root }, value: props.customPeriod.startDate, placeholder: "Per\u00EDodo", onChange: function (event) {
                                handleChange(event.target.value);
                            }, style: { display: "flex", justifyContent: "space-between" } }, { children: acquisitionPeriods === null || acquisitionPeriods === void 0 ? void 0 : acquisitionPeriods.map(function (period) {
                                var hasBalance = Boolean(period.remainingBalance);
                                return (_jsxs(MenuItem, __assign({ disabled: !hasBalance, style: { display: "flex", justifyContent: "space-between" }, value: period.startDate }, { children: [_jsx(S.Period, __assign({ hasBalance: hasBalance }, { children: "".concat(formatToBrazilianDate(period.startDate), " - ").concat(formatToBrazilianDate(period.endDate)) })), _jsx(Chip, { icon: _jsx(FaRegCalendarAlt, { size: 18 }), size: "small", color: period.remainingBalance ? "primary" : "default", label: "".concat(period.remainingBalance, " dias"), style: { padding: 4 } })] })));
                            }) })) }))] }), _jsx(S.SideBar, { children: _jsx(InfoPeriodDate, { title: "Data de admiss\u00E3o", value: formatToBrazilianDate(props.admittanceDate) }) })] }));
}
