import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar as AppBarMaterial , Grid } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import CajuLogo from "../../assets/images/Caju_logo.svg";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

export default function AppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBarMaterial
        position="static"
        elevation={0}
        style={{
          background: "#fff",
          height: 74,
          justifyContent: "center",
          padding: 10,
          borderBottom: `1px solid ${Tokens.neutralColor.medium["700"]}`,
        }}
      >
        <Toolbar>
          <Grid container justifyContent="space-between">
            <img src={CajuLogo} alt={"Logo Caju"} />
            <Button variant="outlined" color="primary" style={{ display: "none" }}>
              Ajuda
            </Button>
          </Grid>
        </Toolbar>
      </AppBarMaterial>
    </div>
  );
}
