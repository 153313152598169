import { useEffect, useState } from "react";

import Forms from "./components/Forms";
import {
  useLocation,
  Location,
  useNavigate,
  useParams,
} from "react-router-dom";
import AppBar from "@/components/AppBar";
import { useSection } from "@/hooks/services/form/useSection";
import { Helmet } from "react-helmet";

interface LocationState {
  sectionId: string;
  isFromSummary?: boolean;
}

interface FormLocation extends Location {
  state: LocationState;
}
export default function FormBySection() {
  const navigate = useNavigate();
  const location: FormLocation = useLocation();
  const locationState = location.state ?? {
    sectionId: "",
    isFromSummary: false,
  };
  const params = useParams<{ token: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const { loading, section } = useSection({
    sectionId: locationState.sectionId,
    token: params.token,
  });

  useEffect(() => {
    if (locationState.sectionId) {
      window.history.replaceState({}, document.title);
    }
    if (!locationState.sectionId) {
      navigate(`/home/token/${params.token}`);
    }
  }, [locationState.sectionId, navigate, params.token]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <div>
      <Helmet>
        <title>{section?.title}</title>
      </Helmet>
      <AppBar />
      <Forms
        isFromSummary={locationState.isFromSummary}
        key={locationState.sectionId}
        forms={section?.forms}
        sectionTitle={section?.title}
        sectionId={section?.id ?? ""}
        token={params.token}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </div>
  );
}
