var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Chip, Tooltip } from "@material-ui/core";
import { getChipsTexts } from "./chipTexts";
export var PriorityTag = function (_a) {
    var deadlineStatus = _a.deadlineStatus, _b = _a.variant, variant = _b === void 0 ? "REGULAR" : _b;
    var _c = getChipsTexts({
        status: deadlineStatus,
        variant: variant,
    }), bgColor = _c.bgColor, title = _c.title, toolTipTitle = _c.toolTipTitle, txtColor = _c.txtColor, IconComponent = _c.icon;
    var chipSize = variant === "REGULAR" ? "medium" : "small";
    if (!title) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(Tooltip, __assign({ title: toolTipTitle }, { children: _jsx(Chip, { label: _jsxs("div", __assign({ style: { display: "flex", alignItems: "center" } }, { children: [IconComponent && (_jsx(IconComponent, { size: 18, style: { marginRight: 5 } })), _jsx("span", __assign({ "data-testid": "tag" }, { children: title }))] })), size: chipSize, style: {
                backgroundColor: bgColor,
                color: txtColor,
                fontWeight: 500,
                fontSize: 14,
            } }) })));
};
