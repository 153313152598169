var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { Accordion, AccordionSummary, makeStyles } from "@material-ui/core";
import { AccordionDetails } from "@material-ui/core";
import styled from "styled-components";
export var useStyles = makeStyles(function () { return ({
    drawerContainer: {
        height: "calc(100vh - 120px)",
        minWidth: "300px",
        overflow: "auto",
    },
    rootAccordion: {
        borderBottom: "unset",
    },
}); });
export var CustomAccordionDetails = styled(AccordionDetails)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0;\n  background-color: ", ";\n"], ["\n  padding: 0;\n  background-color: ", ";\n"])), Tokens.neutralColor.light[1000]);
export var CustomAccordionTitle = styled(AccordionSummary)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0;\n  color: ", ";\n  background: transparent;\n  width: 100%;\n  background-color: ", ";\n"], ["\n  padding: 0;\n  color: ", ";\n  background: transparent;\n  width: 100%;\n  background-color: ", ";\n"])), Tokens.interfaceColor.primary.pure, Tokens.neutralColor.light[1000]);
export var CustomAccordion = styled(Accordion)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  padding: 0;\n  background-color: ", ";\n"], ["\n  width: 100%;\n  padding: 0;\n  background-color: ", ";\n"])), Tokens.neutralColor.light[1000]);
var templateObject_1, templateObject_2, templateObject_3;
