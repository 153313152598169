import { Tokens } from "@caju-beneficios/sponsor-v2-lib/dist/configs/tokens/tokens";
import { LinearProgress, withStyles } from "@material-ui/core";
export var BorderLinearProgress = withStyles(function (theme) { return ({
    root: {
        height: 10,
        position: "sticky",
        zIndex: 999,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
        backgroundColor: Tokens.interfaceColor.primary.pure,
    },
}); })(LinearProgress);
