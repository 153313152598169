var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { TableHead } from "@material-ui/core";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
export var TableHeader = styled(TableHead)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n"], ["\n  background-color: ", ";\n"])), Tokens.neutralColor.light["900"]);
export var Item = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  list-style-type: none;\n  &:hover section {\n    opacity: 1;\n    visibility: visible;\n  }\n"], ["\n  list-style-type: none;\n  &:hover section {\n    opacity: 1;\n    visibility: visible;\n  }\n"])));
export var IconContainer = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 15px;\n  opacity: 0;\n  transition: 0.3s;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 15px;\n  opacity: 0;\n  transition: 0.3s;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
