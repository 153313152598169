export var VacationFollowUpStatus;
(function (VacationFollowUpStatus) {
    VacationFollowUpStatus["AWAITING_APPROVAL"] = "AWAITING_APPROVAL";
    VacationFollowUpStatus["APPROVED_SCHEDULED"] = "APPROVED_SCHEDULED";
    VacationFollowUpStatus["APPROVED_IN_VACATION"] = "APPROVED_IN_VACATION";
    VacationFollowUpStatus["PAST_APPROVED"] = "PAST_APPROVED";
    VacationFollowUpStatus["DENIED"] = "DENIED";
    VacationFollowUpStatus["PENDING_REVIEW"] = "PENDING_REVIEW";
    VacationFollowUpStatus["PAST"] = "PAST";
})(VacationFollowUpStatus || (VacationFollowUpStatus = {}));
