var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formatToBrazilianDate } from "../../utils";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { Avatar, Chip, IconButton, Tooltip } from "@material-ui/core";
import { BiCalendar } from "react-icons/bi";
import SignatureIcon from "../vacations/FollowUpDetailsDrawer/components/Documents/components/SignatureIcon";
import * as S from "./EmployeeVacationRequestCard.styles";
import { FileIcon } from "./FileIcon";
import { ShareIcon } from "./ShareIcon";
import { UserLocation } from "./UserLocation";
import { AnticipationChipSmall } from "../vacations/AnticipationChipSmall";
var EmployeeVacationRequestCardRoot = function (_a) {
    var onClickVacation = _a.onClickVacation, style = _a.style, children = _a.children, vacation = _a.vacation;
    return (_jsx(S.Container, __assign({ style: __assign({}, style), onClick: function () { return onClickVacation(vacation); } }, { children: children })));
};
EmployeeVacationRequestCardRoot.displayName =
    "EmployeeVacationRequestCard.Root";
var EmployeeVacationByManagerRequestCardRoot = function (_a) {
    var onClickVacation = _a.onClickVacation, style = _a.style, children = _a.children, vacation = _a.vacation;
    return (_jsx(S.Container, __assign({ style: __assign({}, style), onClick: function () { return onClickVacation(vacation); } }, { children: children })));
};
EmployeeVacationByManagerRequestCardRoot.displayName =
    "EmployeeVacationByManagerRequestCardRoot.Root";
var EmployeeVacationRequestCardHeader = function (_a) {
    var children = _a.children;
    return _jsx(S.ContainerNameAndPosition, { children: children });
};
EmployeeVacationRequestCardHeader.displayName =
    "EmployeeVacationRequestCard.Header";
var EmployeeVacationRequestCardEmployeeInformation = function (_a) {
    var employee = _a.employee;
    return (_jsxs("div", { children: [_jsx("h2", { children: employee.name }), _jsx("p", { children: employee.position })] }));
};
EmployeeVacationRequestCardEmployeeInformation.displayName =
    "EmployeeVacationRequestCard.Employee";
var EmployeeVacationRequestCardAvatar = function (_a) {
    var profilePictureUrl = _a.profilePictureUrl;
    return (_jsx(Avatar, { src: profilePictureUrl !== null && profilePictureUrl !== void 0 ? profilePictureUrl : "", variant: "square", style: { borderRadius: "4px" } }));
};
EmployeeVacationRequestCardAvatar.displayName =
    "EmployeeVacationRequestCard.Avatar";
var EmployeeVacationRequestCardVacationDays = function (_a) {
    var startDate = _a.startDate, endDate = _a.endDate, durationDays = _a.durationDays, anticipated = _a.anticipated;
    return (_jsx(S.AdmissionInfo, __assign({ style: { flex: 1, width: "100%" } }, { children: _jsxs(S.LineContainer, { children: [_jsxs("div", __assign({ style: { display: "flex" } }, { children: [_jsx(BiCalendar, { size: 20 }), _jsxs("p", { children: [formatToBrazilianDate(startDate), " - ", formatToBrazilianDate(endDate)] })] })), anticipated ? _jsx(AnticipationChipSmall, {}) : null, _jsx(Chip, { label: "".concat(durationDays, " dias"), color: "primary", size: "small", style: {
                        width: "fit-content",
                        fontWeight: "500",
                        marginLeft: 10
                    } })] }) })));
};
EmployeeVacationRequestCardVacationDays.displayName =
    "EmployeeVacationRequestCard.VacationDays";
var EmployeeVacationRequestCardDocuments = function (_a) {
    var documentsCount = _a.documentsCount, signaturesCompleted = _a.signaturesCompleted;
    return (_jsxs(S.LineContainer, { children: [_jsxs("div", { children: [_jsx(FileIcon, { size: 20, style: { margin: 0 }, color: Tokens.neutralColor.dark[100] }), documentsCount && documentsCount > 0 ? (_jsxs(_Fragment, { children: [_jsx("p", { children: "Documentos" }), _jsx("p", __assign({ style: { color: Tokens.neutralColor.medium[400] } }, { children: "(".concat(documentsCount, ")") }))] })) : ("-")] }), signaturesCompleted !== null ? (_jsx(_Fragment, { children: signaturesCompleted ? (_jsx(Tooltip, __assign({ title: "A assinatura de todos os documentos solicitados foi concluída.", placement: "right", arrow: true }, { children: _jsx(IconButton, __assign({ size: "small", style: { padding: 0 } }, { children: _jsx(SignatureIcon, { type: "completed" }) })) }))) : (_jsx(Tooltip, __assign({ title: "Existem documentos com assinaturas pendentes.", placement: "right", arrow: true }, { children: _jsx(IconButton, __assign({ size: "small", style: { padding: 0 } }, { children: _jsx(SignatureIcon, { type: "pending" }) })) }))) })) : null] }));
};
EmployeeVacationRequestCardDocuments.displayName =
    "EmployeeVacationRequestCard.Documents";
var EmployeeVacationRequestCardManager = function (_a) {
    var manager = _a.manager;
    return (_jsx(S.LineContainer, { children: _jsxs("div", __assign({ style: { marginTop: "8px" } }, { children: [_jsx(UserLocation, { noMargin: true }), _jsx("p", { children: manager.name })] })) }));
};
EmployeeVacationRequestCardManager.displayName =
    "EmployeeVacationRequestCard.Manager";
var EmployeeVacationRequestCardShareHistory = function (_a) {
    var shareHistory = _a.shareHistory;
    return (_jsx(S.LineContainer, { children: _jsxs("div", __assign({ style: { marginTop: "8px", alignSelf: "center" } }, { children: [_jsxs("div", { children: [_jsx(ShareIcon, {}), _jsx("p", { children: "Compartilhado" })] }), _jsx(Chip, { label: formatToBrazilianDate(shareHistory[0].date), color: "default", size: "small", style: {
                        width: "fit-content",
                        fontWeight: "500",
                    } })] })) }));
};
EmployeeVacationRequestCardShareHistory.displayName =
    "EmployeeVacationRequestCard.ShareHistory";
var EmployeeVacationRequestCardStartSoon = function () { return (_jsx(Tooltip, __assign({ title: "Caso n\u00E3o seja aprovada, a solicita\u00E7\u00E3o ser\u00E1 automaticamente reprovada ao passar da data de in\u00EDcio", arrow: true, placement: "top" }, { children: _jsx(Chip, { label: "Data de in\u00EDcio se aproximando", size: "small", style: {
            background: Tokens.feedbackColor.warning.light,
            fontWeight: "500",
            justifyContent: "flex-start",
            marginTop: "16px",
        } }) }))); };
EmployeeVacationRequestCardStartSoon.displayName =
    "EmployeeVacationRequestCard.StartSoon";
var EmployeeVacationRequestCardSpace = function () { return (_jsx("div", { style: { marginTop: "2px" } })); };
EmployeeVacationRequestCardSpace.displayName =
    "EmployeeVacationRequestCard.Space";
/**
 * @example
 *  <EmployeeVacationRequestCard.Root
 *   style={{}}
 *   onClickVacation={onClickVacation}
 *   key={item.id}
 *   vacation={item}>
 *   <EmployeeVacationRequestCard.Header>
 *     <EmployeeVacationRequestCard.Employee employee={item.employee} />
 *     <EmployeeVacationRequestCard.Avatar profilePictureUrl={item.employee.profilePictureUrl} />
 *   </EmployeeVacationRequestCard.Header>
 *   <EmployeeVacationRequestCard.VacationDays
 *      durationDays={item.durationDays}
 *      startDate={item.startDate}
 *      endDate={item.endDate}
 *   />
 *   <EmployeeVacationRequestCard.Space />
 *   <EmployeeVacationRequestCard.Documents
 *      documentsCount={item.documentsCount}
 *      signaturesCompleted={item.signaturesCompleted}
 *   />
 *   {(item.manager && item.manager.name) &&
 *     <EmployeeVacationRequestCard.Manager manager={item.manager} />}
 *   {item.shareHistory &&
 *     <EmployeeVacationRequestCard.ShareHistory shareHistory={item.shareHistory} />}
 *   {(item.startsSoon && item.status === VacationFollowUpStatus.AWAITING_APPROVAL) &&
 *     <EmployeeVacationRequestCard.StartSoon />}
 *  </EmployeeVacationRequestCard.Root>
 *
 * @property {VacationFollowUp} vacation
 * @property {(vacation: VacationFollowUp) => void} onClickVacation
 * @property {CSSProperties} style
 * @property {ReactNode} children
 */
export var EmployeeVacationRequestCard = {
    Root: EmployeeVacationRequestCardRoot,
    RooyByManager: EmployeeVacationByManagerRequestCardRoot,
    Header: EmployeeVacationRequestCardHeader,
    Employee: EmployeeVacationRequestCardEmployeeInformation,
    Avatar: EmployeeVacationRequestCardAvatar,
    VacationDays: EmployeeVacationRequestCardVacationDays,
    Documents: EmployeeVacationRequestCardDocuments,
    Space: EmployeeVacationRequestCardSpace,
    Manager: EmployeeVacationRequestCardManager,
    ShareHistory: EmployeeVacationRequestCardShareHistory,
    StartSoon: EmployeeVacationRequestCardStartSoon,
};
