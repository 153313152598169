var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 93px;\n  border-radius: 8px;\n  background: ", ";\n  margin: 8px 0px;\n  padding: 8px;\n  max-height: 440px;\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  cursor: pointer;\n"], ["\n  min-height: 93px;\n  border-radius: 8px;\n  background: ", ";\n  margin: 8px 0px;\n  padding: 8px;\n  max-height: 440px;\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  cursor: pointer;\n"])), Tokens.neutralColor.light["1000"]);
export var ContainerNameAndPosition = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n\n  div {\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    gap: 4px;\n  }\n  \n  h2 {\n    margin: 0;\n    font-size: 14px;\n    font-weight: 500;\n  }\n  p {\n    margin: 0;\n    font-size: 12px;\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n\n  div {\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    gap: 4px;\n  }\n  \n  h2 {\n    margin: 0;\n    font-size: 14px;\n    font-weight: 500;\n  }\n  p {\n    margin: 0;\n    font-size: 12px;\n    color: ", ";\n  }\n"])), Tokens.neutralColor.medium["400"]);
export var AdmissionInfo = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 8px;\n  display: flex;\n  gap: 16px;\n"], ["\n  margin-top: 8px;\n  display: flex;\n  gap: 16px;\n"])));
export var LineContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n\n  div {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    gap: 4px;\n\n    p {\n      font-size: 12px;\n      font-weight: 500;\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n\n  div {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    gap: 4px;\n\n    p {\n      font-size: 12px;\n      font-weight: 500;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
