import React, { useCallback, useState } from "react";
import { renderFormField } from "../../../helpers";
import { FormField, FormFieldType, SectionForm } from "@/services/form/types";
import { Button, Grid, Typography } from "@material-ui/core";
import { BiPlus } from "react-icons/bi";
import { useFormik } from "formik";
import {
  createInitialValues,
  generateValidationSchemaList,
} from "../../../helpers/formik";
import {
  applyTypeOnFields,
  createFormsByValuesLength,
  generateFormsPositions,
} from "../helpers";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { HiOutlineTrash } from "react-icons/hi";

import * as S from "./styles";
import useNotification from "@/hooks/useNotification";
import { getCustomHidden } from '@ciclos-frontend/common';

interface Props {
  form: SectionForm;
  isMobile: boolean;
}

export default React.forwardRef<any, Props>((props: Props, ref) => {
  const isRequired = props.form.required;
  const [formsPositions, setFormsPositions] = useState(() =>
    createFormsByValuesLength(props.form.fields)
  );

  const notification = useNotification();

  const [hasEmptyError, setHasErrorEmpty] = useState(false);

  const form = useFormik<any>({
    initialValues: createInitialValues({
      fields: props.form.fields,
      isList: true,
    }),
    onSubmit: (values) => values,
    validationSchema: generateValidationSchemaList(props.form.fields),
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const allEmptyValues = Object.values(form.values).every(
    (item) => Array.isArray(item) && item.length === 0
  );

  const isEmpty =
    isRequired &&
    allEmptyValues &&
    props.form.fields.find((field: FormField) => field.required);

  const keys = Object.keys(form.values);
  const arrPositions = generateFormsPositions(formsPositions);

  const addNewForm = useCallback(async () => {
    const isValid = await form.validateForm();

    if (
      form.values["PHYSICAL_MENTAL_DISABILITY_WORK"] &&
      form.values["PHYSICAL_MENTAL_DISABILITY_WORK"].some((val: string) => val === "Não") &&
      Object.keys(isValid).includes('PHYSICAL_MENTAL_DISABILITY_WORK_DOCUMENT')
    ) {
      delete isValid['PHYSICAL_MENTAL_DISABILITY_WORK_DOCUMENT'];
    }

    await form.submitForm();

    if (Object.keys(isValid).length > 0) {
      notification.error("Preencha os campos obrigatórios");
      return;
    }

    keys.map(
      async (key) =>
        await form.setFieldValue(
          key,
          Array.isArray(form.values[key])
            ? [...form.values[key]]
            : [form.values[key]]
        )
    );
    setFormsPositions((prev) => prev + 1);
  }, [form, notification, keys]);

  const removeForm = useCallback(
    (formIndex: number) => {
      const keys = Object.keys(form.values);

      keys.map(async (key) => {
        const newValue = form.values[key].filter(
          (_item: any, index: number) => {
            return index !== formIndex;
          }
        );
        await form.setFieldValue(key, newValue);
      });
      setFormsPositions((prev) => prev - 1);
    },
    [form]
  );

  const handleChangeField = (position: number, fieldId: string, value: any) => {
    if (fieldId === "PHYSICAL_MENTAL_DISABILITY_WORK" && value == "Não") {
      handleChangeField(position, "PHYSICAL_MENTAL_DISABILITY_WORK_DOCUMENT", null);
    }

    const currentFieldValue = form.values[fieldId];
    const updatedName = Array.isArray(currentFieldValue)
      ? [...currentFieldValue]
      : currentFieldValue.value
        ? [...currentFieldValue.value]
        : [currentFieldValue];
    updatedName[position] = value;

    form.setFieldValue(fieldId, updatedName);
  };

  React.useImperativeHandle(
    ref,
    () => ({
      submitForm: async () => {
        if (isEmpty) {
          setHasErrorEmpty(true);
          return [false];
        }

        form.handleSubmit();
        setHasErrorEmpty(false);
        const errors = form?.errors ?? {};

        if (
          form.values["PHYSICAL_MENTAL_DISABILITY_WORK"] &&
          form.values["PHYSICAL_MENTAL_DISABILITY_WORK"].some((val: string) => val === "Não" || val === '')
        ) {
          delete errors["PHYSICAL_MENTAL_DISABILITY_WORK_DOCUMENT"];
        }

        const isValid = Object.keys(errors).length === 0;

        if (!isValid) {
          return [isValid];
        }


        const fieldsValuesWithType = applyTypeOnFields({
          isList: true,
          fields: props.form.fields,
          values: form.values,
        });

        return [
          isValid,
          {
            formId: props.form.id,
            formType: props.form.type,
            fields: fieldsValuesWithType,
          },
        ];
      },
    }),
    [form, props.form, isEmpty]
  );

  function getErrorField(fieldId: string, position: number) {
    try {
      const error = (form?.errors as any)[fieldId][position];
      const touched = (form?.touched as any)[fieldId][position];
      if (error && touched) {
        return error;
      }
    } catch (error) {
      return "";
    }
  }
  const getEmptyStateMessages = (type: string) => {
    return {
      "Dados bancários": "É necessário adicionar pelo menos uma conta bancária",
    }[type];
  };
  return (
    <Grid container spacing={2} style={{ marginBottom: "8px", padding: '0 24px 24px 24px' }}>
      {arrPositions.map((position) => {
        return (
          <Grid container key={position} className="form-wrapper" style={{ gap: "10px", marginBottom: "20px" }}>
            <Grid item xs={12}>
              <S.TitleContainer>
                <h3>
                  {props.form.title} {position + 1}
                </h3>
                <Button
                  variant="text"
                  endIcon={
                    <HiOutlineTrash color={Tokens.feedbackColor.error.pure} />
                  }
                  onClick={() => removeForm(position)}
                  style={{
                    borderColor: Tokens.feedbackColor.error.pure,
                    color: Tokens.feedbackColor.error.pure,
                  }}
                >
                  Remover
                </Button>
              </S.TitleContainer>
            </Grid>

            {props.form?.fields?.map((field) => {
              const customHidden = getCustomHidden(
                props.form.id,
                field.id,
                form.values,
                position
              );
              const isArrayValue = Array.isArray(form.values[field.id]);
              const currentValue = isArrayValue
                ? form.values[field.id][position] ?? null
                : form.values[field.id] ?? null;

              const formWrappers = document.querySelectorAll(".form-wrapper");
              const formWrapper = formWrappers[position];

              const checkBoxContainer = formWrapper?.querySelector(`#${CSS.escape("PHYSICAL_MENTAL_DISABILITY_WORK")}`);

              const disabilityCheckbox = checkBoxContainer?.querySelector(
                'input[type="checkbox"]'
              ) as HTMLInputElement;

              const foreignContainer = formWrapper?.querySelector(`#${CSS.escape("FOREIGN")}`);

              const foreignCheckbox = foreignContainer?.querySelector(
                'input[type="checkbox"]'
              ) as HTMLInputElement;

              const cpfFieldError = document.querySelector("#CPF-helper-text");

              if(foreignCheckbox && foreignCheckbox.checked && cpfFieldError) {
                cpfFieldError.textContent = "RNE inválido"
              }

              const changeForeignInputLabel = foreignCheckbox && foreignCheckbox.checked && field.id === "CPF";
              const changeDisabilityDocument = field.id === "PHYSICAL_MENTAL_DISABILITY_WORK_DOCUMENT" &&
              disabilityCheckbox &&
              !disabilityCheckbox.checked

              if (
                changeDisabilityDocument
              ) {
                return null;
              }

              return (
                <Grid
                  item
                  xs={12}
                  key={field.id}
                  style={field.hidden ? { display: "none" } : {}}
                >
                  {renderFormField(changeForeignInputLabel ? FormFieldType.TEXT : field.type, {
                    fields: field.data?.fields,
                    id: field.id,
                    label: changeForeignInputLabel ? "RNE da pessoa dependente" : field.label,
                    required: field.required,
                    options: field.data?.options,
                    showForOptionValues: field.data?.showForOptionValues,
                    onChange: (value: any) => {
                      handleChangeField(position, field.id, value);
                    },
                    value: currentValue,
                    error: getErrorField(field.id, position),
                    isMobile: props.isMobile,
                    data: field.data,
                    hidden: field.hidden || customHidden,
                  }, props.form.id, form.values)}
                </Grid>
              );
            })}
          </Grid>
        );
      })}
      <Grid item style={{ marginTop: 16 }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<BiPlus />}
          onClick={() => addNewForm()}
        >
          {props.form.title}
        </Button>
        {isEmpty && hasEmptyError ? (
          <div style={{ marginTop: "8px" }}>
            <Typography
              variant="caption"
              style={{
                color: Tokens.feedbackColor.error.medium,
              }}
            >
              {getEmptyStateMessages(props.form.title)}
            </Typography>
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
});
