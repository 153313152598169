var Debounce = /** @class */ (function () {
    function Debounce() {
    }
    Debounce.prototype.delay = function (func, milliseconds) {
        if (milliseconds === void 0) { milliseconds = 500; }
        if (!milliseconds) {
            func();
            return;
        }
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () { func(); }, milliseconds);
    };
    return Debounce;
}());
export default new Debounce();
