var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Tokens } from "@caju-beneficios/sponsor-v2-lib/dist/configs/tokens/tokens";
import { TbCalendarCheck } from "react-icons/tb";
import { WrapperIcon } from "./styles";
import { FaRegCheckSquare, FaRegClock } from "react-icons/fa";
import { MdAttachMoney } from "react-icons/md";
var config = {
    balance: {
        title: "Saldo disponível",
        icon: _jsx(TbCalendarCheck, { size: 18 }),
        iconColor: Tokens.interfaceColor.primary.dark,
        bgColor: Tokens.interfaceColor.primary.light,
    },
    allowance: {
        title: "Dias abonados",
        icon: _jsx(MdAttachMoney, { size: 18 }),
        iconColor: Tokens.neutralColor.dark[200],
        bgColor: Tokens.neutralColor.light[800],
    },
    selected: {
        title: "Dias selecionados",
        icon: _jsx(FaRegCheckSquare, { size: 18 }),
        iconColor: Tokens.feedbackColor.success.dark,
        bgColor: Tokens.feedbackColor.success.light,
    },
    remaining: {
        title: "Saldo restante",
        icon: _jsx(FaRegClock, { size: 18 }),
        iconColor: Tokens.feedbackColor.warning.dark,
        bgColor: Tokens.feedbackColor.warning.light,
    },
    nextPeriod: {
        title: "Saldo do próximo período",
        icon: _jsx(TbCalendarCheck, { size: 18 }),
        iconColor: Tokens.interfaceColor.primary.dark,
        bgColor: Tokens.interfaceColor.primary.light,
    },
};
export var getTitle = function (variant) { return config[variant].title; };
export var getIcon = function (variant) {
    var _a = config[variant], icon = _a.icon, bgColor = _a.bgColor, iconColor = _a.iconColor;
    return (_jsx(WrapperIcon, __assign({ bgColor: bgColor, iconColor: iconColor }, { children: icon })));
};
