export function getCustomDescription(formId, id, values, description) {
    if (["WORK_CARD", "work"].includes(formId) &&
        (values["TYPE"] === "Digital" || values["type"] === "Digital")) {
        if (["NUMBER", "number"].includes(id)) {
            return "7 primeiros dígitos do CPF";
        }
        if (["SERIES", "series"].includes(id)) {
            return "4 últimos dígitos do CPF";
        }
    }
    return description;
}
export function getCustomHidden(formId, id, values, position) {
    var _a, _b, _c, _d;
    if (["DEPENDENTS", "dependents"].includes(formId)) {
        if ([
            "secondAttach",
            "SECOND_ATTACHMENT",
            "thirdAttach",
            "THIRD_ATTACHMENT",
        ].includes(id) &&
            !((values["attach"] && ((_a = values["attach"][position]) === null || _a === void 0 ? void 0 : _a.name)) ||
                (values["attach"] && ((_b = values["attach"][position]) === null || _b === void 0 ? void 0 : _b.fileName)) ||
                (values["ATTACHMENT"] && ((_c = values["ATTACHMENT"][position]) === null || _c === void 0 ? void 0 : _c.name)) ||
                (values["ATTACHMENT"] && ((_d = values["ATTACHMENT"][position]) === null || _d === void 0 ? void 0 : _d.fileName)))) {
            return true;
        }
    }
    return false;
}
export function getCustomLabel(formId, id, label) {
    if (["DEPENDENTS", "dependents"].includes(formId)) {
        if (["ATTACHMENT", "attach"].includes(id)) {
            return "Anexos";
        }
        else if ([
            "SECOND_ATTACHMENT",
            "secondAttach",
            "THIRD_ATTACHMENT",
            "thirdAttach",
        ].includes(id)) {
            return "";
        }
    }
    return label;
}
