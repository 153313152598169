export var Tags;
(function (Tags) {
    Tags["COMPLETED_ADMISSION"] = "COMPLETED_ADMISSION";
    Tags["FIX_ADMISSION_FORM"] = "FIX_ADMISSION_FORM";
    Tags["OPEN_EMPLOYEE_MANAGEMENT"] = "OPEN_EMPLOYEE_MANAGEMENT";
    Tags["OPEN_FILE_EMPLOYEE_MANAGEMENT"] = "OPEN_FILE_EMPLOYEE_MANAGEMENT";
    Tags["OPEN_FILE_SPONSOR_ONBOARDING"] = "OPEN_FILE_SPONSOR_ONBOARDING";
    Tags["OPEN_SIGNATURE_PANEL"] = "OPEN_SIGNATURE_PANEL";
    Tags["OPEN_EMPLOYEE_DIRECTORY"] = "OPEN_EMPLOYEE_DIRECTORY";
    Tags["OPEN_ADMISSION"] = "OPEN_ADMISSION";
    Tags["OPEN_CICLOS_REPORTS"] = "OPEN_CICLOS_REPORTS";
    Tags["TIME_SPENT_CREATE_REGISTRATION"] = "TIME_SPENT_CREATE_REGISTRATION";
    Tags["COMPLETED_DOCUMENT_UPLOAD"] = "COMPLETED_DOCUMENT_UPLOAD";
    Tags["CREATE_SIGNATURE"] = "CREATE_SIGNATURE";
    // receipts
    Tags["CREATE_RECEIPT"] = "CREATE_RECEIPT";
    Tags["OPEN_RECEIPT_PANEL"] = "OPEN_RECEIPT_PANEL";
    Tags["OPEN_RECEIPT_ITEMS_PANEL"] = "OPEN_RECEIPT_ITEMS_PANEL";
    Tags["DELETE_RECEIPT"] = "DELETE_RECEIPT";
    Tags["OPEN_EMPLOYEE_RECEIPT_FOLDER"] = "OPEN_EMPLOYEE_RECEIPT_FOLDER";
    Tags["CLOSE_CES_PRIMEIRA_ADMISSAO"] = "CLOSE_CES_PRIMEIRA_ADMISSAO";
    Tags["CLOSE_CES_RECORRENTE"] = "CLOSE_CES_RECORRENTE";
    //vacations
    Tags["CREATE_VACATION_REQUEST_BY_SPONSOR_ON_DEADLINE_PANEL"] = "CREATE_VACATION_REQUEST_BY_SPONSOR_ON_DEADLINE_PANEL";
    Tags["CREATE_VACATION_REQUEST_BY_SPONSOR_ON_REQUEST_AND_FOLLOW_UP_PANEL"] = "CREATE_VACATION_REQUEST_BY_SPONSOR_ON_REQUEST_AND_FOLLOW_UP_PANEL";
    Tags["CREATE_VACATION_REQUEST_BY_SPONSOR_ON_HISTORY_PANEL"] = "CREATE_VACATION_REQUEST_BY_SPONSOR_ON_HISTORY_PANEL";
    Tags["CREATE_VACATION_REQUEST_BY_SPONSOR_ON_DIRECTORY"] = "CREATE_VACATION_REQUEST_BY_SPONSOR_ON_DIRECTORY";
    Tags["COMPLETE_SETUP"] = "COMPLETE_SETUP";
    Tags["SKIP_SETUP"] = "SKIP_SETUP";
    Tags["COMPLETE_SETUP_MASSIVE"] = "COMPLETE_SETUP_MASSIVE";
    Tags["OPEN_VACATION_DEADLINE_PANEL"] = "OPEN_VACATION_DEADLINE_PANEL";
    Tags["OPEN_VACATION_SETUP_PANEL"] = "OPEN_VACATION_SETUP_PANEL";
    Tags["VACATION_SETUP_MASSIVE_DOWNLOAD_TEMPLATE"] = "VACATION_SETUP_MASSIVE_DOWNLOAD_TEMPLATE";
    Tags["PRE_VACATION_SETUP_MANUAL"] = "PRE_VACATION_SETUP_MANUAL";
    Tags["PRE_VACATION_SETUP_MASSIVE"] = "PRE_VACATION_SETUP_MASSIVE";
    Tags["OPEN_VACATION_REQUEST_AND_FOLLOW_UP_PANEL"] = "OPEN_VACATION_REQUEST_AND_FOLLOW_UP_PANEL";
    Tags["OPEN_VACATION_HISTORY_PANEL"] = "OPEN_VACATION_HISTORY_PANEL";
    Tags["SHARE_VACATION_REQUEST_ON_CREATION"] = "SHARE_VACATION_REQUEST_ON_CREATION";
    Tags["SHARE_VACATION_REQUEST"] = "SHARE_VACATION_REQUEST";
    Tags["VACATION_SETUP_MASSIVE_SKIP"] = "VACATION_SETUP_MASSIVE_SKIP";
    Tags["CREATE_VACATION_HISTORY"] = "CREATE_VACATION_HISTORY";
    Tags["VACATION_SETUP_MASSIVE_UPLOADED_FILE"] = "VACATION_SETUP_MASSIVE_UPLOADED_FILE";
    Tags["VACATION_SETUP_MASSIVE_REVIEW_DETAILS_WARNING_AMOUNT_CORRECT_CONTINUE"] = "VACATION_SETUP_MASSIVE_REVIEW_DETAILS_WARNING_AMOUNT_CORRECT_CONTINUE";
    Tags["EMPLOYEES_REVIEWED_ON_VACATION_SETUP"] = "EMPLOYEES_REVIEWED_ON_VACATION_SETUP";
    Tags["EMPLOYEES_NOT_REVIEWED_ON_VACATION_SETUP"] = "EMPLOYEES_NOT_REVIEWED_ON_VACATION_SETUP";
    Tags["PRE_VACATION_SETUP_TAB_DEADLINE"] = "PRE_VACATION_SETUP_TAB_DEADLINE";
    Tags["PRE_VACATION_SETUP_TAB_FOLLOW_UP"] = "PRE_VACATION_SETUP_TAB_FOLLOW_UP";
    Tags["PRE_VACATION_SETUP_TAB_HISTORY"] = "PRE_VACATION_SETUP_TAB_HISTORY";
    Tags["PRE_VACATION_SETUP_START_TAB_DEADLINE"] = "PRE_VACATION_SETUP_START_TAB_DEADLINE";
    Tags["PRE_VACATION_SETUP_START_TAB_FOLLOW_UP"] = "PRE_VACATION_SETUP_START_TAB_FOLLOW_UP";
    Tags["PRE_VACATION_SETUP_START_TAB_HISTORY"] = "PRE_VACATION_SETUP_START_TAB_HISTORY";
    Tags["PRE_VACATION_SETUP_HELP_TAB_DEADLINE"] = "PRE_VACATION_SETUP_HELP_TAB_DEADLINE";
    Tags["VACATION_SETUP_MASSIVE_HELP"] = "VACATION_SETUP_MASSIVE_HELP";
    Tags["PRE_VACATION_SETUP_HELP_TAB_FOLLOW_UP"] = "PRE_VACATION_SETUP_HELP_TAB_FOLLOW_UP";
    Tags["PRE_VACATION_SETUP_HELP_TAB_HISTORY"] = "PRE_VACATION_SETUP_HELP_TAB_HISTORY";
    Tags["PRE_VACATION_SETUP_UNDERSTOOD"] = "PRE_VACATION_SETUP_UNDERSTOOD";
    Tags["PRE_VACATION_SETUP_BACK"] = "PRE_VACATION_SETUP_BACK";
    Tags["PRE_VACATION_SETUP_RESTART"] = "PRE_VACATION_SETUP_RESTART";
    Tags["PRE_VACATION_SETUP_CONTINUE"] = "PRE_VACATION_SETUP_CONTINUE";
    //vacations employee
    Tags["OPEN_EMPLOYEE_VACATION_HISTORY"] = "OPEN_EMPLOYEE_VACATION_HISTORY";
    Tags["CREATE_VACATION_REQUEST_BY_EMPLOYEE"] = "CREATE_VACATION_REQUEST_BY_EMPLOYEE";
    //organizational
    Tags["OPEN_SPONSOR_ORGANIZATIONAL"] = "OPEN_SPONSOR_ORGANIZATIONAL";
    Tags["OPEN_EMPLOYEE_ORGANIZATIONAL"] = "OPEN_EMPLOYEE_ORGANIZATIONAL";
    Tags["EDIT_LABOR_ORGANIZATIONAL"] = "EDIT_LABOR_ORGANIZATIONAL";
})(Tags || (Tags = {}));
