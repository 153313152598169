var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useRef, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import CancelPresentationOutlinedIcon from '@material-ui/icons/CancelPresentationOutlined';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Tokens } from '@caju-beneficios/react-web-commons/dist/configs';
import { Slide } from '../../animations';
import { joinClass } from '../../utils/joinClass';
var useStyles = makeStyles(function (_a) {
    var palette = _a.palette, shape = _a.shape;
    return ({
        boxContainer: {
            height: 190,
            width: '100%',
            position: 'relative',
            border: "2px dashed ".concat(palette.primary.main),
            borderRadius: shape.borderRadius,
            backgroundColor: palette.background.default,
            transition: "all .2s",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&.dropped': {
                border: "2px solid ".concat(Tokens.feedbackColor.success.pure),
                backgroundColor: Tokens.feedbackColor.success.light,
            },
            '&.error': {
                border: "2px solid ".concat(Tokens.feedbackColor.error.pure),
                backgroundColor: Tokens.feedbackColor.error.light,
            },
            '&.dragging': {
                border: "2px solid ".concat(palette.primary.main),
                backgroundColor: palette.primary.light,
            },
            '& input': {
                position: 'absolute',
                backgroundColor: 'transparent',
                color: 'transparent',
                height: '100%',
                width: '100%',
                cursor: 'pointer',
                '&::-webkit-file-upload-button': {
                    display: 'none'
                }
            }
        },
        closeButton: {
            position: 'absolute',
            top: 0,
            right: 0,
            '&:hover': {
                color: Tokens.neutralColor.dark[100]
            }
        }
    });
});
function DraggingMessage() {
    return (_jsxs(Slide, __assign({ enter: true, direction: "top" }, { children: [_jsx(CloudDownloadOutlinedIcon, { style: { margin: 'auto' }, fontSize: "large", color: "primary" }), _jsx(Typography, __assign({ color: "primary", align: "center", variant: "body2" }, { children: "Solte o arquivo para carregar" }))] })));
}
function NoDraggingText() {
    return (_jsxs(Slide, __assign({ enter: true, direction: "top" }, { children: [_jsx(CloudUploadOutlinedIcon, { style: { margin: 'auto' }, color: "primary", fontSize: "large" }), _jsxs(Typography, __assign({ align: "center", variant: "body2", style: { color: Tokens.interfaceColor.primary.pure } }, { children: ["Arraste e solte arquivos para", _jsx("br", {}), "carreg\u00E1-los no Caju Folha"] }))] })));
}
function SucessMessage(_a) {
    var file = _a.file;
    return (_jsxs(Slide, __assign({ enter: true, direction: "top" }, { children: [_jsx(CheckBoxOutlinedIcon, { style: { margin: 'auto' }, color: "inherit", fontSize: "large" }), _jsxs(Typography, __assign({ align: "center", variant: "body2", style: { color: Tokens.neutralColor.dark[100] } }, { children: ["Arquivo ", _jsx("strong", { children: file === null || file === void 0 ? void 0 : file.name }), " carregado", _jsx("br", {}), "com sucesso"] }))] })));
}
function ErrorMessage(_a) {
    var file = _a.file, errorMessage = _a.errorMessage;
    return (_jsxs(Slide, __assign({ enter: true, direction: "top" }, { children: [_jsx(CancelPresentationOutlinedIcon, { style: { margin: 'auto' }, color: "inherit", fontSize: "large" }), _jsx(Typography, __assign({ align: "center", variant: "body2", style: { color: Tokens.neutralColor.dark[100] } }, { children: errorMessage || (_jsxs(_Fragment, { children: ["Arquivo ", _jsx("strong", { children: file === null || file === void 0 ? void 0 : file.name }), " n\u00E3o foi carregado"] })) }))] })));
}
export default function UploadBox(_a) {
    var file = _a.file, errorMessage = _a.errorMessage, onReset = _a.onReset, props = __rest(_a, ["file", "errorMessage", "onReset"]);
    var classes = useStyles();
    var fileInputRef = useRef(null);
    var _b = __read(useState(false), 2), error = _b[0], setError = _b[1];
    var _c = __read(useState(false), 2), isDragging = _c[0], setIsDragging = _c[1];
    var className = joinClass([
        classes.boxContainer,
        isDragging && 'dragging',
        !isDragging && file && 'dropped',
        !isDragging && error && 'error',
    ]);
    useEffect(function () {
        if (fileInputRef.current && file) {
            var dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
            fileInputRef.current.files = dataTransfer.files;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileInputRef]);
    useEffect(function () { setError(Boolean(errorMessage)); }, [errorMessage]);
    var handleDropFile = function (e) {
        if (props.onChange) {
            props.onChange(e);
        }
    };
    var handleReset = function (e) {
        e.stopPropagation();
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        setError(false);
        onReset();
    };
    return (_jsxs("div", __assign({ className: className, onDragOver: function () { return setIsDragging(true); }, onDragLeave: function () { return setIsDragging(false); }, onDrop: function () { return setIsDragging(false); } }, { children: [_jsx("input", __assign({ type: "file" }, props, { ref: fileInputRef, onChange: handleDropFile })), file && (_jsx(IconButton, __assign({ onClick: handleReset, className: classes.closeButton, color: "inherit" }, { children: _jsx(ClearOutlinedIcon, { fontSize: "inherit", color: "inherit" }) }))), _jsxs("div", __assign({ style: { textAlign: 'center' } }, { children: [isDragging && _jsx(DraggingMessage, {}), !isDragging && !file && !error && _jsx(NoDraggingText, {}), !isDragging && file && !error && _jsx(SucessMessage, { file: file }), !isDragging && error && _jsx(ErrorMessage, { file: file, errorMessage: errorMessage })] }))] })));
}
