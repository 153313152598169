var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import * as EmployeeService from "../../../../../../../../../services/employee";
import { useStyles } from "./styles";
import { useExternalApiContext, useService } from "@ciclos-frontend/common";
import { Autocomplete } from "@material-ui/lab";
import { Box, Paper, TextField, Typography } from "@material-ui/core";
export function SelectEmployeesWithVacation(props) {
    var _a;
    var classes = useStyles();
    var _b = __read(useState(""), 2), queryName = _b[0], setQueryName = _b[1];
    var _c = useExternalApiContext(), sponsorId = _c.sponsorId, api = _c.api;
    var service = useService({
        requestFn: EmployeeService.getEmployeesList,
        params: [api, { sponsorId: sponsorId, perPage: 20, query: queryName }],
        options: {
            getOnInit: props.isVisible ? true : false,
            refetchWhenParamsChange: true,
            delayRefetch: 1000,
        },
    });
    if (!props.isVisible)
        return null;
    return (_jsx("div", { children: _jsx(Autocomplete
        // fullWidth
        , { "data-testid": "autocomplete-signature", options: ((_a = service === null || service === void 0 ? void 0 : service.data) === null || _a === void 0 ? void 0 : _a.items) || [], loading: service.isLoading, loadingText: "Carregando...", noOptionsText: "Nenhum resultado encontrado", getOptionLabel: function (option) { return "".concat(option.personal.name); }, 
            // getOptionSelected={(option, value) => option.id === value.id}
            inputValue: queryName, renderOption: function (option) {
                var _a, _b, _c, _d;
                return (_jsxs(Box, __assign({ "data-testid": "option-".concat((_a = option.personal) === null || _a === void 0 ? void 0 : _a.name), className: classes.optionsMenu }, { children: [_jsx(Typography, __assign({ className: classes.optionsNameMenu }, { children: (_b = option.personal) === null || _b === void 0 ? void 0 : _b.name })), _jsx(Typography, __assign({ className: classes.optionsEmailMenu }, { children: ((_c = option.labor) === null || _c === void 0 ? void 0 : _c.corporateEmail) || ((_d = option.labor) === null || _d === void 0 ? void 0 : _d.personalEmail) }))] })));
            }, onInputChange: function (_, val, reason) {
                if (reason === "input") {
                    setQueryName(val);
                }
            }, onChange: function (_, newValue) {
                if (newValue) {
                    props.onChange({
                        employeeId: newValue.id,
                        name: newValue.personal.name,
                    });
                }
            }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { "aria-label": "input-label", label: "Colaborador", variant: "outlined", size: "small", InputLabelProps: { className: classes.inputProps, shrink: true } }))); }, PaperComponent: function (_a) {
                var children = _a.children;
                return _jsx(Paper, __assign({ "data-testid": "paper-list-employees" }, { children: children }));
            } }) }));
}
