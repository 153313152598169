var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from "./styles";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
export var ThirteenthSalaryOption = function (props) {
    return (_jsxs(S.Container, { children: [_jsx(S.TitleAndDescription, { children: _jsx("h3", { children: "Adiantamento da primeira parcela do 13\u00BA" }) }), _jsxs(RadioGroup, __assign({ "aria-label": "thirteenthSalary", name: "thirteenthSalary", value: props.value, onChange: function (_, value) { return props.onChange(value); } }, { children: [_jsx(FormControlLabel, { value: "Sim", control: _jsx(Radio, { color: "primary" }), label: "Sim", disabled: props.shouldDisable }), _jsx(FormControlLabel, { value: "N\u00E3o", control: _jsx(Radio, { color: "primary" }), label: "N\u00E3o", disabled: props.shouldDisable })] })), props.shouldDisable ? (_jsx(Alert, __assign({ severity: "info" }, { children: "Este colaborador j\u00E1 optou pelo adiantamento da primeira parcela do 13\u00BA no per\u00EDodo atual." }))) : null] }));
};
