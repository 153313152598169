var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import styled from "styled-components";
export var ContainerHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .adm-drawer-header {\n    padding: 0px;\n  }\n  .adm-drawer-header div:first-child {\n    padding: 24px 24px 0px 24px;\n  }\n"], ["\n  .adm-drawer-header {\n    padding: 0px;\n  }\n  .adm-drawer-header div:first-child {\n    padding: 24px 24px 0px 24px;\n  }\n"])));
export var ContainerDescription = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding: 20px 40px;\n  border-top: 1px solid ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding: 20px 40px;\n  border-top: 1px solid ", ";\n"])), Tokens.neutralColor.medium[700]);
export var Title = styled.h6(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 24px;\n  font-size: 24px;\n  font-weight: 700;\n  margin: 0;\n"], ["\n  padding: 24px;\n  font-size: 24px;\n  font-weight: 700;\n  margin: 0;\n"])));
export var Description = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 16px;\n  margin: 0;\n"], ["\n  font-weight: 600;\n  font-size: 16px;\n  margin: 0;\n"])));
export var SubDescription = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 14px;\n  color: ", ";\n"], ["\n  font-size: 14px;\n  color: ", ";\n"])), Tokens.neutralColor.medium[400]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
