var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useEffect, useRef, useState } from "react";
import { useRefetchWhenParamsChange } from "./useRefetchWhenParamsChange";
export function usePrevious(value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value;
    }, []);
    return ref.current;
}
var useService = function (_a) {
    var requestFn = _a.requestFn, options = _a.options, params = _a.params, onSuccess = _a.onSuccess, onError = _a.onError;
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var _c = __read(useState(), 2), data = _c[0], setData = _c[1];
    var initializedRef = useRef(false);
    var handleSuccess = useCallback(function (newData) {
        if (onSuccess) {
            var updatedData = onSuccess(data, newData);
            if (updatedData) {
                setData(updatedData);
            }
            if (!updatedData) {
                setData(newData);
            }
        }
        if (!onSuccess) {
            setData(newData);
        }
    }, [onSuccess, data]);
    var memoizedRequestFn = useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var data_1, error_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 3, , 4]);
                    if (!params) return [3 /*break*/, 2];
                    return [4 /*yield*/, requestFn.apply(void 0, __spreadArray([], __read(params), false))];
                case 1:
                    data_1 = _c.sent();
                    handleSuccess(data_1);
                    return [2 /*return*/, data_1];
                case 2: return [3 /*break*/, 4];
                case 3:
                    error_1 = _c.sent();
                    if (onError) {
                        onError((_a = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _a === void 0 ? void 0 : _a.data);
                    }
                    else {
                        throw (_b = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _b === void 0 ? void 0 : _b.data;
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [requestFn, onError, handleSuccess]);
    var load = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data_2, error_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, 3, 4]);
                    setIsLoading(true);
                    return [4 /*yield*/, memoizedRequestFn(params)];
                case 1:
                    data_2 = _b.sent();
                    if (data_2) {
                        handleSuccess(data_2);
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_2 = _b.sent();
                    if (onError) {
                        onError((_a = error_2 === null || error_2 === void 0 ? void 0 : error_2.response) === null || _a === void 0 ? void 0 : _a.data);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [handleSuccess, memoizedRequestFn, onError, params]);
    useEffect(function () {
        if (initializedRef.current)
            return;
        if ((options === null || options === void 0 ? void 0 : options.getOnInit) && !initializedRef.current) {
            initializedRef.current = true;
            load();
        }
    }, [
        options === null || options === void 0 ? void 0 : options.getOnInit,
        load,
        options === null || options === void 0 ? void 0 : options.refetchWhenParamsChange,
        memoizedRequestFn,
    ]);
    useRefetchWhenParamsChange({
        params: params,
        refetch: load,
        options: options,
    });
    var refetch = function () { return load(); };
    var request = useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var data_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, , 2, 3]);
                    setIsLoading(true);
                    return [4 /*yield*/, memoizedRequestFn(params)];
                case 1:
                    data_3 = _a.sent();
                    return [2 /*return*/, data_3];
                case 2:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [memoizedRequestFn]);
    var updateData = function (newData) {
        setData(newData);
    };
    return { isLoading: isLoading, data: data, refetch: refetch, request: request, updateData: updateData };
};
export { useService };
