var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { flagProperties } from "../../../../utils/flagProperties";
import { Chip } from "@material-ui/core";
export default function SignatureIcon(_a) {
    var type = _a.type;
    var flagProp = flagProperties[type === null || type === void 0 ? void 0 : type.toLowerCase()];
    return (_jsx(Chip, { style: {
            height: "23px",
            backgroundColor: flagProp.backgroundColor,
        }, label: _jsx("div", __assign({ style: {
                display: "flex",
                alignItems: "center",
                gap: 4,
            } }, { children: flagProp.icon })) }));
}
