var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from "./styles";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
export var AllowanceDaysOption = function (props) {
    var alertMsg = props.disableReason === "period"
        ? " O período selecionado não permite venda de férias."
        : props.disableReason === "remaining"
            ? "O saldo do colaborador não permite venda de férias."
            : null;
    return (_jsxs(S.Container, { children: [_jsxs(S.TitleAndDescription, { children: [_jsx("h3", { children: "Abono pecuni\u00E1rio" }), _jsx("p", { children: "Venda de 1/3 das f\u00E9rias (limitado a 10 dias)" })] }), _jsxs(RadioGroup, __assign({ "aria-label": "allowanceDays", name: "allowanceDays", value: props.value, onChange: function (_, value) { return props.onChange(value); } }, { children: [_jsx(FormControlLabel, { value: "Sim", control: _jsx(Radio, { color: "primary" }), label: "Sim", disabled: props.shouldDisable }), _jsx(FormControlLabel, { value: "Não", control: _jsx(Radio, { color: "primary" }), label: "N\u00E3o", disabled: props.shouldDisable })] })), props.shouldDisable && alertMsg ? (_jsx(Alert, __assign({ severity: "info" }, { children: alertMsg }))) : null] }));
};
