import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { Button, Divider, Grid, Theme, useMediaQuery } from "@material-ui/core";
import { BiEditAlt } from "react-icons/bi";
import * as S from "./styles";
import { useNavigateSection } from "@/pages/FormBySection/hooks/useNavigateSection";
import { FormType } from "@/services/form/types";
import { useSection } from "@/hooks/services/form/useSection";
import { useParams } from "react-router-dom";
import FormValues from "./FormValues";
import FormValuesList from "./FormValuesList";
import {
  createFormsByValuesLength,
  generateFormsPositions,
} from "@/pages/FormBySection/components/FormsByType/helpers";
import { IoMdAdd } from "react-icons/io";

interface Props {
  sectionId: string;
}

export default function SummarySections(props: Props) {
  const params = useParams<{ token: string }>();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { section } = useSection({
    sectionId: props.sectionId,
    token: params.token,
  });

  const navigateSection = useNavigateSection();

  const isDependents = section?.title.toLowerCase() === "dependentes";

  return (
    <Grid
      container
      justifyContent="center"
      style={{ marginTop: 24, marginBottom: 110 }}
    >
      <Grid
        item
        xs={12}
        style={{
          border: `1px solid ${Tokens.neutralColor.medium["700"]}`,
          borderWidth: 1,
          borderRadius: Tokens.borderRadius.medium,
        }}
      >
        <S.SectionConfig>
          <S.SectionTitle>{section?.title}</S.SectionTitle>
          <Grid container style={{ gap: "20px" }} justifyContent="flex-end">
            {isDependents && (
              <Button  
                variant="text"
                onClick={() => {
                  navigateSection.goTo(props.sectionId, params.token, true);
                }}
                color="primary"
                startIcon={<IoMdAdd size={18} />}
                style={{ height: 34 }}
              >
                Dependente
              </Button>
            )}
            <Button
              color="primary"
              size="small"
              variant="outlined"
              startIcon={<BiEditAlt />}
              style={{height: "34px"}}
              onClick={() => {
                navigateSection.goTo(props.sectionId, params.token, true);
              }}
            >
              <p>Editar</p> 
            </Button>
          </Grid>
        </S.SectionConfig>
        {section?.forms.map((form, index) => {
          const isList = form.type === FormType.LIST;

          if (isList) {
            const formPositions = createFormsByValuesLength(form.fields);
            const isAccordion = Boolean(form.accordion);
            const generatedFormpositions = generateFormsPositions(formPositions);

            return (
              <div key={index} style={{
                padding: "0 24px"
              }}>
                {generatedFormpositions.map((position) => {
                  const renderListDivider = 
                    position <= section.forms.length - 1 && 
                    generatedFormpositions.length > 1 &&
                    !isAccordion;

                  return (
                    <div key={index}>
                      {!isAccordion && (
                        <S.FormTitle>
                          <h4>
                            {form.title}
                          </h4>
                        </S.FormTitle>
                      )}
                      <FormValuesList
                        form={form}
                        isMobile={isMobile}
                        key={form.id}
                        position={position}
                        isAccordion={isAccordion && isDependents}
                      />
                      {renderListDivider && <Divider variant="middle" style={{ margin: "24px 0" }} />}
                    </div>
                  );
                })}
              </div>
            );
          }

          const renderDivider = index < section.forms.length - 1;
          
          return (
            <div key={index} style={{
              padding: "0 24px"
            }}>
              <S.FormTitle>{form.title}</S.FormTitle>
              <FormValues form={form} isMobile={isMobile} key={form.id} />
              {renderDivider && <Divider variant="middle" style={{ margin: "24px 0" }} />}
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
}
