var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { Button, MenuItem, Typography } from "@material-ui/core";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoMdAdd } from "react-icons/io";
import ActionsButtons from "../ActionsButtons";
export var VariantEdit;
(function (VariantEdit) {
    VariantEdit["BUTTON"] = "BUTTON";
    VariantEdit["ACTION_BUTTON"] = "ACTION_BUTTON";
})(VariantEdit || (VariantEdit = {}));
function AddButton(_a) {
    var addButtonLabel = _a.addButtonLabel, onAddClick = _a.onAddClick;
    if (addButtonLabel && onAddClick) {
        return (_jsx(Button, __assign({ variant: "text", onClick: onAddClick, color: "primary", startIcon: _jsx(IoMdAdd, { size: 18 }), style: { height: 34 } }, { children: addButtonLabel })));
    }
    return null;
}
function EditButton(_a) {
    var onEditClick = _a.onEditClick, isOnHistoryPage = _a.isOnHistoryPage, addButtonLabel = _a.addButtonLabel, onAddClick = _a.onAddClick, onEditHistoryClick = _a.onEditHistoryClick;
    return (_jsxs("div", __assign({ style: { display: "flex", gap: 15 } }, { children: [_jsx(AddButton, { addButtonLabel: addButtonLabel, onAddClick: onAddClick }), _jsx(Button, __assign({ variant: "outlined", onClick: isOnHistoryPage ? onEditHistoryClick : onEditClick, color: "primary", startIcon: _jsx("i", { className: "uil uil-edit-alt", style: {
                        fontSize: 20,
                        color: Tokens.interfaceColor.primary.pure,
                    } }), style: { height: 34, paddingRight: 16, paddingLeft: 16 } }, { children: "Editar" }))] })));
}
function ActionsButton(_a) {
    var menuItems = _a.menuItems;
    return (_jsx(ActionsButtons, __assign({ customActionButtom: _jsx(HiOutlineDotsVertical, { size: 20, color: Tokens.interfaceColor.primary.pure, style: { cursor: "pointer" } }) }, { children: menuItems === null || menuItems === void 0 ? void 0 : menuItems.map(function (menuItem, index) {
            if (menuItem.hide)
                return null;
            return (_jsx(MenuItem, __assign({ onClick: function () { return menuItem.onClick(); } }, { children: menuItem.label }), "".concat(menuItem.label, "-").concat(index)));
        }) })));
}
function EditButtonsContainer(_a) {
    var onCancel = _a.onCancel, loading = _a.loading, onSubmit = _a.onSubmit, addButtonLabel = _a.addButtonLabel, onAddClick = _a.onAddClick, isOnHistoryPage = _a.isOnHistoryPage, onEditHistoryClick = _a.onEditHistoryClick, onEditClick = _a.onEditClick;
    if (isOnHistoryPage) {
        return (_jsxs("div", __assign({ style: { display: "flex", gap: 15 } }, { children: [_jsx(AddButton, { addButtonLabel: addButtonLabel, onAddClick: onAddClick }), _jsx(Button, __assign({ variant: "outlined", onClick: isOnHistoryPage ? onEditHistoryClick : onEditClick, color: "primary", startIcon: _jsx("i", { className: "uil uil-edit-alt", style: {
                            fontSize: 20,
                            color: Tokens.interfaceColor.primary.pure,
                        } }), style: { height: 34, paddingRight: 16, paddingLeft: 16 } }, { children: "Editar" }))] })));
    }
    return (_jsxs(_Fragment, { children: [_jsx("p", __assign({ style: {
                    color: Tokens.neutralColor.medium["500"],
                    fontSize: 12,
                } }, { children: "Editando..." })), _jsxs("div", __assign({ style: { display: "flex", gap: 15 } }, { children: [_jsx(AddButton, { addButtonLabel: addButtonLabel, onAddClick: onAddClick }), _jsx(Button, __assign({ size: "small", variant: "outlined", startIcon: _jsx(AiOutlineClose, { size: 18 }), style: {
                            color: Tokens.feedbackColor.error.pure,
                            borderColor: Tokens.feedbackColor.error.pure,
                        }, onClick: onCancel, disabled: loading, "data-testid": "button-cancel" }, { children: "Cancelar" })), _jsx(Button, __assign({ size: "small", variant: "outlined", startIcon: _jsx(AiOutlineCheck, { size: 18 }), style: {
                            color: Tokens.feedbackColor.success.pure,
                            borderColor: Tokens.feedbackColor.success.pure,
                        }, onClick: onSubmit, disabled: loading, "data-testid": "button-save" }, { children: "Salvar" }))] }))] }));
}
export var FormHeader = function (_a) {
    var addButtonLabel = _a.addButtonLabel, hideEditButton = _a.hideEditButton, isEditing = _a.isEditing, isOnHistoryPage = _a.isOnHistoryPage, loading = _a.loading, onAddClick = _a.onAddClick, onCancel = _a.onCancel, onEditClick = _a.onEditClick, onEditHistoryClick = _a.onEditHistoryClick, onSubmit = _a.onSubmit, _b = _a.options, options = _b === void 0 ? { variantEdit: "BUTTON" } : _b, title = _a.title;
    function getEditButton() {
        var _a;
        var _b;
        if (!hideEditButton && (onEditClick || onEditHistoryClick)) {
            var variants = (_a = {},
                _a[VariantEdit.BUTTON] = (_jsx(EditButton, { onEditClick: onEditClick, isOnHistoryPage: isOnHistoryPage, addButtonLabel: addButtonLabel, onAddClick: onAddClick, onEditHistoryClick: onEditHistoryClick })),
                _a[VariantEdit.ACTION_BUTTON] = (_jsx(ActionsButton, { menuItems: (_b = options.actionButton) === null || _b === void 0 ? void 0 : _b.menuItems })),
                _a);
            return variants[options.variantEdit];
        }
    }
    return (_jsxs("div", __assign({ style: {
            display: "flex",
            alignItems: "center",
            gap: 15,
            padding: "28px 24px 28px 24px",
            borderBottom: "1px solid ".concat(Tokens.neutralColor.medium[700]),
        } }, { children: [title && (_jsx("div", __assign({ style: {
                    width: "100%",
                } }, { children: _jsx(Typography, __assign({ variant: "subtitle2", style: {
                        fontWeight: 600,
                        fontSize: 16,
                    } }, { children: title })) }))), isEditing && (_jsx(EditButtonsContainer, { loading: loading, onCancel: onCancel, onSubmit: onSubmit, isOnHistoryPage: isOnHistoryPage, addButtonLabel: addButtonLabel, onAddClick: onAddClick, onEditClick: onEditClick, onEditHistoryClick: onEditHistoryClick })), !isEditing && !hideEditButton && getEditButton()] })));
};
