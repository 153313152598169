import { useEffect } from "react";
import { usePrevious } from ".";
export var useRefetchWhenParamsChange = function (props) {
    var prevParams = usePrevious({ value: props.params });
    useEffect(function () {
        var _a, _b;
        if (!(prevParams === null || prevParams === void 0 ? void 0 : prevParams.value))
            return;
        var validateAndRefetch = function () {
            var shouldRefetch = JSON.stringify(props.params) !== JSON.stringify(prevParams.value);
            prevParams.value = props.params;
            if (shouldRefetch) {
                props.refetch();
            }
        };
        if ((_a = props.options) === null || _a === void 0 ? void 0 : _a.refetchWhenParamsChange) {
            var delay = (_b = props.options.delayRefetch) !== null && _b !== void 0 ? _b : 0;
            var timerId_1 = setTimeout(validateAndRefetch, delay);
            return function () { return clearTimeout(timerId_1); };
        }
    }, [prevParams, props]);
};
