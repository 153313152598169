var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export function ShareIcon() {
    return (_jsx("svg", __assign({ width: "18", height: "17", viewBox: "0 0 18 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M16.6245 0.883382C16.3061 0.55885 15.9024 0.331064 15.46 0.226361C15.0176 0.121658 14.5546 0.144316 14.1245 0.291716L2.33285 4.23338C1.85727 4.38905 1.44175 4.68851 1.14364 5.09041C0.845527 5.49232 0.679525 5.97686 0.66855 6.47714C0.657575 6.97742 0.802168 7.46877 1.08237 7.88336C1.36257 8.29795 1.76456 8.61534 2.23285 8.79172L6.59951 10.4584C6.69895 10.4964 6.78948 10.5545 6.86547 10.6291C6.94145 10.7036 7.00127 10.793 7.04118 10.8917L8.70785 15.2667C8.8775 15.7284 9.18536 16.1265 9.58949 16.4069C9.99361 16.6872 10.4743 16.8361 10.9662 16.8334H11.0245C11.5254 16.8243 12.0108 16.6587 12.4128 16.3598C12.8148 16.0609 13.1132 15.6437 13.2662 15.1667L17.2078 3.35838C17.3512 2.9325 17.3725 2.475 17.2694 2.03762C17.1664 1.60024 16.943 1.20043 16.6245 0.883382ZM15.6662 2.83338L11.6828 14.65C11.6366 14.7996 11.5437 14.9304 11.4177 15.0234C11.2918 15.1164 11.1394 15.1666 10.9828 15.1667C10.8272 15.1693 10.6745 15.1244 10.545 15.0381C10.4155 14.9517 10.3153 14.828 10.2578 14.6834L8.59118 10.3084C8.47035 9.99049 8.284 9.70156 8.04425 9.46037C7.80449 9.21918 7.51668 9.03111 7.19951 8.90838L2.82451 7.24172C2.67692 7.18763 2.55006 7.08855 2.46183 6.95846C2.37361 6.82836 2.32849 6.67384 2.33285 6.51672C2.33297 6.36017 2.38319 6.20777 2.47616 6.08182C2.56912 5.95587 2.69995 5.86297 2.84951 5.81672L14.6662 1.87505C14.8018 1.81975 14.9506 1.80494 15.0945 1.8324C15.2384 1.85986 15.3713 1.92842 15.4771 2.02979C15.5829 2.13116 15.657 2.26099 15.6906 2.4036C15.7241 2.54621 15.7157 2.69549 15.6662 2.83338Z", fill: "#252525" }) })));
}
