var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { makeStyles } from "@material-ui/core";
import styled from "styled-components";
import { Tokens } from "@caju-beneficios/sponsor-v2-lib/dist/configs/tokens/tokens";
export var useStyles = makeStyles(function () { return ({
    title: {
        fontWeight: 600,
        color: Tokens.neutralColor.dark[100],
        fontSize: "16px",
        paddingBottom: "8px"
    },
    cardContainer: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
        padding: "16px",
    },
    cardAvatar: { height: "40px", width: "40px", alignSelf: 'flex-start' },
    cardName: {
        fontWeight: 500,
        color: Tokens.neutralColor.dark[100],
        fontSize: "14px",
    },
    cardComment: {
        fontWeight: 400,
        color: Tokens.neutralColor.dark[300],
        fontSize: "14px",
        paddingTop: "16px",
    }
}); });
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var templateObject_1;
