var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Tabs, Tab, makeStyles } from "@material-ui/core";
import { Tokens } from "@caju-beneficios/sponsor-v2-lib/dist/configs/tokens/tokens";
var useStyles = makeStyles(function () { return ({
    tabsRoot: {
        position: "sticky",
        top: 0,
        zIndex: 1,
        backgroundColor: Tokens.neutralColor.light[1000],
        height: "fit-content",
        paddingTop: "24px",
        minWidth: "205px",
    },
    scroller: {
        border: "1px solid ".concat(Tokens.neutralColor.medium[700]),
        borderRadius: Tokens.borderRadius.medium,
    },
    tab: {
        textTransform: "lowercase",
        minHeight: "25px",
        width: "100%",
        "&:first-of-type": {
            marginTop: "16px",
        },
        "&:last-of-type": {
            marginBottom: "16px",
        },
    },
    tabContainer: {
        width: "205px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "24px",
        paddingLeft: "16px",
    },
    tabTitle: {
        "&:first-letter": {
            textTransform: "uppercase",
        },
    },
    indicator: {
        display: "none",
    },
    root: {
        padding: 0,
        minWidth: "initial",
        textAlign: "left",
    },
    selected: {
        borderRight: "3px solid ".concat(Tokens.interfaceColor.primary.pure),
    },
    wrapper: {
        alignItems: "flex-start",
    },
}); });
export default function VerticalNavigator(_a) {
    var children = _a.children, tabIndex = _a.tabIndex, onTabChange = _a.onTabChange, formsTabs = _a.formsTabs, styles = _a.styles;
    var classes = useStyles();
    return (_jsxs(_Fragment, { children: [_jsx(Tabs, __assign({ value: tabIndex, onChange: onTabChange, indicatorColor: "primary", textColor: "primary", "aria-label": "tabs", variant: "standard", scrollButtons: "off", classes: {
                    root: classes.tabsRoot,
                    flexContainer: classes.tabContainer,
                    indicator: classes.indicator,
                    scroller: classes.scroller,
                }, style: styles }, { children: formsTabs
                    ? formsTabs.map(function (tab, index) { return (_jsx(Tab, { color: "primary", className: classes.tab, classes: {
                            selected: classes.selected,
                            root: classes.root,
                            wrapper: classes.wrapper,
                        }, label: _jsx("div", __assign({ className: classes.tabTitle }, { children: _jsx("span", { children: tab.title }) })) }, index + tab.id)); })
                    : null })), children] }));
}
