import moment from "moment-timezone";
export function isPastDate(day) {
    var today = moment().startOf("day");
    return day.isBefore(today, "day");
}
export function isFutureDate(day) {
    var today = moment().startOf("day");
    return day.isAfter(today, "day");
}
export function isLastThreeDaysOfWeek(day, config) {
    var dayOfWeek = day.day();
    var blockDays = (config === null || config === void 0 ? void 0 : config.allowStartOnThursday) ? [5, 6, 0] : [6, 5, 4, 0];
    return blockDays.includes(dayOfWeek);
}
export function isLastTwoDaysOfWeek(day) {
    var dayOfWeek = day.day();
    return dayOfWeek === 0 || dayOfWeek === 5 || dayOfWeek === 6;
}
export function isOverLimit(day, limitToStart, remainingBalance) {
    var limitMoment = moment(limitToStart, "YYYY-MM-DD");
    var limitDay = moment(limitMoment).add({ days: remainingBalance - 1 });
    if (day.isAfter(limitDay))
        return true;
    return false;
}
export function isOutsideConcessionPeriod(day, concessionPeriod) {
    var startDate = moment(concessionPeriod === null || concessionPeriod === void 0 ? void 0 : concessionPeriod.startDate, "YYYY-MM-DD").startOf("day");
    var endDate = moment(concessionPeriod === null || concessionPeriod === void 0 ? void 0 : concessionPeriod.endDate, "YYYY-MM-DD").startOf("day");
    var currentDay = day.startOf("day");
    return !currentDay.isBetween(startDate, endDate, undefined, "[]");
}
export function atLeastFiveDays(startDate, day) {
    if (!startDate)
        return;
    var diff = day.diff(startDate, "days") + 1;
    return diff > 4;
}
export function exceedsAvailableDays(startDate, day, availableDays) {
    if (!startDate || !day.isValid() || typeof availableDays !== "number")
        return false;
    var diffInDays = day.diff(startDate, "days");
    return diffInDays >= availableDays;
}
export function exceedMinimumReaminingDays(startDate, day, availableDays) {
    if (!startDate || !day.isValid() || typeof availableDays !== "number")
        return false;
    var diffInDays = day.diff(startDate, "days");
    var remainingDays = availableDays - diffInDays;
    return diffInDays <= availableDays && remainingDays <= 5;
}
export function isBeforeMinimumDays(day, config) {
    var minimumDate = moment()
        .add(config === null || config === void 0 ? void 0 : config.minimumRequestTime, "days")
        .startOf("day");
    return day.isBefore(minimumDate, "day");
}
