import { FormType, SectionForm } from "@/services/form/types";
import { Grid } from "@material-ui/core";
import React, { useCallback } from "react";
import { renderFormField } from "../../../helpers";
import { useFormik } from "formik";
import {
  createInitialValues,
  generateValidationSchema,
} from "../../../helpers/formik";
import { applyTypeOnFields } from "../helpers";
import { OnChangeOptions } from "@/pages/FormBySection/helpers/types";

interface Props {
  form: SectionForm;
  isMobile: boolean;
}

export interface FormValues {
  formType: FormType;
  formId: string;
  fields: any;
}

export interface DefaultFormHandle {
  submitForm: () => [boolean] | [boolean, FormValues];
}

export default React.forwardRef<DefaultFormHandle, Props>(
  (props: Props, ref) => {
    const form = useFormik<any>({
      initialValues: createInitialValues({ fields: props.form.fields }),
      onSubmit: (values) => values,
      validationSchema: generateValidationSchema(props.form.fields),
      enableReinitialize: true,
      validateOnMount: true,
    });

    React.useImperativeHandle(
      ref,
      () => ({
        submitForm: () => {
          form.handleSubmit();

          const errors = form?.errors ?? {};
          const isValid = Object.keys(errors).length === 0;

          if (!isValid) {
            return [isValid];
          }
          const fieldsValuesWithType = applyTypeOnFields({
            isList: false,
            fields: props.form.fields,
            values: form.values,
          });
          return [
            isValid,
            {
              formId: props.form.id,
              formType: props.form.type,
              fields: fieldsValuesWithType,
            },
          ];
        },
      }),
      [form, props.form]
    );

    const getErrorField = useCallback(
      (fieldId: string) => {
        try {
          const error = (form?.errors as any)[fieldId];
          const touched = (form?.touched as any)[fieldId];
          if (error && touched) {
            return error;
          }
        } catch (error) {
          return "";
        }
      },
      [form?.errors, form?.touched]
    );

    const getFieldValue = useCallback(
      (fieldId: string) => {
        return form.values[fieldId];
      },
      [form.values]
    );

    const showForm = props.form.fields.find((field) => !field.hidden);

    return showForm ? (
      <Grid container spacing={2} justifyContent="center" key={props.form.id} style={{
        padding: '0 24px 24px 24px'
      }}>
        <Grid item xs={12}>
          <h4>{props.form.title}</h4>
        </Grid>

        {props.form?.fields.map((field) => {
          return (
            <Grid
              item
              xs={12}
              key={field.id}
              style={field.hidden ? { display: "none" } : {}}
            >
              {renderFormField(field.type, {
                label: field.label,
                id: field.id,
                required: field.required,
                options: field.data?.options,
                fields: field.data?.fields,
                showForOptionValues: field.data?.showForOptionValues,
                onChange: (
                  value: any,
                  _fieldId: any,
                  options?: OnChangeOptions
                ) => {
                  if (options?.fieldsValues) {
                    options.fieldsValues.map((fieldOpt) => {
                      form.setFieldValue(fieldOpt.fieldId, fieldOpt.value);
                      return fieldOpt;
                    });
                    return;
                  }
                  form.setFieldValue(`${field.id}`, value);
                },
                value: getFieldValue(field.id),
                error: getErrorField(field.id),
                isMobile: props.isMobile,
                data: field.data,
                additionalText: field.data?.additionalText,
                hidden: field.hidden,
              }, props.form.id, form.values)}
            </Grid>
          );
        })}
      </Grid>
    ) : null;
  }
);
