import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { makeStyles } from "@material-ui/core";
export var useStyles = makeStyles(function () { return ({
    header: {
        padding: "0 20px",
        borderBottom: "1px solid ".concat(Tokens.neutralColor.medium[700]),
        background: Tokens.neutralColor.light[1000],
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        minHeight: "122px",
        width: "100%",
        height: "96px",
    },
    headerActions: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    actionsContainer: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },
    button: {
        color: Tokens.interfaceColor.primary.pure,
        minWidth: 42,
        height: 42,
        "&:hover": {
            backgroundColor: Tokens.interfaceColor.primary.light,
        },
    },
    cancelButton: {
        color: Tokens.feedbackColor.error.pure,
        minWidth: 42,
        height: 42,
        "&:hover": {
            color: Tokens.feedbackColor.error.dark,
            backgroundColor: Tokens.feedbackColor.error.light,
        },
    },
    buttonClose: {
        color: Tokens.neutralColor.medium[400],
        minWidth: 0,
        "&:hover": {
            backgroundColor: Tokens.neutralColor.light[1000],
        },
    },
}); });
