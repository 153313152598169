var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState } from "react";
export var Step;
(function (Step) {
    Step[Step["SELECT_HISTORY_PERIOD"] = 0] = "SELECT_HISTORY_PERIOD";
    Step[Step["SELECT_TYPE"] = 1] = "SELECT_TYPE";
    Step[Step["REQUEST"] = 2] = "REQUEST";
    Step[Step["REVIEW"] = 3] = "REVIEW";
})(Step || (Step = {}));
var initialStep = Step.REQUEST;
export var useStepper = function (props) {
    var _a = __read(useState(function () {
        if (props.showHistoryStep)
            return Step.SELECT_HISTORY_PERIOD;
        return initialStep;
    }), 2), step = _a[0], setStep = _a[1];
    var goTo = function (newStep) {
        setStep(newStep);
    };
    var getLabelSubmit = function () {
        if (step === Step.SELECT_TYPE || step === Step.SELECT_HISTORY_PERIOD) {
            return "Continuar";
        }
        if (step === Step.REQUEST) {
            return "Continuar para conferência";
        }
        else if (props.isHistory) {
            return "Criar período de férias";
        }
        else {
            return "Confirmar solicitação";
        }
    };
    var labelSubmitButton = getLabelSubmit();
    return {
        step: step,
        labelSubmitButton: labelSubmitButton,
        goTo: goTo,
    };
};
