var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion, AccordionDetails, AccordionSummary, makeStyles, } from "@material-ui/core";
import { KeyboardArrowDownRounded } from "@material-ui/icons";
import * as S from "./styles";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
var useStyles = makeStyles({
    accordionSummary: {
        padding: 0,
        "& p": {
            fontSize: "16px",
            fontWeight: 600,
            color: Tokens.neutralColor.dark[300],
        },
    },
    accordionDetailsRoot: {
        padding: 0,
        marginTop: '8px',
        "& > div": {
            width: "100%",
        },
    },
});
export function ConditionalAccordion(_a) {
    var children = _a.children, isAccordion = _a.isAccordion, isExpanded = _a.isExpanded, title = _a.title;
    var classes = useStyles();
    if (isAccordion) {
        return (_jsxs(Accordion, __assign({ style: { backgroundColor: Tokens.neutralColor.light[1000] }, defaultExpanded: isExpanded }, { children: [_jsx(AccordionSummary, __assign({ expandIcon: _jsx(KeyboardArrowDownRounded, {}), classes: {
                        root: classes.accordionSummary,
                    } }, { children: typeof title === "string" ? (_jsx(S.FormTitle, __assign({ "data-testid": "title-container", isAccordion: isAccordion }, { children: title }))) : (title) })), _jsx(AccordionDetails, __assign({ classes: {
                        root: classes.accordionDetailsRoot,
                    } }, { children: children })), _jsx("div", { style: { marginTop: "24px" } })] })));
    }
    return (_jsxs("div", { children: [typeof title === "string" ? (_jsx(S.FormTitle, __assign({ "data-testid": "title-container", isAccordion: isAccordion }, { children: title }))) : (title), children] }));
}
