var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import * as S from "./styles";
import { TextField } from "@material-ui/core";
export var InputComment = React.memo(function (props) {
    var _a = __read(useState(""), 2), value = _a[0], setValue = _a[1];
    return (_jsxs(S.CommentContainer, { children: [_jsx("h3", { children: "Coment\u00E1rio" }), _jsx(TextField, __assign({}, props, { size: "small", variant: "outlined", helperText: "A empresa ter\u00E1 acesso ao coment\u00E1rio", value: value, label: "Coment\u00E1rio (opcional)", onChange: function (e) { return setValue(e.target.value); }, multiline: true, minRows: 4, onBlur: props.onBlur }))] }));
});
