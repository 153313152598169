var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { HiOutlineArrowsExpand } from 'react-icons/hi';
var VariantType;
(function (VariantType) {
    VariantType["success"] = "success";
    VariantType["error"] = "error";
    VariantType["warning"] = "warning";
    VariantType["info"] = "info";
})(VariantType || (VariantType = {}));
function useNotification() {
    var _a = useSnackbar(), closeSnackbar = _a.closeSnackbar, enqueueSnackbar = _a.enqueueSnackbar;
    var notify = useCallback(function notify(content, variant, option) {
        var _a = option.position, position = _a === void 0 ? 'top' : _a, rest = __rest(option, ["position"]);
        var key = enqueueSnackbar(content, __assign(__assign({ anchorOrigin: {
                horizontal: 'right',
                vertical: position,
            }, autoHideDuration: 2000, onClick: function () { return closeSnackbar(key); }, variant: variant, iconVariant: __assign(__assign({}, option.iconVariant), { info: _jsx(HiOutlineArrowsExpand, { style: { marginRight: 8 } }) }) }, option), rest));
    }, [closeSnackbar, enqueueSnackbar]);
    var success = useCallback(function success(content, option) {
        if (option === void 0) { option = {}; }
        notify(content, VariantType.success, option);
    }, [notify]);
    var error = useCallback(function error(content, option) {
        if (option === void 0) { option = {}; }
        notify(content, VariantType.error, option);
    }, [notify]);
    var warning = useCallback(function warning(content, option) {
        if (option === void 0) { option = {}; }
        notify(content, VariantType.warning, option);
    }, [notify]);
    var info = useCallback(function warning(content, option) {
        if (option === void 0) { option = {}; }
        notify(content, VariantType.info, option);
    }, [notify]);
    return useMemo(function () { return ({
        error: error,
        success: success,
        warning: warning,
        info: info
    }); }, [error, success, warning, info]);
}
export default useNotification;
