import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import styled from "styled-components";

export const Container = styled.div``;

export const FieldLabel = styled.p`
  color: ${Tokens.neutralColor.medium[400]};
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  width: 200px;
`;

export const FieldValue = styled.p`
  margin: 0;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  word-break: break-all;
`;

export const FormInfo = styled.div<{ isMobile: boolean }>`
  display: grid;
  margin: 16px 0px;
  /* justify-content: ${(props) => (props.isMobile ? "" : "")}; */
  /* flex-direction: ${(props) => (props.isMobile ? "column" : "row")}; */
  grid-template-columns: ${(props) => (props.isMobile ? "auto" : "300px auto")};
`;

export const SectionTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  width: 100%;
`;

export const SectionConfig = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${Tokens.neutralColor.medium["700"]};
  padding: 28px 24px;
`;

export const FormTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #000;
`;
