var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
import { useEmployeeVacationSummary } from "../../components/RequestInfo/hooks/useEmployeeVacationSummary";
import { Step } from "../../hooks/useStepper";
var EmployeeVacationSummaryCtx = createContext({});
export var EmployeeVacationSummaryProvider = function (props) {
    var _a = __read(useState(null), 2), typeVacation = _a[0], setTypeVacation = _a[1];
    var handleTypeVacation = function (value) { return setTypeVacation(value); };
    var _b = __read(useState(Step.REQUEST), 2), initialStep = _b[0], setInicitalStep = _b[1];
    var _c = __read(useState(function () {
        var _a, _b;
        return {
            startDate: (_a = props.historyRules) === null || _a === void 0 ? void 0 : _a.startDate,
            endDate: (_b = props.historyRules) === null || _b === void 0 ? void 0 : _b.endDate,
        };
    }), 2), customPeriod = _c[0], setCustomPeriod = _c[1];
    var _d = useEmployeeVacationSummary({
        onEmployeeChange: function (data) {
            if (data) {
                var hasRemainingBalance = data.remainingBalance > 0;
                var hasRemaingingBalanceOnNextPeriod = data.nextConcessionPeriod.balance > 0;
                if (!hasRemainingBalance && hasRemaingingBalanceOnNextPeriod) {
                    setInicitalStep(Step.SELECT_TYPE);
                }
                props.onEmployeeChange(data);
            }
        },
        initialEmployeeId: props.initialEmployeeId,
        startDate: customPeriod.startDate,
        endDate: customPeriod.endDate,
    }), handleCurrentEmployeeId = _d.handleCurrentEmployeeId, summary = _d.summary, resetEmployeeSummary = _d.resetEmployeeSummary, verifyHasEmployee = _d.verifyHasEmployee, isLoadingSummary = _d.isLoading, getNextPeriod = _d.getNextPeriod, nextPeriodSummary = _d.nextPeriodSummary;
    var handleCustomPeriod = function (period) {
        setCustomPeriod(__assign({}, period));
    };
    return (_jsx(EmployeeVacationSummaryCtx.Provider, __assign({ value: {
            handleCurrentEmployeeId: handleCurrentEmployeeId,
            summary: summary,
            resetEmployeeSummary: resetEmployeeSummary,
            verifyHasEmployee: verifyHasEmployee,
            isLoadingSummary: isLoadingSummary,
            typeVacation: typeVacation,
            handleTypeVacation: handleTypeVacation,
            getNextPeriod: getNextPeriod,
            nextPeriodSummary: nextPeriodSummary,
            initialStep: initialStep,
            handleCustomPeriod: handleCustomPeriod,
            customPeriod: customPeriod,
        } }, { children: props.children })));
};
export var useEmployeeVacationSummaryCtx = function () {
    var context = useContext(EmployeeVacationSummaryCtx);
    if (!context) {
        throw new Error("useEmployeeVacationSummary must be used within an EmployeeVacationSummaryProvider");
    }
    return context;
};
