var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Tokens } from "../../../types/tokenColor";
import { makeStyles } from "@material-ui/core";
import styled from "styled-components";
export var useStyles = makeStyles(function (theme) { return ({
    root: {
        display: "flex",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: 870,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 870,
        overflowY: "hidden",
    },
}); });
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .adm-drawer-header {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: row-reverse;\n    align-items: center;\n    padding: 24px;\n\n    svg {\n      width: 24px;\n      height: 24px;\n      color: ", ";\n    }\n  }\n\n  .adm-drawer-content {\n    padding: 0px;\n  }\n"], ["\n  .adm-drawer-header {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: row-reverse;\n    align-items: center;\n    padding: 24px;\n\n    svg {\n      width: 24px;\n      height: 24px;\n      color: ", ";\n    }\n  }\n\n  .adm-drawer-content {\n    padding: 0px;\n  }\n"])), Tokens.neutralColor.medium[400]);
export var StepReview = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 50% 50%;\n"], ["\n  display: grid;\n  grid-template-columns: 50% 50%;\n"])));
export var StepWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: ", ";\n"], ["\n  display: ", ";\n"])), function (props) { return (props.isVisible ? "block" : "none"); });
var templateObject_1, templateObject_2, templateObject_3;
