var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Card, Grid, Typography } from "@material-ui/core";
import * as S from "./styles";
import React from "react";
export var Comment = React.memo(function (_a) {
    var _b, _c, _d;
    var comment = _a.comment;
    var styles = S.useStyles();
    if (comment === null) {
        return null;
    }
    return (_jsxs(Grid, { children: [_jsx(Typography, __assign({ className: styles.title }, { children: "Coment\u00E1rio" })), _jsx(Card, __assign({ variant: "outlined" }, { children: _jsxs(Grid, __assign({ className: styles.cardContainer }, { children: [_jsx(Avatar, { src: (_b = comment.commentedBy.profilePictureUrl) !== null && _b !== void 0 ? _b : '', className: styles.cardAvatar }), _jsxs(Grid, { children: [_jsx(Typography, __assign({ className: styles.cardName }, { children: (_c = comment.commentedBy.name) !== null && _c !== void 0 ? _c : '-' })), _jsx(Typography, __assign({ className: styles.cardComment }, { children: (_d = comment.content) !== null && _d !== void 0 ? _d : '' }))] })] })) }))] }));
});
