var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Menu, Tooltip, Typography } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useStyles } from "./styles";
import ApproveVacationDialog from "../Modals/ApproveVacationDialog";
import DisapproveVacationDialog from "../Modals/DisapproveVacationDialog";
import DeleteVacationDialog from "../Modals/DeleteVacationDialog";
import ShareByEmailDialog from "../Modals/ShareByEmailDialog";
import useFollowUpDrawerService from "../../../../vacations/FollowUpDetailsDrawer/hooks/useFollowUpDrawerService";
import { useExternalApiContext } from "../../../../../hooks/useExternalApi";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
export var Header = function (_a) {
    var _b, _c;
    var _d = _a.approvalBy, approvalBy = _d === void 0 ? "SPONSOR" : _d, props = __rest(_a, ["approvalBy"]);
    var _e = useExternalApiContext(), api = _e.api, sponsorId = _e.sponsorId;
    var _f = useFollowUpDrawerService(props.vacation, approvalBy, props.managerId), approveDialog = _f.approveDialog, deleteVacationDialog = _f.deleteVacationDialog, disapproveDialog = _f.disapproveDialog, shareByEmailDialog = _f.shareByEmailDialog, handleShareByEmailService = _f.handleShareByEmailService, isShareByEmailServiceLoading = _f.isShareByEmailServiceLoading, handleApproveVacation = _f.handleApproveVacation, isApprovalServiceLoading = _f.isApprovalServiceLoading, handleDeleteService = _f.handleDeleteService, isDeleteServiceLoading = _f.isDeleteServiceLoading;
    var classes = useStyles();
    var _g = __read(React.useState(null), 2), anchorEl = _g[0], setAnchorEl = _g[1];
    var handleCloseMenu = function () {
        setAnchorEl(null);
    };
    var _h = __read(useState(""), 2), reason = _h[0], setReason = _h[1];
    var hideButtons = useMemo(function () {
        var _a;
        if (typeof props.shouldHideActionsButton === "function") {
            return props.shouldHideActionsButton((_a = props.vacation) === null || _a === void 0 ? void 0 : _a.status);
        }
        return props.shouldHideActionsButton;
    }, [props]);
    function renderDeleteButton() {
        var deleteButton = (_jsx(Tooltip, __assign({ title: "Cancelar solicita\u00E7\u00E3o", arrow: true }, { children: _jsx(Button, __assign({ classes: { root: classes.cancelButton }, onClick: deleteVacationDialog.show }, { children: _jsx("i", { className: "uil uil-trash-alt", style: { fontSize: 24 } }) })) })));
        if (typeof props.shouldHideDeleteButton === "function") {
            var hideButton = props.shouldHideDeleteButton(props.vacation.status);
            if (!hideButton)
                return deleteButton;
        }
        if (!props.shouldHideDeleteButton) {
            return deleteButton;
        }
    }
    var title = props.title;
    return (_jsxs("header", __assign({ className: classes.header }, { children: [_jsx(ShareByEmailDialog, { isLoading: isShareByEmailServiceLoading, isOpen: shareByEmailDialog.isVisible, onClose: shareByEmailDialog.hide, onConfirm: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, handleShareByEmailService([
                                    {
                                        api: api,
                                        sponsorId: sponsorId,
                                        employeeId: props.vacation.employeeId,
                                        vacationId: props.vacation.id,
                                        payload: payload,
                                    },
                                ])];
                            case 1:
                                _a.sent();
                                shareByEmailDialog.hide();
                                return [2 /*return*/];
                        }
                    });
                }); } }), _jsx(DeleteVacationDialog, { isLoading: isDeleteServiceLoading, isOpen: deleteVacationDialog.isVisible, onClose: deleteVacationDialog.hide, onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, handleDeleteService([
                                    {
                                        api: api,
                                        sponsorId: sponsorId,
                                        employeeId: props.vacation.employeeId,
                                        vacationId: props.vacation.id,
                                    },
                                ])];
                            case 1:
                                _a.sent();
                                deleteVacationDialog.hide();
                                return [4 /*yield*/, props.onRefetch()];
                            case 2:
                                _a.sent();
                                props.onDrawerClose();
                                return [2 /*return*/];
                        }
                    });
                }); } }), _jsx(ApproveVacationDialog, { isLoading: isApprovalServiceLoading, isOpen: approveDialog.isVisible, onClose: approveDialog.hide, onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, handleApproveVacation({
                                    approve: true,
                                    reason: "",
                                })];
                            case 1:
                                _a.sent();
                                return [4 /*yield*/, props.onRefetch()];
                            case 2:
                                _a.sent();
                                props.onDrawerClose();
                                return [2 /*return*/];
                        }
                    });
                }); }, description: props.approvalDescription }), _jsx(DisapproveVacationDialog, { isLoading: isApprovalServiceLoading, isOpen: disapproveDialog.isVisible, reason: reason, onSetReason: setReason, onClose: function () {
                    setReason("");
                    disapproveDialog.hide();
                }, onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, handleApproveVacation({
                                    approve: false,
                                    reason: reason,
                                })];
                            case 1:
                                _a.sent();
                                setReason("");
                                disapproveDialog.hide();
                                props.onDrawerClose();
                                return [4 /*yield*/, props.onRefetch()];
                            case 2:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); }, description: props.disapproveDescription }), _jsxs("div", __assign({ className: classes.headerActions }, { children: [_jsx(Typography, __assign({ style: { fontSize: "24px", fontWeight: 700 } }, { children: title })), _jsxs("div", __assign({ className: classes.actionsContainer }, { children: [renderDeleteButton(), props.showShareButton ? (_jsx(Tooltip, __assign({ title: "Compartilhar por e-mail", arrow: true }, { children: _jsx(Button, __assign({ classes: {
                                        root: classes.button,
                                    }, onClick: shareByEmailDialog.show }, { children: _jsx("i", { className: "uil uil-location-arrow", style: { fontSize: 24 } }) })) }))) : null, !hideButtons && ((_c = (_b = props.vacation) === null || _b === void 0 ? void 0 : _b.manager) === null || _c === void 0 ? void 0 : _c.id) === props.managerId ? (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ variant: "outlined", startIcon: _jsx(IoClose, { size: 24 }), style: {
                                            color: Tokens.feedbackColor.error.pure,
                                            borderColor: Tokens.feedbackColor.error.pure,
                                            height: "42px",
                                        }, onClick: disapproveDialog.show }, { children: "Reprovar" })), _jsx(Button, __assign({ variant: "outlined", startIcon: _jsx("i", { className: "uil uil-check", style: {
                                                fontSize: 24,
                                                color: Tokens.feedbackColor.success.pure,
                                                height: 42,
                                            } }), style: {
                                            color: Tokens.feedbackColor.success.pure,
                                            borderColor: Tokens.feedbackColor.success.pure,
                                            height: "42px",
                                        }, onClick: approveDialog.show }, { children: "Aprovar" }))] })) : null, _jsx(Button, __assign({ onClick: function () { return props.onDrawerClose(); }, classes: {
                                    root: classes.buttonClose,
                                } }, { children: _jsx(IoClose, { size: 24 }) })), _jsx(Menu, { id: "simple-menu", anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: handleCloseMenu, anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                }, transformOrigin: {
                                    vertical: "bottom",
                                    horizontal: "right",
                                } })] }))] }))] })));
};
