var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DrawerDetails } from "../../";
import { VacationFollowUpStatus, } from "../../../../../services/vacations/followUp/types";
import useFollowUpDrawerService from "../../../../../components/vacations/FollowUpDetailsDrawer/hooks/useFollowUpDrawerService";
import { LocalLoading } from "@caju-beneficios/react-web-commons";
import { renderFlagMessagesSponsor } from "../../utils/flagMessages";
export var DrawerVacationDetailsManagerBoard = function (props) {
    var _a;
    var _b = useFollowUpDrawerService(props.vacation), vacationDetail = _b.data, isGetByIdLoading = _b.isGetByIdLoading;
    return (_jsx(DrawerDetails.Drawer, __assign({ isOpen: props.isOpen, onClose: props.onClose }, { children: isGetByIdLoading ? (_jsx(LocalLoading, { show: true })) : (_jsx(DrawerDetails.Body, { onClose: props.onClose, onRefetch: function () { return props.onRefetch(); }, vacation: props.vacation, header: _jsx(DrawerDetails.Header, { title: props.title, onDrawerClose: props.onClose, onRefetch: props.onRefetch, vacation: props.vacation, shouldHideDeleteButton: true, shouldHideActionsButton: true, approvalBy: "MANAGER", managerId: vacationDetail === null || vacationDetail === void 0 ? void 0 : vacationDetail.manager.id }), content: _jsx(DrawerDetails.Content, { vacationDetails: vacationDetail, onOpenSignatureDrawer: props.onOpenSignatureDrawer, isEmployee: true }), sideBar: _jsxs("div", { children: [_jsx(DrawerDetails.SideBar.Status, { showOnVacationImg: function (status) {
                            return status === VacationFollowUpStatus.APPROVED_IN_VACATION;
                        }, deniedReason: (_a = vacationDetail === null || vacationDetail === void 0 ? void 0 : vacationDetail.deniedReason) === null || _a === void 0 ? void 0 : _a.reason, flagMessages: function () {
                            return renderFlagMessagesSponsor(vacationDetail === null || vacationDetail === void 0 ? void 0 : vacationDetail.status);
                        }, status: vacationDetail === null || vacationDetail === void 0 ? void 0 : vacationDetail.status, anticipated: vacationDetail === null || vacationDetail === void 0 ? void 0 : vacationDetail.anticipated }), _jsx(DrawerDetails.SideBar.Divider, {}), _jsx(DrawerDetails.SideBar.Info, { admittanceDate: vacationDetail === null || vacationDetail === void 0 ? void 0 : vacationDetail.employee.admittanceDate, completedAcquisitionPeriod: vacationDetail === null || vacationDetail === void 0 ? void 0 : vacationDetail.completedAcquisitionPeriod })] }) })) })));
};
