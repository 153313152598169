import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { makeStyles } from "@material-ui/core";
export var useStyles = makeStyles(function () { return ({
    container: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflowY: "hidden",
    },
    drawerBody: {
        display: "flex",
        height: "100vh",
    },
    contentSideBar: {
        backgroundColor: Tokens.neutralColor.light[900],
    },
}); });
