import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import styled from "styled-components";

export const TitleForm = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid ${Tokens.neutralColor.medium["700"]};
  padding: 28px 24px;
`;
