var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, makeStyles, Paper, TextField, Typography, } from "@material-ui/core";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { Autocomplete } from "@material-ui/lab";
function removeDuplicates(items, predicate) {
    var seenIds = new Set();
    return items.filter(function (item) {
        if (seenIds.has(predicate(item))) {
            return false;
        }
        seenIds.add(predicate(item));
        return true;
    });
}
var useStyles = makeStyles({
    title: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: Tokens.lineHight.xl,
        color: Tokens.neutralColor.dark[100],
    },
    errorTitle: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: Tokens.lineHight.xl,
        color: Tokens.feedbackColor.error.medium,
    },
    description: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: Tokens.lineHight.xl,
        color: Tokens.neutralColor.dark[100],
    },
    BoxContent: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        width: 375,
        margin: "24px 0",
    },
    optionsMenu: {
        display: "flex",
        flexDirection: "column",
    },
    optionsNameMenu: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: Tokens.lineHight.lg,
        color: Tokens.neutralColor.dark[200],
    },
    optionsEmailMenu: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: Tokens.lineHight.xl,
        color: Tokens.neutralColor.medium[400],
    },
    inputProps: {
        fontSize: 16,
        fontWeight: 400,
        color: Tokens.neutralColor.medium[400],
    },
});
export function SelectItem(_a) {
    var label = _a.label, onChanged = _a.onChanged, clearChanged = _a.clearChanged, dataSource = _a.dataSource, showOption = _a.showOption, showSubOption = _a.showSubOption;
    var classes = useStyles();
    var _b = __read(useState(function () { return ""; }), 2), currentInputValue = _b[0], setCurrentInputValue = _b[1];
    function handleSelectEmployee(value) {
        if (value) {
            onChanged(value);
            setCurrentInputValue(clearChanged ? "" : showOption(value));
        }
        if (!value) {
            onChanged(null);
            setCurrentInputValue("");
        }
    }
    var dataSourceFiltered = removeDuplicates(dataSource.filter(function (item) { return showOption(item); }), showOption);
    return (_jsx(Autocomplete, { fullWidth: true, options: dataSourceFiltered.map(function (item) {
            var _a, _b;
            return __assign(__assign({}, item), { department: (_a = item === null || item === void 0 ? void 0 : item.departmentData) === null || _a === void 0 ? void 0 : _a.name, costCenter: (_b = item === null || item === void 0 ? void 0 : item.costCenterData) === null || _b === void 0 ? void 0 : _b.name });
        }), noOptionsText: "Nenhum resultado encontrado", getOptionLabel: function (option) { return "".concat(showOption(option)); }, getOptionSelected: function (option, value) {
            return showOption(option) === showOption(value);
        }, inputValue: currentInputValue, renderOption: function (option) { return (_jsxs(Box, __assign({ className: classes.optionsMenu }, { children: [_jsx(Typography, __assign({ className: classes.optionsNameMenu }, { children: showOption(option) })), _jsx(Typography, __assign({ className: classes.optionsEmailMenu }, { children: showSubOption && showSubOption(option) }))] }))); }, onInputChange: function (_, val, reason) {
            if (reason === "input") {
                setCurrentInputValue(val);
                if (!val) {
                    onChanged(null);
                }
            }
            if (reason === "clear") {
                setCurrentInputValue(val);
                onChanged(null);
            }
        }, onChange: function (_, newValue) {
            if (newValue) {
                handleSelectEmployee(newValue);
            }
        }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { "aria-label": "input-label", label: label, variant: "outlined", size: "small", InputLabelProps: { className: classes.inputProps, shrink: true } }))); }, PaperComponent: function (_a) {
            var children = _a.children;
            return _jsx(Paper, __assign({ "data-testid": "paper-list-employees" }, { children: children }));
        } }));
}
