import { makeStyles } from "@material-ui/core";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
export var useStyles = makeStyles({
    rootCard: {
        background: Tokens.neutralColor.light[800],
        height: "auto",
        width: "100%",
        padding: "16px 0",
        borderRadius: Tokens.borderRadius.medium,
        marginBottom: "12px",
    },
    caption: {
        fontSize: "12px",
        fontWeight: 500,
        marginBottom: "16px",
        padding: "16px 0 0 16px",
        color: Tokens.neutralColor.medium[400],
    },
    item: {
        padding: "0 16px",
    },
    border: {
        borderRight: "1px solid ".concat(Tokens.neutralColor.medium[600]),
    },
    title: {
        fontSize: "14px",
        fontWeight: 500,
        marginBottom: "8px",
        color: Tokens.neutralColor.dark[300],
    },
    iconButton: {
        padding: 0,
        marginLeft: "8px",
        "&:hover": {
            backgroundColor: "transparent"
        }
    }
});
