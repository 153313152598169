import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEmployeeVacationSummaryCtx } from "../../contexts/EmployeeVacationSummary";
import { CardEmployeeSelected } from "../RequestInfo/components/Settings/components/CardEmployeeSelected";
import { SelectEmployeesWithVacation } from "../RequestInfo/components/Settings/components/SelectEmployeesWithVacation";
export function EmployeeInfo(props) {
    var _a, _b;
    var _c = useEmployeeVacationSummaryCtx(), summary = _c.summary, handleCurrentEmployeeId = _c.handleCurrentEmployeeId, isLoadingSummary = _c.isLoadingSummary;
    var hasSummary = Boolean(summary === null || summary === void 0 ? void 0 : summary.employee.id);
    var showSelected = !hasSummary && props.requester === "SPONSOR";
    return (_jsxs("div", { children: [_jsx(SelectEmployeesWithVacation, { isVisible: showSelected, onChange: function (value) {
                    if (value === null || value === void 0 ? void 0 : value.employeeId) {
                        handleCurrentEmployeeId(value.employeeId);
                    }
                } }), summary ? (_jsx(CardEmployeeSelected, { isLoading: isLoadingSummary, employeeName: (_a = summary.employee.name) !== null && _a !== void 0 ? _a : "", employeePostion: (_b = summary.employee.position) !== null && _b !== void 0 ? _b : "", profilePicture: summary.employee.profilePictureUrl, onClickReset: function () {
                    //reset
                    if (props.onClickReset) {
                        props.onClickReset();
                    }
                }, showResetButton: props.showResetButton })) : null] }));
}
