import { jsx as _jsx } from "react/jsx-runtime";
import { Tokens } from "@caju-beneficios/sponsor-v2-lib/dist/configs/tokens/tokens";
import { Close, WatchLaterOutlined } from "@material-ui/icons";
import { FiCheck } from "react-icons/fi";
import { CalendarCheckIcon } from "../components/CalendarCheckIcon";
import { ClockIcon } from "../components/ClockIcon";
import { SunIcon } from "../components/SunIcon";
var commonCssProperties = { width: "18px", height: "18px" };
export var flagProperties = {
    APPROVED_IN_VACATION: {
        backgroundColor: Tokens.categoryColor.health.light,
        color: Tokens.feedbackColor.warning.dark,
        icon: _jsx(SunIcon, {}),
        label: "Em férias",
    },
    APPROVED_SCHEDULED: {
        backgroundColor: Tokens.categoryColor.culture.light,
        color: Tokens.feedbackColor.success.dark,
        icon: _jsx(CalendarCheckIcon, {}),
        label: "Férias agendadas",
    },
    AWAITING_APPROVAL: {
        backgroundColor: Tokens.categoryColor.food.light,
        color: Tokens.neutralColor.dark[100],
        icon: _jsx(WatchLaterOutlined, { style: commonCssProperties }),
        label: "Aguardando aprovação do RH",
    },
    DENIED: {
        backgroundColor: Tokens.feedbackColor.error.light,
        color: Tokens.feedbackColor.error.dark,
        icon: _jsx(Close, { style: commonCssProperties }),
        label: "Reprovada",
    },
    PAST_APPROVED: {
        backgroundColor: Tokens.feedbackColor.success.light,
        color: Tokens.feedbackColor.success.dark,
        icon: _jsx(FiCheck, { style: commonCssProperties }),
        label: "Concluída",
    },
    PAST: {
        backgroundColor: "",
        color: "",
        icon: _jsx("div", {}),
        label: "",
    },
    PENDING_REVIEW: {
        backgroundColor: Tokens.categoryColor.transportation.light,
        color: Tokens.neutralColor.dark[100],
        icon: _jsx(ClockIcon, {}),
        label: "Em análise com o gestor",
    },
};
