import { makeStyles } from "@material-ui/core/styles";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
export var useStyles = makeStyles(function () { return ({
    header: {
        borderBottom: "1px solid ".concat(Tokens.neutralColor.medium[700])
    },
    menu: {
        width: '100%',
        maxWidth: 200
    },
    content: {
        width: '100%',
        height: '100vh',
        overflow: 'auto'
    },
    footer: {
        borderTop: "1px solid ".concat(Tokens.neutralColor.medium[700])
    }
}); });
