var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useMemo, useState } from "react";
function isAllowanceAvailableByPeriod(params) {
    var _a, _b, _c;
    if (((_a = params.period.startDate) === null || _a === void 0 ? void 0 : _a.isValid()) && ((_b = params.period.endDate) === null || _b === void 0 ? void 0 : _b.isValid())) {
        var selectedDays = (_c = params.period.endDate) === null || _c === void 0 ? void 0 : _c.diff(params.period.startDate, "days");
        var leftDays = params.remainingDays - selectedDays - 1;
        var daysLeftGreaterThanFive = leftDays - params.daysToSell < 5;
        if (leftDays === params.daysToSell)
            return false;
        return daysLeftGreaterThanFive;
    }
}
function getPartialDaysByAvailableDays(remainingBalance) {
    return Math.round(remainingBalance / 3);
}
var defaultAllowanceDays = 10;
export var useAllowanceOption = function (props) {
    var daysToSell = useMemo(function () {
        return props.allowPartialVacationSell
            ? getPartialDaysByAvailableDays(props.remainingBalance)
            : defaultAllowanceDays;
    }, [props.allowPartialVacationSell, props.remainingBalance]);
    var _a = __read(useState(null), 2), allowanceDays = _a[0], setAllowanceDays = _a[1];
    var _b = __read(useState(), 2), disableReason = _b[0], setDisableReason = _b[1];
    var getAllowanceEnable = useCallback(function () {
        var _a, _b, _c;
        setDisableReason(undefined);
        var selectedDays = (_b = (_a = props.period.endDate) === null || _a === void 0 ? void 0 : _a.diff(props.period.startDate, "days")) !== null && _b !== void 0 ? _b : 0;
        if (props.canTakeAllowance &&
            !props.overFourteenDays &&
            selectedDays &&
            selectedDays < 13 &&
            props.remainingBalance - (selectedDays + daysToSell) < 14) {
            setDisableReason("period");
            return false;
        }
        if (!props.canTakeAllowance) {
            if (typeof props.canTakeAllowance === "boolean") {
                setDisableReason("remaining");
            }
            return false;
        }
        var shouldEnable = isAllowanceAvailableByPeriod({
            period: props.period,
            remainingDays: (_c = props.remainingBalance) !== null && _c !== void 0 ? _c : 0,
            daysToSell: daysToSell,
        });
        if (shouldEnable && selectedDays) {
            setDisableReason("period");
            return false;
        }
        if (props.canTakeAllowance)
            return true;
    }, [
        props.canTakeAllowance,
        props.period,
        props.remainingBalance,
        props.overFourteenDays,
        daysToSell,
    ]);
    var handleAllowanceDays = function (value) {
        if (value === "Sim") {
            setAllowanceDays(true);
        }
        if (value === "Não") {
            setAllowanceDays(false);
        }
    };
    var shouldEnableAllowanceOption = useMemo(function () { return getAllowanceEnable(); }, [getAllowanceEnable]);
    var resetReason = function () { return setDisableReason(undefined); };
    return {
        shouldEnableAllowanceOption: shouldEnableAllowanceOption,
        disableReason: disableReason,
        handleAllowanceDays: handleAllowanceDays,
        allowanceDays: allowanceDays,
        resetReason: resetReason,
        daysToSell: daysToSell,
    };
};
