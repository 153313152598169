var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import * as S from "./styles";
import { Chip } from "@material-ui/core";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
export function AnticipationChip() {
    return (_jsxs(S.AnticipationContainer, { children: [_jsx(Chip, { label: _jsxs(S.LabelChipAnticipation, { children: [_jsx(MdKeyboardDoubleArrowLeft, { size: 20, color: Tokens.categoryColor.cashew.medium }), _jsx("p", { children: "F\u00E9rias antecipadas" })] }), style: {
                    backgroundColor: Tokens.categoryColor.cashew.ultraLight,
                    width: "fit-content",
                } }), _jsx("span", __assign({ style: {
                    fontWeight: 500,
                    color: Tokens.neutralColor.medium[400],
                    fontSize: "12px",
                    width: 220,
                } }, { children: "F\u00E9rias antecipadas com saldo do pr\u00F3ximo per\u00EDodo." }))] }));
}
