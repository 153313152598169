import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { makeStyles } from "@material-ui/core";
export var useStyles = makeStyles(function () { return ({
    button: {
        color: Tokens.interfaceColor.primary.pure,
        minWidth: 42,
        height: 42,
        "&:hover": {
            backgroundColor: Tokens.interfaceColor.primary.light,
        },
    },
}); });
