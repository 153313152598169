var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Grid, IconButton, makeStyles, Toolbar, Typography, } from "@material-ui/core";
import { Tokens } from "@caju-beneficios/sponsor-v2-lib/dist/configs/tokens/tokens";
import { GoUpload } from "react-icons/go";
import LockFolder from "./components/LockFolder";
import * as S from "./styles";
import { LocalLoading } from "@caju-beneficios/react-web-commons";
import { useDocuments } from "./hooks/useDocuments";
import FileCard from "./components/FileCard";
import DeleteFileDialog from "../Modals/DeleteFileDialog";
import CancelSignatureDialog from "../Modals/CancelSignatureDialog";
import { FileIcon } from "../../../FollowUpDetailsDrawer/icons/FileIcon";
var useStyles = makeStyles({
    toolbar: {
        marginTop: 10,
        minHeight: 42,
        width: "100%",
        backgroundColor: Tokens.interfaceColor.primary.light,
        borderRadius: 8,
    },
    box: {
        minHeight: 34,
        display: "flex",
        alignItems: "center",
        gap: 16,
    },
    text: {
        color: Tokens.interfaceColor.primary.dark,
        fontSize: 14,
        fontWeight: 500,
    },
    textButton: {
        color: Tokens.interfaceColor.primary.pure,
        fontSize: 12,
        fontWeight: 600,
    },
    button: {
        padding: "4px 12px",
    },
    boxButtons: {
        display: "flex",
        gap: 8,
    },
});
export default function Documents(_a) {
    var _this = this;
    var _b;
    var employeeId = _a.employeeId, vacationId = _a.vacationId, folderId = _a.folderId, onOpenSignatureDrawer = _a.onOpenSignatureDrawer, isEmployee = _a.isEmployee;
    var _c = useDocuments(employeeId, vacationId, folderId), toggleInputFile = _c.toggleInputFile, deleteDocumentDialog = _c.deleteDocumentDialog, documents = _c.documents, isDocumentsLoading = _c.isDocumentsLoading, isDeleteLoading = _c.isDeleteLoading, isSignatureDeleteLoading = _c.isSignatureDeleteLoading, deleteSignatureDialog = _c.deleteSignatureDialog, handleChangeFiles = _c.handleChangeFiles, handleDeleteDocuments = _c.handleDeleteDocuments, handleDeleteSignature = _c.handleDeleteSignature, handleDownloadFile = _c.handleDownloadFile, handlePreviewFile = _c.handlePreviewFile, handleNotifySignature = _c.handleNotifySignature, isNotifyLoading = _c.isNotifyLoading, isLoading = _c.isLoading, refetch = _c.refetch, selected = _c.selected, setSelected = _c.setSelected;
    var documentCount = !isDocumentsLoading && (documents === null || documents === void 0 ? void 0 : documents.length) ? "(".concat(documents.length, ")") : null;
    var classes = useStyles();
    var getRowsSelected = function (rowSelection) {
        return "".concat(rowSelection, " ").concat(rowSelection > 1 ? "selecionado(s)" : "selecionado");
    };
    var handleRequestMultipleSignatures = function () {
        var documentsSelected = documents === null || documents === void 0 ? void 0 : documents.filter(function (document) {
            return selected.includes(document.id);
        });
        if (!documentsSelected) {
            return;
        }
        onOpenSignatureDrawer &&
            onOpenSignatureDrawer({
                initialEmployeeId: documentsSelected[0].employeeId,
                onReload: refetch,
                initialFileInfoList: documentsSelected.map(function (document) {
                    var _a, _b, _c, _d;
                    return {
                        category: (_a = document === null || document === void 0 ? void 0 : document.category) !== null && _a !== void 0 ? _a : "",
                        fileId: (_b = document === null || document === void 0 ? void 0 : document.id) !== null && _b !== void 0 ? _b : "",
                        fileKey: (_c = document === null || document === void 0 ? void 0 : document.fileKey) !== null && _c !== void 0 ? _c : "",
                        fileName: (_d = document === null || document === void 0 ? void 0 : document.name) !== null && _d !== void 0 ? _d : "",
                        folderName: "Férias",
                    };
                }),
                isSimpleVersion: true,
            });
        setSelected([]);
    };
    var handleSelect = function (checked, document) {
        if (checked) {
            setSelected(__spreadArray(__spreadArray([], __read(selected), false), [document.id], false));
        }
        else {
            setSelected(selected.filter(function (s) { return s !== document.id; }));
        }
    };
    return (_jsxs(Grid, __assign({ container: true, style: { marginTop: "24px" } }, { children: [_jsx(DeleteFileDialog, { isOpen: deleteDocumentDialog.isVisible, isLoading: isDeleteLoading, fileName: (_b = deleteDocumentDialog.params) === null || _b === void 0 ? void 0 : _b.name, onClose: deleteDocumentDialog.hide, onConfirm: function () { return __awaiter(_this, void 0, void 0, function () {
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0: return [4 /*yield*/, handleDeleteDocuments({
                                    documentIds: [(_a = deleteDocumentDialog.params) === null || _a === void 0 ? void 0 : _a.id],
                                })];
                            case 1:
                                _b.sent();
                                deleteDocumentDialog.hide();
                                return [2 /*return*/];
                        }
                    });
                }); } }), _jsx(CancelSignatureDialog, { isOpen: deleteSignatureDialog.isVisible, isLoading: isSignatureDeleteLoading, onClose: deleteSignatureDialog.hide, onConfirm: function () { return __awaiter(_this, void 0, void 0, function () {
                    var _a, _b;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0: return [4 /*yield*/, handleDeleteSignature((_b = (_a = deleteSignatureDialog.params.signature) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : "")];
                            case 1:
                                _c.sent();
                                deleteSignatureDialog.hide();
                                return [2 /*return*/];
                        }
                    });
                }); } }), _jsxs(S.Container, { children: [_jsxs(Grid, { children: [_jsxs(Grid, __assign({ container: true, alignItems: "center", style: { gap: "8px" } }, { children: [_jsx(Typography, __assign({ style: {
                                            fontWeight: 600,
                                            color: Tokens.neutralColor.dark[100],
                                            fontSize: "16px",
                                        } }, { children: "Documentos" })), !isDocumentsLoading ? (_jsx(Typography, __assign({ style: {
                                            fontWeight: 400,
                                            color: Tokens.neutralColor.medium[400],
                                            fontSize: "14px",
                                        } }, { children: documentCount }))) : null] })), _jsxs(Grid, __assign({ container: true }, { children: [_jsx(LockFolder, {}), _jsx(Typography, __assign({ style: {
                                            fontWeight: 500,
                                            color: Tokens.neutralColor.medium[400],
                                            fontSize: "14px",
                                        } }, { children: "F\u00E9rias" }))] }))] }), !isEmployee ? (_jsx(Grid, { children: _jsxs(Button, __assign({ startIcon: _jsx(GoUpload, {}), color: "primary", onClick: function () { return toggleInputFile.show(); }, component: "label", disabled: isDocumentsLoading }, { children: ["Adicionar novo", toggleInputFile.isVisible ? (_jsx("input", { type: "file", hidden: true, onChange: function (event) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, handleChangeFiles(event)];
                                            case 1: return [2 /*return*/, _a.sent()];
                                        }
                                    }); }); }, accept: "image/jpg,image/png,.pdf", multiple: true })) : null] })) })) : null] }), selected.length > 0 && (_jsx(Toolbar, __assign({ className: classes.toolbar }, { children: _jsxs("div", __assign({ className: classes.box }, { children: [_jsx(Typography, __assign({ className: classes.text }, { children: getRowsSelected(selected.length) })), _jsx("div", __assign({ className: classes.boxButtons }, { children: _jsxs(IconButton, __assign({ color: "primary", className: classes.button, onClick: handleRequestMultipleSignatures }, { children: [_jsx(FileIcon, { color: Tokens.interfaceColor.primary.pure, style: { marginRight: 5 } }), _jsx(Typography, __assign({ className: classes.textButton }, { children: "Solicitar assinatura" }))] })) }))] })) }))), _jsx(Grid, __assign({ container: true, style: {
                    flexDirection: "column",
                    padding: "16px 0",
                    gap: "8px",
                } }, { children: isLoading ? (_jsx("div", __assign({ style: { marginTop: "-100px" } }, { children: _jsx(LocalLoading, { show: true }) }))) : (_jsx(_Fragment, { children: documents === null || documents === void 0 ? void 0 : documents.map(function (document) {
                        var _a;
                        return (_jsx(FileCard, { selected: selected.includes(document.id), onSelect: function (e) { return handleSelect(e, document); }, isEmployee: isEmployee, document: document, fileName: document.name, hasSignature: Boolean((_a = document.signature) === null || _a === void 0 ? void 0 : _a.id), signature: document.signature, onOpenSignatureDrawer: onOpenSignatureDrawer, onDeleteFile: function () {
                                deleteDocumentDialog.setParams(document);
                                deleteDocumentDialog.toggle();
                            }, onDeleteSignature: function () {
                                deleteSignatureDialog.setParams(document);
                                deleteSignatureDialog.toggle();
                            }, onDownloadFile: function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, handleDownloadFile(document.id, document.name)];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, onPreviewFile: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, handlePreviewFile(document.id)];
                                    case 1: return [2 /*return*/, _a.sent()];
                                }
                            }); }); }, onSendNotification: function () { return __awaiter(_this, void 0, void 0, function () { var _a, _b; return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0: return [4 /*yield*/, handleNotifySignature((_b = (_a = document.signature) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : "")];
                                    case 1: return [2 /*return*/, _c.sent()];
                                }
                            }); }); }, isSendNotificationLoading: isNotifyLoading, onRefetch: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, refetch()];
                                    case 1: return [2 /*return*/, _a.sent()];
                                }
                            }); }); } }, document.id));
                    }) })) }))] })));
}
