
import { FileValue, FormFieldType, SectionForm } from "@/services/form/types";
import LabelAndValueDefault from "../components/LabelAndValueDefault";
import LabelAndValueFile from "../components/LabelAndValueFile";
import LabelAndValueConditional from "../components/LabelAndValueConditional";
import { ReactNode } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import CustomAccordion from "@/components/CustomAccordion";
import { cpf } from "cpf-cnpj-validator";

interface Props {
  form: SectionForm;
  isMobile: boolean;
  position: number;
  isAccordion:boolean;
}

export default function FormValuesList(props: Props) {
  const renderWrapper = (children: ReactNode) => {
    if(props.isAccordion) {
      const accordionTitle = props.form.fields[0].value[props.position] as unknown as string ?? "";

      return (
        <CustomAccordion 
          isExpanded={props.isAccordion} 
          title={(
            <Grid 
              container 
              justifyContent="space-between" 
              alignItems="center"
              style={{ padding: "20px 0" }}
            >
              <Typography>
                {accordionTitle}
              </Typography>
              <span style={{ color: Tokens.neutralColor.medium[400] }}>Dependente {props.position + 1}</span>
            </Grid>
          )}
        >
          {children}
        </CustomAccordion>
      )
    } else {
      return (
        <div>
          {children}
        </div>
      )
    }
  }

  return (
    <>
      {renderWrapper(
        <>
          {props.form.fields
            .filter((field) => !field.hidden)
            .map((field) => {
              if (field.type === FormFieldType.FILE) {
                return (
                  <LabelAndValueFile
                    label={field.label}
                    value={field.value[props.position] as FileValue}
                    isMobile={props.isMobile}
                    key={`${field.label}-${props.position}}`}
                  />
                );
              }

              if (field.type === FormFieldType.MULTIDROPDOWN) {
                const parsedValue =
                  typeof field.value === "string"
                    ? JSON.parse(field.value as unknown as string)
                    : field.value;

                if (typeof parsedValue !== "object") {
                  const resultString = String(parsedValue).replace(/["[\]]/g, "");

                  return (
                    <LabelAndValueDefault
                      label={field.label}
                      value={resultString}
                      type={field.type}
                      isMobile={props.isMobile}
                      key={`${field.label}-${props.position}}`}
                    />
                  );
                }
                return (
                  <LabelAndValueDefault
                    label={field.label}
                    value={String(parsedValue)}
                    type={field.type}
                    isMobile={props.isMobile}
                    key={`${field.label}-${props.position}}`}
                  />
                );
              }

              if (field.type === FormFieldType.CONDITIONAL_SUBFORM) {
                return (
                  <LabelAndValueConditional
                    key={`${field.label}-${props.position}}`}
                    label={field.label}
                    type={field.type}
                    value={field.value[0] as string}
                    fields={field.data?.fields}
                    isMobile={props.isMobile}
                  />
                );
              }

              if (field.type === FormFieldType.CPF) {
                if(cpf.isValid(field.value[props.position] as string)) {
                  return (
                    <LabelAndValueConditional
                      key={`${field.label}-${props.position}}`}
                      label={field.value?.[0] as string}
                      type={field.type}
                      value={field.value[0] as string}
                      fields={field.data?.fields}
                      isMobile={props.isMobile}
                    />
                  );
                } else {
                  return (
                    <LabelAndValueConditional
                      key={`${field.label}-${props.position}}`}
                      label="RNE da pessoa dependente"
                      type={field.type}
                      value={field.value[0] as string}
                      fields={field.data?.fields}
                      isMobile={props.isMobile}
                    />
                  );
                }
              }

              return (
                <LabelAndValueDefault
                  label={field.label}
                  value={field.value[props.position] as string}
                  type={field.type}
                  isMobile={props.isMobile}
                  key={`${field.label}-${props.position}}`}
                />
              );
            })}
        </>
      )}
    </>
  );
}
