var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Tokens } from "@caju-beneficios/sponsor-v2-lib/dist/configs/tokens/tokens";
import styled from "styled-components";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 50% 50%;\n  height: calc(100vh - 180px);\n  overflow-y: scroll;\n"], ["\n  display: grid;\n  grid-template-columns: 50% 50%;\n  height: calc(100vh - 180px);\n  overflow-y: scroll;\n"])));
export var ReviewAndNotify = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-right: 1px solid ", ";\n  height: 100%;\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"], ["\n  border-right: 1px solid ", ";\n  height: 100%;\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"])), Tokens.neutralColor.medium[700]);
export var RequestDetails = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  background-color: ", ";\n\n  h4 {\n    font-size: 16px;\n    font-weight: 600;\n  }\n"], ["\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  background-color: ", ";\n\n  h4 {\n    font-size: 16px;\n    font-weight: 600;\n  }\n"])), Tokens.neutralColor.light[900]);
export var CardNotify = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  padding: 16px;\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"], ["\n  border: 1px solid ", ";\n  padding: 16px;\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"])), Tokens.neutralColor.medium[700]);
export var SwitchLabel = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  p {\n    font-size: 14px;\n    font-weight: 500;\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  p {\n    font-size: 14px;\n    font-weight: 500;\n    color: ", ";\n  }\n"])), Tokens.neutralColor.dark[300]);
export var TextFieldLabel = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 14px;\n  font-weight: 400;\n  color: ", ";\n"], ["\n  font-size: 14px;\n  font-weight: 400;\n  color: ", ";\n"])), Tokens.neutralColor.medium[400]);
export var RequestDays = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  align-items: flex-start;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  align-items: flex-start;\n"])));
export var StartAndEndDate = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  align-items: flex-start;\n  span {\n    display: flex;\n    gap: 4px;\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  align-items: flex-start;\n  span {\n    display: flex;\n    gap: 4px;\n    align-items: center;\n  }\n"])));
export var AllowanceDays = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n\n  h6 {\n    color: ", ";\n    font-size: 14px;\n    font-weight: 400;\n  }\n  p {\n    margin-top: 8px;\n    display: flex;\n    align-items: center;\n    gap: 16px;\n    font-size: 14px;\n    font-weight: 500;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n\n  h6 {\n    color: ", ";\n    font-size: 14px;\n    font-weight: 400;\n  }\n  p {\n    margin-top: 8px;\n    display: flex;\n    align-items: center;\n    gap: 16px;\n    font-size: 14px;\n    font-weight: 500;\n  }\n"])), Tokens.neutralColor.medium[400]);
export var ThirteenSalary = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  p {\n    font-size: 14px;\n    font-weight: 500;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  p {\n    font-size: 14px;\n    font-weight: 500;\n  }\n"])));
export var CommentContainer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n\n  h6 {\n    color: ", ";\n    font-size: 14px;\n    font-weight: 400;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n\n  h6 {\n    color: ", ";\n    font-size: 14px;\n    font-weight: 400;\n  }\n"])), Tokens.neutralColor.medium[400]);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
