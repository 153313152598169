import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { PiWarning } from "react-icons/pi";
import { DeadlineStatus } from "../../../../../../../services/vacations/types";
export var getChipsTexts = function (_a) {
    var _b;
    var status = _a.status, variant = _a.variant;
    var config = (_b = {},
        _b[DeadlineStatus.HIGH] = {
            icon: undefined,
            title: variant === "REGULAR" ? "Em até 30 dias" : "!!!",
            toolTipTitle: "Menos de 30 dias para data de início",
            txtColor: undefined,
            bgColor: Tokens.feedbackColor.error.light,
        },
        _b[DeadlineStatus.OVERDUE] = {
            icon: PiWarning,
            title: variant === "REGULAR" ? "Limite ultrapassado" : "!!!",
            toolTipTitle: "A data limite de início foi ultrrapassada",
            txtColor: undefined,
            bgColor: Tokens.feedbackColor.error.light,
        },
        _b[DeadlineStatus.MEDIUM] = {
            icon: undefined,
            title: variant === "REGULAR" ? "Em até 60 dias" : "!!",
            toolTipTitle: "Menos de 60 dias para data de início",
            txtColor: undefined,
            bgColor: Tokens.feedbackColor.warning.light,
        },
        _b[DeadlineStatus.LOW] = {
            icon: undefined,
            title: variant === "REGULAR" ? "Em até 90 dias" : "!",
            toolTipTitle: "Menos de 90 dias para data de início",
            txtColor: undefined,
            bgColor: Tokens.categoryColor.transportation.light,
        },
        _b[DeadlineStatus.NONE] = {
            icon: undefined,
            title: "Mais de 90 dias",
            toolTipTitle: "Mais de 90 dias para data de início",
            bgColor: Tokens.interfaceColor.primary.light,
            txtColor: Tokens.interfaceColor.primary.dark,
        },
        _b[DeadlineStatus.ZERO] = {
            icon: undefined,
            title: "Saldo utilizado",
            toolTipTitle: "O saldo de dias já foi utilizado",
            txtColor: undefined,
            bgColor: undefined,
        },
        _b[DeadlineStatus.NO_PERIOD] = {
            icon: undefined,
            title: "Sem período aquisitivo",
            toolTipTitle: "O colaborador ainda não possui saldo de dias para solicitar férias",
            txtColor: undefined,
            bgColor: undefined,
        },
        _b);
    return config[status];
};
