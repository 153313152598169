var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import React, { createContext, forwardRef, useContext } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import useActionButtons from "./useActionButtons";
var Context = createContext({
    closeMenu: function () { },
});
function ActionButton(_a) {
    var onClick = _a.onClick, customActionButtom = _a.customActionButtom;
    return (_jsx("div", { children: customActionButtom ? (_jsx("div", __assign({ onClick: onClick }, { children: customActionButtom }))) : (_jsx(IconButton, __assign({ "aria-label": "a\u00E7\u00F5es", onClick: onClick, color: "primary", "data-testid": "action-button" }, { children: _jsx(HiOutlineDotsVertical, { size: 22 }) }))) }));
}
export default function ActionsButton(_a) {
    var children = _a.children, customActionButtom = _a.customActionButtom, _b = _a.customStyleMenu, customStyleMenu = _b === void 0 ? {} : _b;
    var _c = useActionButtons(), closeMenu = _c.closeMenu, menuPropsBind = _c.menuPropsBind, togglerPropsBind = _c.togglerPropsBind;
    var values = {
        closeMenu: closeMenu,
    };
    return (_jsxs(Context.Provider, __assign({ value: values }, { children: [_jsx(ActionButton, __assign({}, togglerPropsBind, { customActionButtom: customActionButtom, "data-testid": "action-button" })), _jsx(Menu, __assign({ style: __assign({ marginTop: "35px" }, customStyleMenu) }, menuPropsBind, { children: children }))] })));
}
export var Option = forwardRef(function (_a, ref) {
    var onClick = _a.onClick, children = _a.children, disabled = _a.disabled;
    var closeMenu = useContext(Context).closeMenu;
    function handleClick() {
        closeMenu();
        onClick();
    }
    return (_jsx(MenuItem, __assign({ onClick: handleClick, ref: ref, disabled: disabled }, { children: children })));
});
