var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { Accordion, AccordionDetails, AccordionSummary, makeStyles, } from "@material-ui/core";
import styled from "styled-components";
var drawerWidth = 1000;
export var WrapperDrawerHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .adm-drawer-header {\n    background-color: ", ";\n    padding: 32px 40px 20px;\n  }\n  .close-btn {\n    width: 24px;\n    height: 24px;\n  }\n  h2 {\n    margin: 24px 0 0 0;\n  }\n"], ["\n  .adm-drawer-header {\n    background-color: ", ";\n    padding: 32px 40px 20px;\n  }\n  .close-btn {\n    width: 24px;\n    height: 24px;\n  }\n  h2 {\n    margin: 24px 0 0 0;\n  }\n"])), Tokens.neutralColor.light[1000]);
export var CustomAccordion = styled(Accordion)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  padding: 0;\n  background-color: ", ";\n"], ["\n  width: 100%;\n  padding: 0;\n  background-color: ", ";\n"])), Tokens.neutralColor.light[1000]);
export var CustomAccordionTitle = styled(AccordionSummary)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0;\n  color: ", ";\n  background: transparent;\n  width: 100%;\n  background-color: ", ";\n"], ["\n  padding: 0;\n  color: ", ";\n  background: transparent;\n  width: 100%;\n  background-color: ", ";\n"])), Tokens.interfaceColor.primary.pure, Tokens.neutralColor.light[1000]);
export var CustomAccordionDetails = styled(AccordionDetails)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 0;\n  background-color: ", ";\n"], ["\n  padding: 0;\n  background-color: ", ";\n"])), Tokens.neutralColor.light[1000]);
export var useStyles = makeStyles(function (theme) { return ({
    root: {
        display: "flex",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 950,
    },
    drawerContainer: {
        height: "calc(100vh - 120px)",
        minWidth: "300px",
        overflow: "auto",
    },
    gridContainer: {
        padding: "24px"
    },
    employeeContainer: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
        padding: "24px",
    },
    employeeAvatar: { height: "40px", width: "40px" },
    employeName: {
        fontWeight: 600,
        color: Tokens.neutralColor.dark[100],
        fontSize: "16px",
    },
    employeePosition: {
        fontWeight: 500,
        color: Tokens.neutralColor.medium[400],
        fontSize: "14px",
    },
    resumeGrid: {
        display: "flex",
        gap: "16px",
        flexDirection: "column",
    },
    resumeItem: {
        color: Tokens.neutralColor.medium[400],
        fontWeight: 500,
    },
    chip: {
        width: "fit-content",
        height: "24px",
        fontWeight: 500
    },
    typography: {
        fontWeight: 500
    },
    allowanceDaysContainer: {
        gap: "16px"
    },
    allowanceDaysTooltip: {
        display: "flex",
        alignItems: "center",
    },
    content: {
        backgroundColor: Tokens.neutralColor.light[900],
        height: "100vh",
        overflowX: "scroll",
        paddingBottom: 64,
    },
    container: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflowY: "hidden",
    },
    drawerBody: {
        display: "flex",
        height: "100vh",
    },
    header: {
        padding: "0 20px",
        borderBottom: "1px solid ".concat(Tokens.neutralColor.medium[700]),
        background: Tokens.neutralColor.light[1000],
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        minHeight: "122px",
        width: "100%",
        height: "96px",
    },
    headerActions: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    actionsContainer: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },
    button: {
        color: Tokens.interfaceColor.primary.pure,
        minWidth: 42,
        height: 42,
        "&:hover": {
            backgroundColor: Tokens.interfaceColor.primary.light,
        },
    },
    cancelButton: {
        color: Tokens.feedbackColor.error.pure,
        minWidth: 42,
        height: 42,
        "&:hover": {
            color: Tokens.feedbackColor.error.dark,
            backgroundColor: Tokens.feedbackColor.error.light,
        },
    },
    buttonClose: {
        color: Tokens.neutralColor.medium[400],
        minWidth: 0,
        "&:hover": {
            backgroundColor: Tokens.neutralColor.light[1000],
        },
    },
    rootAccordion: {
        borderBottom: "unset",
    },
    accordionContainer: {
        padding: "24px"
    },
}); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
