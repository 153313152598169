var _a;
import { VacationFollowUpStatus } from "../../../services/vacations/followUp/types";
import { BiCheck, BiX } from "react-icons/bi";
import { FaCalendarCheck } from "react-icons/fa";
import { PiSunHorizonBold } from "react-icons/pi";
import { LuClock } from "react-icons/lu";
import { MdAccessAlarm } from "react-icons/md";
export var statusArr = [
    VacationFollowUpStatus.AWAITING_APPROVAL,
    VacationFollowUpStatus.APPROVED_SCHEDULED,
    VacationFollowUpStatus.APPROVED_IN_VACATION,
];
export var statusLabel = (_a = {},
    _a[VacationFollowUpStatus.AWAITING_APPROVAL] = {
        label: "Aguardando aprovação",
        icon: LuClock,
    },
    _a[VacationFollowUpStatus.APPROVED_SCHEDULED] = {
        label: "Férias agendadas",
        icon: FaCalendarCheck,
    },
    _a[VacationFollowUpStatus.APPROVED_IN_VACATION] = {
        label: "Em férias",
        icon: PiSunHorizonBold,
    },
    _a[VacationFollowUpStatus.PAST_APPROVED] = { label: "Concluída", icon: BiCheck },
    _a[VacationFollowUpStatus.DENIED] = { label: "Reprovada", icon: BiX },
    _a[VacationFollowUpStatus.PENDING_REVIEW] = {
        label: "Validação pendente",
        icon: MdAccessAlarm,
    },
    _a);
