var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorOutline } from "@material-ui/icons";
import { Grid, IconButton, Tooltip, Chip, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { useStyles } from "./styles";
export function CardSummaryRoot(_a) {
    var children = _a.children;
    var classes = useStyles();
    return (_jsx(Grid, __assign({ container: true, className: classes.rootCard }, { children: children })));
}
CardSummaryRoot.displayName = "CardSummary.Root";
export function CardSummaryIcon(_a) {
    var backgroundColor = _a.backgroundColor, color = _a.color, icon = _a.icon, label = _a.label;
    return (_jsx(Chip, { style: {
            height: "28px",
            backgroundColor: backgroundColor,
        }, label: _jsxs("div", __assign({ style: {
                display: "flex",
                alignItems: "center",
                gap: 4,
            } }, { children: [icon, label ? (_jsx(Typography, __assign({ style: {
                        fontSize: "14px",
                        fontWeight: 500,
                        color: color,
                    } }, { children: label }))) : null] })) }));
}
CardSummaryIcon.displayName = "CardSummary.Icon";
function CardSummaryItem(_a) {
    var children = _a.children, hasBorder = _a.hasBorder, isLoading = _a.isLoading, message = _a.message, quantity = _a.quantity, title = _a.title;
    var classes = useStyles();
    return (_jsxs(Grid, __assign({ item: true, xs: true, "data-testid": "root-card", className: "".concat(classes.item, " ").concat(hasBorder ? classes.border : '') }, { children: [_jsxs(Typography, __assign({ className: classes.title }, { children: [title, _jsx(Tooltip, __assign({ title: message, placement: "right", arrow: true }, { children: _jsx(IconButton, __assign({ size: "medium", className: classes.iconButton }, { children: _jsx(ErrorOutline, { style: {
                                    color: Tokens.neutralColor.medium[400],
                                    fontSize: "16.67px",
                                } }) })) }))] })), _jsxs(Typography, __assign({ style: { fontSize: "16px", fontWeight: 500, display: "flex", alignItems: "center" } }, { children: [_jsx("span", __assign({ style: { marginRight: "8px" } }, { children: children })), isLoading ? (_jsx("div", __assign({ role: "progressbar" }, { children: _jsx(Skeleton, { variant: "rect", width: 34, height: 23, style: { borderRadius: "4px" } }) }))) : (_jsx("span", { children: quantity }))] }))] })));
}
CardSummaryItem.displayName = "CardSummary.Item";
/**
 * @example
 * <CardSummary.Root>
 *  <CardSummary.Item
 *   hasBorder
 *   isLoading={false}
 *   message="Lorem ipsum ultrices proin, rutrum."
 *   quantity={0} title="Lorem ipsum ultrices proin, rutrum.">
 *    <CardSummary.Icon
 *      backgroundColor={Tokens.neutralColor.light[1000]}
 *      color={Tokens.neutralColor.dark[100]}
 *      icon={<CalendarError />}  />
 *  </CardSummary.Item>
 * </CardSummary.Root>
 *
 * ---
 * @name CardSummary.Root
 * @param children ReactNode
 * ---
 * @name CardSummary.Item
 * @param children ReactNode
 * @param hasBorder boolean
 * @param isLoading boolean
 * @param message string
 * @param quantity ReactNode
 * @param title string
 * ---
 * @name CardSummary.Icon
 * @params backgroundColor string
 * @params color string
 * @params icon ReactNode
 * @params label?: string
 */
export var CardSummary = {
    Root: CardSummaryRoot,
    Item: CardSummaryItem,
    Icon: CardSummaryIcon,
};
