import { DefaultPrivacyLevel, datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

const datadogConfig = {
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID ?? "",
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? "",
  site: "datadoghq.com",
  service: "admittance",
  env: "production",
  version: "1.0.0",
  sessionSampleRate: 60,
  sessionReplaySampleRate: 30,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input" as DefaultPrivacyLevel,
};

export const initMonitoring = () => {
  datadogRum.init(datadogConfig);
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? "",
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    service: "admittance",
    env: "production",
    sessionSampleRate: 100,
  });
  datadogRum.startSessionReplayRecording();
};
