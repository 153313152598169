var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { DrawerContent, DrawerFooter, } from "@caju-beneficios/react-web-commons";
import { Drawer, makeStyles, Typography, Checkbox, Tooltip, } from "@material-ui/core";
import * as S from "./styles";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import Button from "@material-ui/core/Button";
import { IoClose } from "react-icons/io5";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import OrderIcon from "./icons/OrderIcon";
function initColumns() {
    return [
        { name: "Nome", selected: true, order: 0 },
        { name: "Cargo", selected: true, order: 1 },
        { name: "Departamento", selected: true, order: 2 },
        { name: "E-mail corporativo", selected: true, order: 3 },
        { name: "Pessoa Gestora", selected: true, order: 4 },
        { name: "CPF", selected: false, order: 5 },
        { name: "Centro de custo", selected: false, order: 6 },
        { name: "Data de admissão", selected: false, order: 7 },
        { name: "Salário", selected: false, order: 8 },
        { name: "Última movimentação", selected: false, order: 9 },
        { name: "Data de nascimento", selected: false, order: 10 },
    ];
}
var REQUIRED_COLUMNS = ["Nome"];
var useStyles = makeStyles({
    table: {
        borderRadius: "inherit",
        "& .MuiTableCell-root": {
            border: "1px solid ".concat(Tokens.neutralColor.light[800]),
        },
    },
    header: {
        padding: "0 20px",
        borderBottom: "1px solid ".concat(Tokens.neutralColor.medium[700]),
        background: Tokens.neutralColor.light[1000],
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        minHeight: "122px",
        width: "100%",
        height: "96px",
    },
    headerActions: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    actionsContainer: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },
    buttonClose: {
        color: Tokens.neutralColor.medium[400],
        minWidth: 0,
        "&:hover": {
            backgroundColor: Tokens.neutralColor.light[1000],
        },
    },
    contentAlert: {
        fontSize: 14,
        color: Tokens.interfaceColor.primary.dark,
        fontWeight: 400,
    },
});
export function SelectColumnsDrawer(props) {
    var _a = __read(useState([]), 2), columns = _a[0], setColumns = _a[1];
    var classes = useStyles();
    var getItemStyle = function (isDragging, draggableStyle) { return (__assign({ borderRadius: "16px", boxShadow: isDragging
            ? "12px 12px 9px 1px ".concat(Tokens.neutralColor.medium[700])
            : "none", pointerEvents: "auto" }, draggableStyle)); };
    var handleSelect = function (index) {
        setColumns(columns.map(function (column, i) {
            if (i === index) {
                return __assign(__assign({}, column), { selected: !column.selected });
            }
            return column;
        }));
    };
    var handleOnDragEnd = function (result) {
        if (!result.destination)
            return;
        if (!columns || !columns.length)
            return;
        var items = columns;
        var _a = __read(items.splice(result.source.index, 1), 1), reorderedItem = _a[0];
        items.splice(result.destination.index, 0, reorderedItem);
        setColumns(items.map(function (item, index) {
            return __assign(__assign({}, item), { order: index });
        }));
    };
    var handleSaveColumns = function () {
        props.onChangeColumns(columns);
        localStorage.setItem("selectColumnEmployees", JSON.stringify(columns));
        props.onClose();
    };
    var handleReset = useCallback(function () {
        props.onChangeColumns(initColumns());
        setColumns(initColumns());
        localStorage.setItem("selectColumnEmployees", JSON.stringify(initColumns()));
        props.onClose();
    }, [props]);
    useEffect(function () {
        var savedColumns = localStorage.getItem("selectColumnEmployees");
        var selectColumnEmployees = savedColumns
            ? JSON.parse(savedColumns)
            : initColumns();
        setColumns(selectColumnEmployees);
        props.onChangeColumns(selectColumnEmployees);
    }, []);
    return (_jsx(_Fragment, { children: _jsxs(Drawer, __assign({ open: props.isOpen, anchor: "right", PaperProps: {
                style: { width: 500 },
            }, onClose: props.onClose }, { children: [_jsx("header", __assign({ className: classes.header }, { children: _jsxs("div", __assign({ className: classes.headerActions }, { children: [_jsx(Typography, __assign({ style: { fontSize: "24px", fontWeight: 700 } }, { children: "Selecionar colunas" })), _jsx("div", __assign({ className: classes.actionsContainer }, { children: _jsx(Button, __assign({ onClick: function () { return props.onClose(); }, classes: {
                                        root: classes.buttonClose,
                                    } }, { children: _jsx(IoClose, { size: 24 }) })) }))] })) })), _jsx(DrawerContent, { children: _jsx("div", __assign({ style: { display: "flex" } }, { children: _jsx(DragDropContext, __assign({ onDragEnd: handleOnDragEnd }, { children: _jsx(Droppable, __assign({ droppableId: "customFields" }, { children: function (provided) { return (_jsxs("ul", __assign({ style: { listStyleType: "none", width: "100%" } }, provided.droppableProps, { ref: provided.innerRef }, { children: [columns === null || columns === void 0 ? void 0 : columns.map(function (column, index) {
                                            return (_jsx(Draggable, __assign({ draggableId: column.name, index: index }, { children: function (provided, snapshot) { return (_jsx(S.Item, __assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { style: getItemStyle(snapshot.isDragging, provided.draggableProps.style) }, { children: _jsxs("div", __assign({ style: {
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                        } }, { children: [_jsxs("div", { children: [_jsx(Checkbox, { checked: column.selected, color: "primary", onClick: function () { return handleSelect(index); }, disabled: REQUIRED_COLUMNS.includes(column.name) }), column.name] }), _jsx(Tooltip, __assign({ title: "Segure e arraste para reordenar", placement: "top", arrow: true }, { children: _jsx(S.IconContainer, __assign({ "data-testid": "order-icon" }, { children: _jsx(OrderIcon, {}) })) }))] })) }))); } }), column.name));
                                        }), provided.placeholder] }))); } })) })) })) }), _jsxs(DrawerFooter, { children: [_jsx(Button, __assign({ variant: "outlined", color: "primary", onClick: function () { return props.onClose(); } }, { children: "Cancelar" })), _jsxs("div", { children: [_jsx(Button, __assign({ variant: "text", color: "primary", style: { marginRight: 20 }, onClick: handleReset }, { children: "Redefinir" })), _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: handleSaveColumns }, { children: "Aplicar" }))] })] })] })) }));
}
