import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { makeStyles } from "@material-ui/core/styles";
export var useStyles = makeStyles({
    rootLink: {
        fontSize: 12,
        cursor: "pointer",
        '&:hover': {
            color: Tokens.neutralColor.dark[200],
            textDecoration: 'none',
            fontWeight: Tokens.fontWeight.medium
        }
    },
    rootBreadcrumb: {
        marginBottom: "16px",
    },
});
