var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useMemo, useState } from "react";
export var usePeriod = function () {
    var _a = __read(useState(null), 2), startDate = _a[0], setStartDate = _a[1];
    var _b = __read(useState(null), 2), endDate = _b[0], setEndDate = _b[1];
    var _c = __read(useState(0), 2), selectedDays = _c[0], setSelectedDays = _c[1];
    var setPeriod = useCallback(function (params) {
        setStartDate(params.startDate);
        setEndDate(params.endDate);
    }, []);
    var handleSelectedDays = function (days) { return setSelectedDays(days); };
    var period = useMemo(function () {
        return {
            startDate: startDate,
            endDate: endDate,
        };
    }, [endDate, startDate]);
    return { period: period, setPeriod: setPeriod, handleSelectedDays: handleSelectedDays, selectedDays: selectedDays };
};
