var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import styled from "styled-components";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 70% 30%;\n  grid-template-rows: 100vh;\n"], ["\n  display: grid;\n  grid-template-columns: 70% 30%;\n  grid-template-rows: 100vh;\n"])));
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding: 24px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding: 24px;\n"])));
export var SideBar = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 24px;\n  background-color: ", ";\n  border-left: 1px solid ", ";\n"], ["\n  padding: 24px;\n  background-color: ", ";\n  border-left: 1px solid ", ";\n"])), Tokens.neutralColor.light[900], Tokens.neutralColor.medium[700]);
export var Period = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 14px;\n  font-weight: 500;\n"], ["\n  color: ", ";\n  font-size: 14px;\n  font-weight: 500;\n"])), function (props) {
    return props.hasBalance
        ? Tokens.neutralColor.dark["200"]
        : Tokens.neutralColor.medium[500];
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
