import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { makeStyles } from "@material-ui/core";
export var useStyles = makeStyles({
    title: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: Tokens.lineHight.xl,
        color: Tokens.neutralColor.dark[100],
    },
    errorTitle: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: Tokens.lineHight.xl,
        color: Tokens.feedbackColor.error.medium,
    },
    description: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: Tokens.lineHight.xl,
        color: Tokens.neutralColor.dark[100],
    },
    BoxContent: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        width: 375,
        margin: "24px 0",
    },
    optionsMenu: {
        display: "flex",
        flexDirection: "column",
    },
    optionsNameMenu: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: Tokens.lineHight.lg,
        color: Tokens.neutralColor.dark[200],
    },
    optionsEmailMenu: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: Tokens.lineHight.xl,
        color: Tokens.neutralColor.medium[400],
    },
    inputProps: {
        fontSize: 16,
        fontWeight: 400,
        color: Tokens.neutralColor.medium[400],
    },
});
