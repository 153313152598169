var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useImperativeHandle, useState } from "react";
import { Details } from "./components/Details";
import { Settings } from "./components/Settings";
import * as S from "./styles";
import { usePeriod } from "./hooks/usePeriod";
import { useAllowanceOption } from "./hooks/useAllowanceOption";
import { useThirteenthSalaryAdvance } from "./hooks/useThirteenthSalaryAdvance";
import useNotification from "../../../../../hooks/useNotification";
import { useEmployeeVacationSummaryCtx } from "../../contexts/EmployeeVacationSummary";
export var RequestInfo = React.forwardRef(function (props, ref) {
    var _a;
    var notification = useNotification();
    var _b = __read(useState(""), 2), comment = _b[0], setComment = _b[1];
    var _c = useEmployeeVacationSummaryCtx(), currentSummary = _c.summary, nextPeriodSummary = _c.nextPeriodSummary, resetEmployeeSummary = _c.resetEmployeeSummary, verifyHasEmployee = _c.verifyHasEmployee, isLoadingSummary = _c.isLoadingSummary, typeVacation = _c.typeVacation;
    var summary = typeVacation ? nextPeriodSummary : currentSummary;
    var _d = usePeriod(), period = _d.period, setPeriod = _d.setPeriod, handleSelectedDays = _d.handleSelectedDays, selectedDays = _d.selectedDays;
    var _e = useAllowanceOption({
        period: period,
        canTakeAllowance: summary === null || summary === void 0 ? void 0 : summary.canTakeAllowance,
        remainingBalance: (_a = summary === null || summary === void 0 ? void 0 : summary.remainingBalance) !== null && _a !== void 0 ? _a : 0,
        overFourteenDays: summary === null || summary === void 0 ? void 0 : summary.overFourteenDays,
        allowPartialVacationSell: summary === null || summary === void 0 ? void 0 : summary.configurations.allowPartialVacationSell,
    }), shouldEnableAllowanceOption = _e.shouldEnableAllowanceOption, disableReason = _e.disableReason, allowanceDays = _e.allowanceDays, handleAllowanceDays = _e.handleAllowanceDays, resetReason = _e.resetReason, daysToSell = _e.daysToSell;
    var _f = useThirteenthSalaryAdvance(), handleThirteenthSalaryAdvance = _f.handleThirteenthSalaryAdvance, thirteenthSalaryAdvance = _f.thirteenthSalaryAdvance;
    var resetEmployeeIdAndSummary = useCallback(function () {
        handleSelectedDays(0);
        handleAllowanceDays("Não");
        setPeriod({ startDate: null, endDate: null });
        resetReason();
        resetEmployeeSummary();
    }, [
        resetReason,
        handleAllowanceDays,
        handleSelectedDays,
        resetEmployeeSummary,
        setPeriod,
    ]);
    useImperativeHandle(ref, function () { return ({
        submitForm: function () {
            if (!summary) {
                return [false, null];
            }
            var hasEmployee = verifyHasEmployee(function () { });
            if (!hasEmployee) {
                notification.error("Selecione colaborador");
                return [false, null];
            }
            if (!period.startDate || !period.endDate) {
                notification.error("Selecione um periodo completo");
                return [false, null];
            }
            var values = {
                allowanceDays: allowanceDays ? daysToSell : 0,
                endDate: period.endDate,
                startDate: period.startDate,
                thirteenthSalaryAdvance: thirteenthSalaryAdvance !== null && thirteenthSalaryAdvance !== void 0 ? thirteenthSalaryAdvance : false,
                employee: {
                    admittanceDate: summary === null || summary === void 0 ? void 0 : summary.employee.admittanceDate,
                    deadlineStatus: summary === null || summary === void 0 ? void 0 : summary.deadlineStatus,
                    name: summary === null || summary === void 0 ? void 0 : summary.employee.name,
                    position: summary === null || summary === void 0 ? void 0 : summary.employee.position,
                    profilePicture: summary === null || summary === void 0 ? void 0 : summary.employee.profilePictureUrl,
                    id: summary.employee.id,
                },
                selectedDays: selectedDays,
                completedAcquisitionPeriod: summary.completedAcquisitionPeriod,
                comment: comment,
                daysToSell: daysToSell,
            };
            return [true, values];
        },
    }); }, [
        thirteenthSalaryAdvance,
        allowanceDays,
        notification,
        period.endDate,
        period.startDate,
        selectedDays,
        summary,
        verifyHasEmployee,
        comment,
        daysToSell,
    ]);
    return (_jsxs(S.Container, { children: [_jsx("div", { children: _jsx(Settings, { summary: summary, isHistory: props.isHistory, requester: props.requester, isLoadingSummary: isLoadingSummary, onChangeThirteenSalary: function (value) {
                        return verifyHasEmployee(function () {
                            handleThirteenthSalaryAdvance(value);
                        });
                    }, thirteenthSalaryAdvance: thirteenthSalaryAdvance, disabledReasonAllowance: disableReason, shouldEnableAllowanceOption: !shouldEnableAllowanceOption, allowanceDays: allowanceDays, period: period, onChangePeriod: function (startDate, endDate, selectedDays) {
                        verifyHasEmployee(function () {
                            setPeriod({ startDate: startDate, endDate: endDate });
                            if (startDate && endDate) {
                                handleSelectedDays(selectedDays);
                            }
                            else {
                                handleSelectedDays(0);
                            }
                        });
                    }, onChangeAllowance: function (value) {
                        verifyHasEmployee(function () {
                            handleAllowanceDays(value);
                        });
                    }, showResetButton: props.showResetButton, onBlurComment: function (e) { return setComment(e.target.value); }, daysToSell: daysToSell, scheduling: props.scheduling, onClickReset: resetEmployeeIdAndSummary, typeVacation: typeVacation }) }), _jsx(Details, { summary: summary, selectedDays: selectedDays, allowanceDays: allowanceDays ? daysToSell : 0, typeVacation: typeVacation })] }));
});
