var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from "react";
import { Avatar } from "@material-ui/core";
import { IoIosArrowDown } from "react-icons/io";
import { BiLinkExternal } from "react-icons/bi";
import { PiPencilSimpleLine } from "react-icons/pi";
export var CustomNode = memo(function (_a) {
    var _b, _c, _d;
    var nodeData = _a.nodeData, onEditEmployee = _a.onEditEmployee, showActionButtons = _a.showActionButtons, handleClickLink = _a.handleClickLink, showName = _a.showName, hidePosition = _a.hidePosition;
    var handleClick = function (e) {
        var _a, _b;
        e.preventDefault();
        e.stopPropagation();
        var childNodes = (_b = (_a = document.getElementById(nodeData.id)) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.childNodes;
        if (childNodes && childNodes[1].className.includes("hidden")) {
            childNodes[0].className = "oc-node";
            childNodes[1].className = "";
        }
        else if (childNodes) {
            childNodes[0].className = "oc-node isChildrenCollapsed";
            childNodes[1].className = "hidden";
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "card org-node-container" }, { children: [_jsxs("div", __assign({ className: "card-header" }, { children: [nodeData.profilePictureUrl ? (_jsx("img", { className: "profile-picture", src: nodeData.profilePictureUrl, alt: "Foto de perfil" })) : (_jsx(Avatar, __assign({ alt: nodeData.name, style: {
                                    width: 40,
                                    height: 40,
                                    marginRight: 12,
                                    borderRadius: "10%",
                                } }, { children: (_b = nodeData.name.substring(0, 2)) !== null && _b !== void 0 ? _b : "" }))), _jsx("div", __assign({ className: "card-actions", style: {
                                    display: "flex",
                                    width: "60px",
                                    justifyContent: "space-around",
                                } }, { children: !nodeData.isRoot && showActionButtons && (_jsxs(_Fragment, { children: [_jsx(BiLinkExternal, { color: "#0e328b", style: { cursor: "pointer" }, size: 18, onClick: function () { return handleClickLink && handleClickLink(nodeData); } }), _jsx(PiPencilSimpleLine, { color: "#0e328b", size: 18, style: { cursor: "pointer" }, onClick: function () { return onEditEmployee && onEditEmployee(nodeData); } })] })) }))] })), _jsxs("div", __assign({ className: "card-info" }, { children: [_jsx("span", __assign({ className: "name" }, { children: showName(nodeData) })), !hidePosition && _jsx("span", __assign({ className: "role" }, { children: nodeData.position })), ((_c = nodeData.departmentData) === null || _c === void 0 ? void 0 : _c.name) && (_jsx("div", __assign({ className: "card-tag" }, { children: _jsx("span", { children: (_d = nodeData.departmentData) === null || _d === void 0 ? void 0 : _d.name }) })))] }))] })), nodeData.children.length > 0 && (_jsx("div", __assign({ className: "notification card-dropdown", id: "".concat(nodeData.id, "-child-button"), onClick: handleClick }, { children: _jsxs("button", __assign({ className: "dropdown-button" }, { children: [_jsx(IoIosArrowDown, { color: "#0e328b", style: { verticalAlign: "middle", marginRight: "4px" } }), _jsx("span", __assign({ style: { color: "#0e328b", backgroundColor: "#e4eafd" } }, { children: nodeData.children.length }))] })) })))] }));
});
