export var FEATURES_TYPES;
(function (FEATURES_TYPES) {
    FEATURES_TYPES["CICLOS"] = "CICLOS";
    FEATURES_TYPES["ADMISSION"] = "ADMISSION";
    FEATURES_TYPES["DIRECTORY"] = "DIRECTORY";
    FEATURES_TYPES["FILE"] = "FILE";
    FEATURES_TYPES["SIGNATURE"] = "SIGNATURE";
    FEATURES_TYPES["CLOSURE"] = "CLOSURE";
    FEATURES_TYPES["RECEIPTS"] = "RECEIPTS";
    FEATURES_TYPES["REPORTS"] = "REPORTS";
    FEATURES_TYPES["VACATIONS"] = "VACATIONS";
    FEATURES_TYPES["CONFIGURATION"] = "CONFIGURATION";
    FEATURES_TYPES["PARAMETERIZATIONS"] = "PARAMETERIZATIONS";
    FEATURES_TYPES["CUSTOM_FIELDS"] = "CUSTOM_FIELDS";
    FEATURES_TYPES["VACATION_SETUP"] = "VACATION_SETUP";
    FEATURES_TYPES["UPCOMING_VACATION_PANEL"] = "UPCOMING_VACATION_PANEL";
    FEATURES_TYPES["REQUEST_VACATION_TRACKING"] = "REQUEST_VACATION_TRACKING";
    FEATURES_TYPES["REQUEST_VACATION_BY_HR"] = "REQUEST_VACATION_BY_HR";
    FEATURES_TYPES["GENERAL_VACATION_HISTORY"] = "GENERAL_VACATION_HISTORY";
    FEATURES_TYPES["EMPLOYEE_VACATION_HISTORY"] = "EMPLOYEE_VACATION_HISTORY";
    FEATURES_TYPES["REQUEST_VACATION_BY_EMPLOYEE"] = "REQUEST_VACATION_BY_EMPLOYEE";
    FEATURES_TYPES["VACATION_DOCUMENTS_AND_SIGNATURE"] = "VACATION_DOCUMENTS_AND_SIGNATURE";
    FEATURES_TYPES["VACATION_CUSTOMIZATIONS"] = "VACATION_CUSTOMIZATIONS";
    FEATURES_TYPES["VACATION_APPROVAL_BY_MANAGER"] = "VACATION_APPROVAL_BY_MANAGER";
    FEATURES_TYPES["DISTRIBUTE_RECEIPTS"] = "DISTRIBUTE_RECEIPTS";
    FEATURES_TYPES["DISTRIBUTION_RECEIPTS_NOTIFICATION"] = "DISTRIBUTION_RECEIPTS_NOTIFICATION";
    FEATURES_TYPES["DISTRIBUTION_RECEIPT_SIGNATURE_REQUEST"] = "DISTRIBUTION_RECEIPT_SIGNATURE_REQUEST";
    FEATURES_TYPES["REQUEST_SIGNATURE"] = "REQUEST_SIGNATURE";
    FEATURES_TYPES["ADDITIONAL_SIGNATURES"] = "ADDITIONAL_SIGNATURES";
    FEATURES_TYPES["PHOTO_AUTHENTICATION"] = "PHOTO_AUTHENTICATION";
    FEATURES_TYPES["SIGNATURE_PANEL"] = "SIGNATURE_PANEL";
    FEATURES_TYPES["EMPLOYEE_FILES_PANEL"] = "EMPLOYEE_FILES_PANEL";
    FEATURES_TYPES["EMPLOYEE_PORTAL_FILES_PANEL"] = "EMPLOYEE_PORTAL_FILES_PANEL";
    FEATURES_TYPES["FOLDER_CUSTOMIZATIONS"] = "FOLDER_CUSTOMIZATIONS";
    FEATURES_TYPES["ADD_FILE"] = "ADD_FILE";
    FEATURES_TYPES["ADD_FOLDER"] = "ADD_FOLDER";
    FEATURES_TYPES["EMPLOYEE_PANEL"] = "EMPLOYEE_PANEL";
    FEATURES_TYPES["ARCHIVE_EMPLOYEE"] = "ARCHIVE_EMPLOYEE";
    FEATURES_TYPES["ORGANIZATIONAL_MOVEMENTS_HISTORY"] = "ORGANIZATIONAL_MOVEMENTS_HISTORY";
    FEATURES_TYPES["SIMPLE_EMPLOYEE_REGISTRATION"] = "SIMPLE_EMPLOYEE_REGISTRATION";
    FEATURES_TYPES["EMPLOYEE_IMPORT"] = "EMPLOYEE_IMPORT";
    FEATURES_TYPES["ADMISSION_PANEL"] = "ADMISSION_PANEL";
    FEATURES_TYPES["NEW_ADMISSION"] = "NEW_ADMISSION";
    FEATURES_TYPES["WELCOME_MESSAGE_CUSTOMIZATION"] = "WELCOME_MESSAGE_CUSTOMIZATION";
    FEATURES_TYPES["WELCOME_SCREEN_CUSTOMIZATION"] = "WELCOME_SCREEN_CUSTOMIZATION";
    FEATURES_TYPES["ADMISSION_FORM_CUSTOMIZATION"] = "ADMISSION_FORM_CUSTOMIZATION";
    FEATURES_TYPES["TASK_LIST_CUSTOMIZATION"] = "TASK_LIST_CUSTOMIZATION";
})(FEATURES_TYPES || (FEATURES_TYPES = {}));
