var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Avatar, CircularProgress } from "@material-ui/core";
import * as S from "./styles";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { PriorityTag } from "../../../PriorityTag";
import { BiEditAlt } from "react-icons/bi";
export var CardEmployeeSelected = function (props) {
    var _a;
    return (_jsx(S.Container, __assign({ showResetButton: props.showResetButton }, { children: props.isLoading ? (_jsx(CircularProgress, { style: { margin: "auto" } })) : (_jsxs(_Fragment, { children: [_jsx(S.Card, { children: _jsxs(S.Info, { children: [_jsx(Avatar, { src: (_a = props.profilePicture) !== null && _a !== void 0 ? _a : undefined }), _jsxs(S.EmployeeInfo, { children: [_jsxs(S.EmployeeName, { children: [_jsx("h3", { children: props.employeeName }), props.deadlineStatus && (_jsx(PriorityTag, { deadlineStatus: props.deadlineStatus, variant: "SMALL" }))] }), _jsx(S.EmployeePostion, { children: _jsx("span", { children: props.employeePostion }) }), props.showAdmittanceDate ? (_jsxs(S.AdmittanceDate, { children: [_jsx(MdOutlineCalendarMonth, { color: Tokens.neutralColor.dark[300], size: 20 }), _jsx("span", { children: props.admittanceDate })] })) : null] })] }) }), props.showResetButton ? (_jsx(BiEditAlt, { size: 20, onClick: function () {
                        if (props.onClickReset) {
                            props.onClickReset();
                        }
                    }, style: {
                        cursor: "pointer",
                        color: Tokens.interfaceColor.primary.pure,
                    } })) : null] })) })));
};
