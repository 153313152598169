import { format, parse } from "date-fns";
export function formatToBrazilianDate(dateString) {
    if (!dateString)
        return "";
    var parsedDate = parse(String(dateString), "yyyy-MM-dd", new Date());
    return format(parsedDate, "dd/MM/yyyy");
}
export var renderFormTitle = function (formId) {
    switch (formId) {
        case "dependents":
            return "Dependente";
        case "finances":
            return "Conta bancária";
        case "courses":
            return "Formação";
        case "emergencyContacts":
            return "Contato de emergência";
        default:
            return;
    }
};
