var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers";
import { useCallback, useMemo, useState } from "react";
import * as S from "./styles";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { CalendarDay, DateRangePicker } from "react-dates";
import { Tooltip } from "@material-ui/core";
import moment from "moment-timezone";
import { isNearHoliday } from "./usePickerRules";
import { atLeastFiveDays, exceedMinimumReaminingDays, exceedsAvailableDays, isOverLimit, 
// isOutsideConcessionPeriod,
isFutureDate, isLastTwoDaysOfWeek, isBeforeMinimumDays, isLastThreeDaysOfWeek, } from "./usePickerRules/helpers";
import { useStyles } from "./styles";
import useNotification from "../../hooks/useNotification";
import ptBrLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import "./locale";
import { checkHoliday } from "../../utils/checkHoliday";
import { useCalendarMonthsRules } from "./useCalendarMonthsRules";
import Holidays from "date-holidays";
import { usePickerRules } from "./usePickerRules";
var IGNORE_HOLIDAYS = ["12-24", "12-31"];
export function PeriodPicker(props) {
    var _a, _b, _c;
    var notification = useNotification();
    var _d = props.period, startDate = _d.startDate, endDate = _d.endDate;
    var classes = useStyles();
    var holidays = useMemo(function () {
        var _a, _b;
        var startDateYear = moment((_a = props.rules.concessionPeriod) === null || _a === void 0 ? void 0 : _a.startDate).year();
        var endDateYear = moment((_b = props.rules.concessionPeriod) === null || _b === void 0 ? void 0 : _b.endDate).year();
        var allHolidays = [];
        var startDateYearHolidays = new Holidays("BR").getHolidays(startDateYear);
        allHolidays.push.apply(allHolidays, __spreadArray([], __read(startDateYearHolidays), false));
        if (startDateYear !== endDateYear) {
            var startDateYearHolidays_1 = new Holidays("BR").getHolidays(endDateYear);
            allHolidays.push.apply(allHolidays, __spreadArray([], __read(startDateYearHolidays_1), false));
        }
        return allHolidays.filter(function (holiday) { return !IGNORE_HOLIDAYS.includes(holiday.rule.split(" ")[0]); });
    }, [
        (_a = props.rules.concessionPeriod) === null || _a === void 0 ? void 0 : _a.endDate,
        (_b = props.rules.concessionPeriod) === null || _b === void 0 ? void 0 : _b.startDate,
    ]);
    var _e = __read(useState("startDate"), 2), focusedInput = _e[0], setFocusedInput = _e[1];
    var _f = usePickerRules({
        startDate: props.period.startDate,
        endDate: props.period.endDate,
        availableDays: (_c = props.rules.remainingBalance) !== null && _c !== void 0 ? _c : 0,
        shouldRequestOverFourteenDays: props.rules.shouldRequestOverFourteenDays,
        overFourteenDays: props.rules.overFourteenDays,
        limitToStart: props.rules.limitToStart,
        viewMode: props.viewMode,
        concessionPeriod: props.rules.concessionPeriod,
        isHistory: props.isHistory,
        config: props.rules.configurations,
        isEmployee: props.isEmployee,
        typeVacation: props.typeVacation,
        acquistionPeriod: props.rules.acquistionPeriod,
        holidays: holidays,
    }), getTooltipSettings = _f.getTooltipSettings, shouldDisableDay = _f.shouldDisableDay, handleIsHoveringDay = _f.handleIsHoveringDay, validateDayOutsideConcessionPeriod = _f.validateDayOutsideConcessionPeriod;
    var handleStartAndEndDate = useCallback(function (params) {
        setFocusedInput(params.focusedInput);
        if (params.endDate) {
            var daysSelected = params.endDate.diff(params.startDate, "days") + 1;
            props.onChange(params.startDate, params.endDate, daysSelected);
        }
        if (!params.endDate) {
            props.onChange(params.startDate, params.endDate, 1);
        }
    }, [props]);
    var handleDayClick = function (day) {
        var startAndEndSelected = (startDate === null || startDate === void 0 ? void 0 : startDate.isValid()) && (endDate === null || endDate === void 0 ? void 0 : endDate.isValid());
        var holiday = checkHoliday(day.toDate(), holidays);
        if (!props.shouldEnablePicker) {
            notification.error("Selecione um colaborador");
            return;
        }
        if (shouldDisableDay(day))
            return;
        if (!startDate && isNearHoliday(day, holidays))
            return;
        if (validateDayOutsideConcessionPeriod(day))
            return;
        if (props.isHistory && isFutureDate(day))
            return;
        if (!startDate &&
            isBeforeMinimumDays(day, props.rules.configurations) &&
            props.isEmployee) {
            return handleStartAndEndDate({
                startDate: null,
                endDate: null,
                focusedInput: "startDate",
            });
        }
        if (!startDate && holiday) {
            return handleStartAndEndDate({
                startDate: null,
                endDate: null,
                focusedInput: "startDate",
            });
        }
        if (!startDate && isLastThreeDaysOfWeek(day, props.rules.configurations)) {
            return handleStartAndEndDate({
                startDate: null,
                endDate: null,
                focusedInput: "startDate",
            });
        }
        if (startAndEndSelected) {
            if (!isLastTwoDaysOfWeek(day) &&
                !isNearHoliday(day, holidays) &&
                !holiday) {
                handleStartAndEndDate({
                    startDate: day,
                    endDate: null,
                    focusedInput: "endDate",
                });
            }
        }
        if (!startDate) {
            if (!isLastTwoDaysOfWeek(day)) {
                handleStartAndEndDate({
                    startDate: day,
                    endDate: null,
                    focusedInput: "endDate",
                });
            }
            if (isOverLimit(day, props.rules.limitToStart, props.rules.remainingBalance)) {
                handleStartAndEndDate({
                    startDate: null,
                    endDate: null,
                    focusedInput: "startDate",
                });
            }
        }
        if (!endDate && startDate) {
            var isBeforeStartDate = day.isBefore(startDate);
            var daysSelected = day.diff(startDate, "days") + 1;
            var selectAllDays = daysSelected === props.rules.remainingBalance;
            if (daysSelected === 1) {
                handleStartAndEndDate({
                    startDate: null,
                    endDate: null,
                    focusedInput: "startDate",
                });
            }
            if (!props.rules.overFourteenDays &&
                daysSelected < 14 &&
                props.rules.remainingBalance - daysSelected < 14) {
                return;
            }
            if (!atLeastFiveDays(startDate, day) && !selectAllDays) {
                return;
            }
            if (exceedsAvailableDays(startDate, day, props.rules.remainingBalance) &&
                !selectAllDays) {
                return;
            }
            if (exceedMinimumReaminingDays(startDate, day, props.rules.remainingBalance) &&
                !selectAllDays) {
                return;
            }
            if (!isBeforeStartDate) {
                handleStartAndEndDate({
                    endDate: day,
                    startDate: startDate,
                    focusedInput: "startDate",
                });
            }
        }
    };
    var _g = useCalendarMonthsRules({
        typeVacation: props.typeVacation,
        viewMode: props.viewMode,
        concessionPeriod: props.rules.concessionPeriod,
        acquistionPeriod: props.rules.acquistionPeriod,
        isHistory: props.isHistory,
        period: props.period,
    }), initialVisibleMonthIsValid = _g.initialVisibleMonthIsValid, maxDate = _g.maxDate, minDate = _g.minDate, getInitialVisibleMonth = _g.getInitialVisibleMonth;
    if (!initialVisibleMonthIsValid)
        return null;
    return (_jsx(MuiPickersUtilsProvider, __assign({ utils: DateFnsUtils, locale: ptBrLocale }, { children: _jsxs(S.Container, { children: [_jsxs(S.Inputs, { children: [_jsx(KeyboardDatePicker, { onChange: function () { }, value: startDate, inputVariant: "outlined", size: "small", format: "dd/MM/yyyy", disabled: true }), _jsx(KeyboardDatePicker, { onChange: function () { }, value: endDate, inputVariant: "outlined", size: "small", format: "dd/MM/yyyy", disabled: true })] }), _jsx(DateRangePicker, { initialVisibleMonth: function () { return getInitialVisibleMonth(); }, disabled: !props.shouldEnablePicker, openDirection: "down", keepOpenOnDateSelect: true, isDayBlocked: shouldDisableDay, startDate: startDate, startDateId: "", endDate: endDate, endDateId: "", onDatesChange: function () { }, minDate: minDate, maxDate: maxDate, daySize: props.viewMode ? 30 : 34, hideKeyboardShortcutsPanel: true, focusedInput: focusedInput, onFocusChange: function (focusedInput) {
                        if (focusedInput) {
                            setFocusedInput(focusedInput);
                        }
                    }, isOutsideRange: function () { return false; }, renderCalendarDay: function (props) {
                        if (props && props.day) {
                            var _a = getTooltipSettings(props.day), title = _a.title, validationStatus = _a.validationStatus;
                            var isToday = props.day.isSame(moment(), "day");
                            var fakeKey = "".concat(props.day.toString());
                            return (_jsx(Tooltip, __assign({ placement: "top", title: title, classes: { tooltip: classes.rootTooltip } }, { children: _jsx(S.WrapperDay, __assign({ isToday: isToday, validationStatus: validationStatus, onMouseEnter: function () { return handleIsHoveringDay(true); }, onMouseLeave: function () { return handleIsHoveringDay(false); } }, { children: _createElement(CalendarDay, __assign({}, props, { onDayClick: handleDayClick, key: fakeKey })) })) })));
                        }
                        return (_jsx("td", __assign({ onMouseEnter: function () { return handleIsHoveringDay(true); }, onMouseLeave: function () { return handleIsHoveringDay(false); } }, { children: _jsx(CalendarDay, __assign({}, props, { onDayClick: handleDayClick })) })));
                    } })] }) })));
}
export default PeriodPicker;
