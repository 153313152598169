var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import styled from "styled-components";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 24px;\n  overflow-y: scroll;\n  height: calc(100vh - 180px);\n"], ["\n  padding: 24px;\n  overflow-y: scroll;\n  height: calc(100vh - 180px);\n"])));
export var DaysInfo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n\n  h2 {\n    font-weight: 600;\n    font-size: 16px;\n  }\n  padding-bottom: 32px;\n  border-bottom: 1px solid ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n\n  h2 {\n    font-weight: 600;\n    font-size: 16px;\n  }\n  padding-bottom: 32px;\n  border-bottom: 1px solid ", ";\n"])), Tokens.neutralColor.medium[700]);
export var PeriodInfo = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 32px;\n  h2 {\n    font-weight: 600;\n    font-size: 16px;\n  }\n\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"], ["\n  margin-top: 32px;\n  h2 {\n    font-weight: 600;\n    font-size: 16px;\n  }\n\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
