var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
export { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
var colors = __assign(__assign(__assign({}, Tokens.neutralColor), Tokens.feedbackColor), Tokens.interfaceColor);
