var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Radio } from "@material-ui/core";
import * as S from "./styles";
import { useStyles } from "./styles";
import { LuCalendarDays } from "react-icons/lu";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { Tokens } from "@caju-beneficios/sponsor-v2-lib/dist/configs/tokens/tokens";
export var vacationTypesConfig = {
    ANTICIPATION: {
        title: "Antecipação",
        description: "Permite solicitar férias antecipadas com saldo  do próximo período.",
        icon: (_jsx(MdKeyboardDoubleArrowLeft, { size: 24, color: Tokens.interfaceColor.primary.pure })),
        value: "ANTICIPATION",
    },
    SCHEDULING: {
        title: "Agendamento",
        description: "Permite planejar as próximas férias  com antecedência dentro do período correto.",
        icon: (_jsx(LuCalendarDays, { size: 24, color: Tokens.interfaceColor.primary.pure })),
        value: "SCHEDULING",
    },
};
export function CardTypeVacation(props) {
    var classes = useStyles();
    var config = vacationTypesConfig[props.type];
    return (_jsx(S.Container, { children: _jsx(Card, __assign({ className: classes.rootCard, variant: "outlined", onClick: function () { return props.onChange(props.type); } }, { children: _jsxs(S.CardContent, { children: [_jsx(Radio, { checked: props.selected, name: "".concat(props.type, "-radio"), color: "primary" }), _jsx(S.Icon, { children: config.icon }), _jsxs(S.Texts, { children: [_jsx("h5", { children: config === null || config === void 0 ? void 0 : config.title }), _jsx("p", { children: config === null || config === void 0 ? void 0 : config.description })] })] }) })) }));
}
