import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { makeStyles } from "@material-ui/core";
export var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        box: {
            paddingTop: 12,
            overflowAnchor: "none",
            overflowY: "hidden",
        },
        tableContainer: (_a = {
                overflowY: "auto",
                position: "relative",
                overflowAnchor: "none",
                height: 500
            },
            _a[theme.breakpoints.down("sm")] = {
                overflowX: "hidden",
                height: 470,
            },
            _a[theme.breakpoints.between("md", "lg")] = {
                height: "calc(100vh - 320px)",
            },
            _a[theme.breakpoints.up("xl")] = {
                height: 660,
            },
            _a),
        table: {
            borderRadius: 8,
            position: "relative",
            border: "1px solid ".concat(Tokens.neutralColor.light["800"]),
        },
        tableHead: {
            position: "sticky",
            top: '-1px',
            zIndex: 2,
            height: 56,
            background: Tokens.neutralColor.light["900"],
        },
        tableCell: {
            padding: "8px 16px",
            fontSize: "14px",
            lineHeight: Tokens.lineHight.xl,
            color: Tokens.neutralColor.dark["300"],
            fontWeight: 600,
            borderRight: "1px solid ".concat(Tokens.neutralColor.light["800"]),
            borderBottom: "1px solid ".concat(Tokens.neutralColor.light["800"]),
        },
        tableBody: {
            position: "relative",
            zIndex: 1,
        },
        tableRow: {
            background: Tokens.neutralColor.light["1000"],
            cursor: "pointer",
            "&:hover": {
                background: Tokens.neutralColor.light["900"],
            },
        },
        cell: {
            border: "1px solid ".concat(Tokens.neutralColor.light["800"]),
            fontSize: "14px",
            lineHeight: Tokens.lineHight.xl,
            color: Tokens.neutralColor.dark["100"],
            padding: "8px 16px",
        },
    });
});
