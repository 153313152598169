var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import styled from "styled-components";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: ", ";\n  align-items: center;\n  gap: 16px;\n  width: 100%;\n"], ["\n  display: grid;\n  grid-template-columns: ", ";\n  align-items: center;\n  gap: 16px;\n  width: 100%;\n"])), function (props) {
    return props.showResetButton ? "90% 10%" : "100%";
});
export var Card = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  padding: 16px;\n  border-radius: 8px;\n  display: flex;\n  gap: 8px;\n  height: 85px;\n"], ["\n  border: 1px solid ", ";\n  padding: 16px;\n  border-radius: 8px;\n  display: flex;\n  gap: 8px;\n  height: 85px;\n"])), Tokens.neutralColor.medium[700]);
export var EmployeePostion = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n\n  span {\n    font-weight: 500;\n    font-size: 12px;\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  gap: 8px;\n\n  span {\n    font-weight: 500;\n    font-size: 12px;\n    color: ", ";\n  }\n"])), Tokens.neutralColor.medium[400]);
export var EmployeeName = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n\n  h3 {\n    font-weight: 500;\n    font-size: 14px;\n  }\n"], ["\n  display: flex;\n  gap: 8px;\n\n  h3 {\n    font-weight: 500;\n    font-size: 14px;\n  }\n"])));
export var AdmittanceDate = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n\n  svg {\n    color: ", ";\n  }\n  span {\n    font-size: 14px;\n    font-weight: 400;\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n\n  svg {\n    color: ", ";\n  }\n  span {\n    font-size: 14px;\n    font-weight: 400;\n    color: ", ";\n  }\n"])), Tokens.neutralColor.dark[300], Tokens.neutralColor.dark[300]);
export var Info = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  gap: 16px;\n  align-items: center;\n"], ["\n  display: flex;\n  gap: 16px;\n  align-items: center;\n"])));
export var EmployeeInfo = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
