var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState, createContext } from 'react';
import Observable from '../../services/observable';
export var ObservableContext = createContext({
    update: function () { },
    observable: new Observable(),
});
export function ObservableProvider(_a) {
    var children = _a.children;
    var _b = __read(useState(new Observable()), 2), observable = _b[0], setObservable = _b[1];
    var context = useMemo(function () { return ({
        observable: observable,
        update: function () { setObservable(new Observable()); },
    }); }, [observable]);
    return (_jsx(ObservableContext.Provider, __assign({ value: context }, { children: children })));
}
