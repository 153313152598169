import { FormFieldType } from "@/services/form/types";
import {
  CpfTextField,
  PhoneTextField,
} from "@caju-beneficios/react-web-commons";
import ConditionalFormField from "../components/ConditionalFormField";
import FieldDropdown from "../components/DropdownField";
import FileField from "../components/FileField";
import TextField from "../components/TextField";
import {
  CepFieldProps,
  FieldConditionalSubFormProps,
  FieldDropdownProps,
  FieldFileProps,
  FieldMultiDropdownProps,
  FieldProps,
  FieldCheckboxProps,
} from "./types";

import CepField from "../components/CepField";
import ConditionalFormMultidropdown from "../components/ConditionalFormMultidropdownField";
import DateField from "../components/DateField";
import FieldMultiDropdown from "../components/MultiDropdownField";
import ReferenceDocFile from "../components/ReferenceDocFile";
import styled from "styled-components";
import { Tokens } from "@caju-beneficios/sponsor-v2-lib/dist/configs/tokens/tokens";
import { Checkbox } from "@material-ui/core";
import FieldTooltip from "../components/FieldTooltip";
import { getCustomLabel } from '@ciclos-frontend/common';

const CheckBoxAndTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  width: max-content;
  p {
    margin: 0;
    color: ${Tokens.neutralColor.dark["300"]};
    font-size: 16px;
  }
`;

type FormFieldRenderer = (props: any) => JSX.Element | null;

const formFieldRenderers: Record<FormFieldType, FormFieldRenderer> = {
  [FormFieldType.TEXT]: (props: FieldProps<string>) => {
    const _label = props.required ? `${props.label}*` : props.label;
    const _value = props.value ? props.value : "";
    const _helperText = props.error ?? props.description;

    return (
      <form autoComplete="off">
        <TextField
          placeholder={props.label}
          id={props.id}
          label={_label}
          disabled={props.disabled}
          error={!!props.error}
          helperText={_helperText}
          value={_value}
          size={props.isMobile ? "medium" : "small"}
          onChange={(event) => {
            if (props.onChange) {
              props?.onChange(event.target.value);
            }
          }}
          InputLabelProps={{
            shrink: Boolean(_value),
          }}
        />
      </form>
    );
  },
  [FormFieldType.DATE]: (props: FieldProps<string | null | undefined>) => {
    const _label = props.label;
    const _helperText = props.error ?? props.description;

    return (
      <DateField
        id={props.id}
        label={_label}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
        isMobile={props.isMobile}
        disabled={props.disabled}
        helperText={_helperText}
        error={props.error}
      />
    );
  },
  [FormFieldType.DROPDOWN]: (props: FieldDropdownProps) => {
    const _label = props.required ? `${props.label}*` : props.label;
    const _helperText = props.error ?? props.description;

    return (
      <FieldDropdown
        id={props.id}
        placeholder={props.label}
        required={props.required}
        label={_label}
        options={props.options}
        disabled={props.disabled}
        error={props.error}
        value={props.value}
        size={props.isMobile ? "medium" : "small"}
        onChange={(value) => {
          if (props.onChange) {
            props?.onChange(value);
          }
        }}
        helperText={_helperText}
      />
    );
  },
  [FormFieldType.MULTIDROPDOWN]: (props: FieldMultiDropdownProps) => {
    const _label = props.required ? `${props.label}*` : props.label;

    const value = props.value;

    const _helperText = props.error ?? props.description;

    const parsedValue =
      typeof value === "string"
        ? JSON.parse(value as unknown as string)
        : value;

    if (!parsedValue) {
      return (
        <FieldMultiDropdown
          id={props.id}
          placeholder={props.label}
          required={props.required}
          label={_label}
          options={props.options}
          disabled={props.disabled}
          error={props.error}
          value={value}
          size={props.isMobile ? "medium" : "small"}
          onChange={(value) => {
            if (props.onChange) {
              props?.onChange(value);
            }
          }}
          helperText={_helperText}
        />
      );
    }
    if (typeof parsedValue !== "object") {
      const resultString = String(parsedValue).replace(/["[\]]/g, "");
      const splittedString = resultString.split(",");

      return (
        <FieldMultiDropdown
          id={props.id}
          placeholder={props.label}
          required={props.required}
          label={_label}
          options={props.options}
          disabled={props.disabled}
          error={props.error}
          value={splittedString}
          size={props.isMobile ? "medium" : "small"}
          onChange={(value) => {
            if (props.onChange) {
              props?.onChange(value);
            }
          }}
          helperText={_helperText}
        />
      );
    }

    return (
      <FieldMultiDropdown
        id={props.id}
        placeholder={props.label}
        required={props.required}
        label={_label}
        options={props.options}
        disabled={props.disabled}
        error={props.error}
        value={parsedValue}
        size={props.isMobile ? "medium" : "small"}
        onChange={(value) => {
          if (props.onChange) {
            props?.onChange(value);
          }
        }}
      />
    );
  },
  [FormFieldType.CONDITIONAL_SUBFORM]: (
    props: FieldConditionalSubFormProps,
  ) => {
    const _label = props.required ? `${props.label}*` : props.label;

    if (props.options && props.options.length > 2) {
      return (
        <ConditionalFormMultidropdown
          value={props.value}
          label={_label}
          id={props.id}
          required={props.required}
          showForOptionValues={props.showForOptionValues}
          options={props.options}
          fields={props.fields}
          helpers={props.data?.helpers}
          disabled={props.disabled}
          onChange={(value) => {
            if (props.onChange) {
              props.onChange(value);
            }
          }}
          error={props.error}
          isMobile={props.isMobile}
        />
      );
    }

    return (
      <ConditionalFormField
        value={props.value}
        label={_label}
        id={props.id}
        required={props.required}
        showForOptionValues={props.showForOptionValues}
        options={props.options}
        fields={props.fields}
        disabled={props.disabled}
        onChange={(value) => {
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        error={props.error}
        isMobile={props.isMobile}
        additionalText={props.additionalText}
      />
    );
  },
  [FormFieldType.CEP]: (props: CepFieldProps) => {
    return <CepField {...props} />;
  },
  [FormFieldType.PHONE]: (props: FieldProps<string>) => {
    const _label = props.required ? `${props.label}*` : props.label;

    return (
      <PhoneTextField
        label={_label}
        variant={"outlined"}
        value={props.value}
        error={!!props.error}
        helperText={props.error}
        fullWidth
        size={props.isMobile ? "medium" : "small"}
        onChange={(event) => {
          if (props.onChange) {
            props.onChange(event.target.value);
          }
        }}
      />
    );
  },
  [FormFieldType.EMAIL]: (props: FieldProps<string>) => {
    const _label = props.required ? `${props.label}*` : props.label;

    return (
      <TextField
        placeholder={_label}
        id={props.id}
        label={_label}
        type="email"
        size={props.isMobile ? "medium" : "small"}
        disabled={props.disabled}
        error={!!props.error}
        helperText={props.error}
        value={props.value}
        onChange={(event) => {
          if (props.onChange) {
            props?.onChange(event.target.value);
          }
        }}
      />
    );
  },
  [FormFieldType.CPF]: (props: FieldProps<string>) => {
    const _label = props.required ? `${props.label}*` : props.label;

    return (
      <CpfTextField
        fullWidth
        size={props.isMobile ? "medium" : "small"}
        label={_label}
        variant={"outlined"}
        disabled={props.disabled}
        error={!!props.error}
        helperText={props.error}
        value={props.value}
        onChange={(event) => {
          if (props.onChange) {
            props?.onChange(event.target.value);
          }
        }}
      />
    );
  },
  [FormFieldType.NUMBER]: (props: FieldProps<string | null>) => {
    const _label = props.required ? `${props.label}*` : props.label;
    const _value = props.value ? String(props.value) : "";
    return (
      <TextField
        label={_label}
        variant={"outlined"}
        value={_value}
        helperText={props.error}
        onChange={(evt) => {
          const num = evt.target.value.replace(
            /[A-Za-z!@#$%^&*()_+={}:'"\\|,.<>?/`~]/g,
            "",
          );
          if (props.onChange) {
            props.onChange(num);
          }
        }}
        size={props.isMobile ? "medium" : "small"}
        fullWidth
      />
    );
  },
  [FormFieldType.MULTILINE_TEXT]: (props: FieldProps<string>) => {
    const _label = props.required ? `${props.label}*` : props.label;
    return (
      <TextField
        key={props.id}
        size={props.isMobile ? "medium" : "small"}
        placeholder={props.label}
        id={props.id}
        label={_label}
        type="email"
        disabled={props.disabled}
        multiline
        error={!!props.error}
        helperText={props.error}
        value={props.value}
        onChange={(event) => {
          if (props.onChange) {
            props?.onChange(event.target.value);
          }
        }}
      />
    );
  },
  [FormFieldType.FILE]: (props: FieldFileProps) => {
    const _label = props.required ? `${props.label}*` : props.label;

    if (props.data?.referenceFileKey && !props.value) {
      return (
        <div>
          <ReferenceDocFile
            label={props.label}
            error={props.error}
            accept={props.data?.accept}
            referenceFileKey={props.data?.referenceFileKey}
            value={props.value}
            maxSize={props.data?.maxSize}
            onChange={(value) => {
              if (props.onChange) {
                props.onChange(value);
              }
            }}
          />
        </div>
      );
    } else {
      return (
        <FileField
          label={_label}
          hidden={props.hidden}
          id={props.id}
          onChange={(value) => {
            if (props.onChange) {
              props.onChange(value);
            }
          }}
          data={props.data}
          size="large"
          variant="outlined"
          value={props.value}
          error={props.error}
          fullWidth={props.isMobile ? true : false}
          required={false}
          disabled={props.disabled}
        />
      );
    }
  },
  [FormFieldType.CHECKBOX]: (props: FieldCheckboxProps) => {
    const _label = props.required ? `${props.label}*` : props.label;
    return (
      <CheckBoxAndTitle id={props.id}>
        <Checkbox
          color="primary"
          checked={props.value == "Sim"}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange(e.target.checked ? "Sim" : "Não", props.id);
            }
          }}
          disabled={props.disabled}
        />
        <p>{_label}</p>
        {props.description && <FieldTooltip message={props.description} />}
      </CheckBoxAndTitle>
    );
  },
};

function getCustomDescription(
  formId: string,
  id: string,
  values: any,
  description?: string,
) {
  if (formId === "WORK_CARD" && values["TYPE"] === "Digital") {
    if (id === "NUMBER") {
      return "7 primeiros dígitos do CPF";
    }
    if (id === "SERIES") {
      return "4 últimos dígitos do CPF";
    }
  }
  return description;
}

export function renderFormField(
  type: FormFieldType,
  props:
    | FieldProps<string | undefined>
    | FieldDropdownProps
    | FieldConditionalSubFormProps
    | CepFieldProps,
  formId: string,
  values: any,
): JSX.Element | null {
  if (!type) return null;
  const renderer = formFieldRenderers[type];
  const description = getCustomDescription(
    formId,
    props.id,
    values,
    props.description,
  );
  const label = getCustomLabel(formId, props.id, props.label);
  if (!renderer({ ...props, description, label })) return null;
  return renderer({ ...props, description, label });
}
