export var DeadlineStatus;
(function (DeadlineStatus) {
    DeadlineStatus["HIGH"] = "HIGH";
    DeadlineStatus["MEDIUM"] = "MEDIUM";
    DeadlineStatus["OVERDUE"] = "OVERDUE";
    DeadlineStatus["LOW"] = "LOW";
    DeadlineStatus["NONE"] = "NONE";
    DeadlineStatus["ZERO"] = "ZERO";
    DeadlineStatus["NO_PERIOD"] = "NO_PERIOD";
})(DeadlineStatus || (DeadlineStatus = {}));
