var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Dialog from '@material-ui/core/Dialog';
import Card from '@material-ui/core/Card';
import { Button, makeStyles } from '@material-ui/core';
import { Tokens } from '@caju-beneficios/react-web-commons/dist/configs';
import { HiOutlineTrash } from 'react-icons/hi';
import * as S from './styles';
var useStyles = makeStyles({
    rootCard: {
        width: 375,
        padding: 32,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    button: {
        color: Tokens.neutralColor.light[1000],
    },
});
export function RemoveFormDialog(props) {
    var classes = useStyles();
    return (_jsx("div", { children: _jsx(Dialog, __assign({ onClose: props.onClose, open: props.isOpen }, { children: _jsxs(Card, __assign({ className: classes.rootCard }, { children: [_jsx(S.Title, __assign({ style: { marginBottom: "8px" } }, { children: props.title })), _jsxs(S.Description, { children: ["Deseja remover ", _jsx("span", __assign({ style: { fontWeight: "bold" } }, { children: props.spanText })), " dos seus dependentes?"] }), _jsxs(S.ContainerActionButtons, __assign({ style: { marginTop: "32px" } }, { children: [_jsx(Button, __assign({ className: 'remove-form-dialog', variant: "outlined", color: "primary", onClick: function () { return props.onClose(); } }, { children: "Voltar" })), _jsx(Button, __assign({ style: { background: Tokens.feedbackColor.error.pure }, variant: "contained", className: "".concat(classes.button, " remove-form-dialog"), startIcon: _jsx(HiOutlineTrash, { color: "#fff" }), onClick: function () { return props.onConfirm(); } }, { children: props.title }))] }))] })) })) }));
}
