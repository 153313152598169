var _a;
export var ErrorsCreate;
(function (ErrorsCreate) {
    ErrorsCreate["INVALID_VACATION_DATES"] = "INVALID_VACATION_DATES";
    ErrorsCreate["OVERLAPPING_VACATIONS"] = "OVERLAPPING_VACATIONS";
    ErrorsCreate["UNCONSUMED_VACATION_DAYS"] = "UNCONSUMED_VACATION_DAYS";
    ErrorsCreate["INVALID_MONETARY_ALLOWANCE"] = "INVALID_MONETARY_ALLOWANCE";
    ErrorsCreate["THIRTEENTH_SALARY_ADVANCE_ALREADY_USED"] = "THIRTEENTH_SALARY_ADVANCE_ALREADY_USED";
    ErrorsCreate["INVALID_CONCESSION_PERIOD"] = "INVALID_CONCESSION_PERIOD";
    ErrorsCreate["EXCEEDED_MINIMUM_REQUEST_TIME"] = "EXCEEDED_MINIMUM_REQUEST_TIME";
})(ErrorsCreate || (ErrorsCreate = {}));
export var errorsCreateArr = Object.values(ErrorsCreate);
export var messagesByErrorCreate = (_a = {},
    _a[ErrorsCreate.INVALID_VACATION_DATES] = "O período selecionado não estão em um período concessivo válido",
    _a[ErrorsCreate.OVERLAPPING_VACATIONS] = "Já existe um período de férias neste período selecionado.",
    _a[ErrorsCreate.UNCONSUMED_VACATION_DAYS] = "Todos os dias dísponiveis devem ser consumidos",
    _a[ErrorsCreate.INVALID_MONETARY_ALLOWANCE] = "Abono pecuniário já utilizado",
    _a[ErrorsCreate.THIRTEENTH_SALARY_ADVANCE_ALREADY_USED] = "Adiantamento da primeira parcela do 13º já utilizado",
    _a[ErrorsCreate.INVALID_CONCESSION_PERIOD] = "Período concessivo inválido",
    _a[ErrorsCreate.EXCEEDED_MINIMUM_REQUEST_TIME] = "O início de férias solicitado excede o limite mínimo de tempo de solicitação",
    _a);
