import moment from "moment-timezone";
export var useCalendarMonthsRules = function (props) {
    var _a;
    var isHistoryOrScheduling = props.typeVacation === "SCHEDULING" || props.isHistory;
    var isAnticipation = props.typeVacation === "ANTICIPATION";
    var getInitialVisibleMonth = function () {
        var _a;
        if (props.viewMode && props.period.startDate) {
            return props.period.startDate;
        }
        if ((!props.typeVacation && !props.isHistory) || isAnticipation) {
            return moment();
        }
        if (isHistoryOrScheduling) {
            return moment((_a = props.concessionPeriod) === null || _a === void 0 ? void 0 : _a.startDate, "YYYY-MM-DD");
        }
        return moment();
    };
    var getMinDate = function () {
        var _a;
        if (props.viewMode && props.period.startDate) {
            return props.period.startDate;
        }
        if ((!props.typeVacation && !props.isHistory) || isAnticipation) {
            return moment();
        }
        if (isHistoryOrScheduling) {
            return moment((_a = props.concessionPeriod) === null || _a === void 0 ? void 0 : _a.startDate, "YYYY-MM-DD");
        }
    };
    var getMaxDate = function () {
        var _a, _b;
        if (props.viewMode && props.period.endDate) {
            return props.period.endDate;
        }
        if (props.isHistory) {
            return moment();
        }
        if ((!props.typeVacation && !props.isHistory) || isHistoryOrScheduling) {
            return moment((_a = props.concessionPeriod) === null || _a === void 0 ? void 0 : _a.endDate, "YYYY-MM-DD");
        }
        if (isAnticipation) {
            return moment((_b = props.acquistionPeriod) === null || _b === void 0 ? void 0 : _b.endDate, "YYYY-MM-DD");
        }
    };
    var minDate = getMinDate();
    var maxDate = getMaxDate();
    var initialVisibleMonthIsValid = (_a = getInitialVisibleMonth()) === null || _a === void 0 ? void 0 : _a.isValid();
    var initialVisibleMonth = getInitialVisibleMonth();
    return {
        getInitialVisibleMonth: getInitialVisibleMonth,
        initialVisibleMonth: initialVisibleMonth,
        initialVisibleMonthIsValid: initialVisibleMonthIsValid,
        minDate: minDate,
        maxDate: maxDate,
    };
};
