var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import StepLabel from "@material-ui/core/StepLabel";
import { makeStyles } from "@material-ui/core/styles";
import MuiStep from "@material-ui/core/Step";
import { Tokens } from '@caju-beneficios/react-web-commons/dist/configs';
import { joinClass } from "../../utils/joinClass";
var useStyles = makeStyles({
    root: {
        color: Tokens.interfaceColor.primary.pure,
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    circle: {
        color: Tokens.neutralColor.dark[300],
        border: "2px solid ".concat(Tokens.neutralColor.dark[300]),
        width: 24,
        height: 24,
        borderRadius: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&.active': {
            border: "2px solid ".concat(Tokens.interfaceColor.primary.pure),
            color: Tokens.interfaceColor.primary.pure
        }
    }
});
function StepIcon(props) {
    var classes = useStyles();
    var completed = props.completed, icon = props.icon;
    return (_jsx("div", __assign({ className: classes.root }, { children: _jsx("div", __assign({ className: joinClass([classes.circle, completed && 'active']) }, { children: icon })) })));
}
export default function Step(_a) {
    var props = __rest(_a, []);
    return (_jsx(MuiStep, __assign({}, props, { children: _jsx(StepLabel, { StepIconComponent: StepIcon }) })));
}
