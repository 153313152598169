var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Dialog from "@material-ui/core/Dialog";
import Card from "@material-ui/core/Card";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import * as S from "./styles";
import React from "react";
import { Alert } from "@material-ui/lab";
var useStyles = makeStyles({
    rootCard: {
        width: 375,
        minHeight: 226,
        padding: 32,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
    },
    container: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& div": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginBottom: "8px",
        },
    },
});
export function ConfirmDialog(_a) {
    var onClose = _a.onClose, onConfirm = _a.onConfirm, isOpen = _a.isOpen, title = _a.title, description = _a.description, _b = _a.onCancelText, onCancelText = _b === void 0 ? "Voltar" : _b, _c = _a.confirmText, confirmText = _c === void 0 ? "Confirmar" : _c, isLoading = _a.isLoading, alertMessage = _a.alertMessage;
    var classes = useStyles();
    return (_jsx("div", { children: _jsx(Dialog, __assign({ onClose: onClose, open: isOpen }, { children: _jsx(Card, __assign({ className: classes.rootCard }, { children: isLoading ? (_jsx("div", __assign({ className: classes.container }, { children: _jsx(CircularProgress, { color: "primary" }) }))) : (_jsx(_Fragment, { children: _jsxs(S.ContainerContent, { children: [_jsx(S.Title, { children: title }), _jsx(S.Description, { children: description }), alertMessage ? (_jsx(Alert, __assign({ variant: "filled", color: "info", severity: "info" }, { children: alertMessage }))) : null, _jsxs(S.Actions, { children: [_jsx(Button, __assign({ variant: "outlined", color: "primary", onClick: function () { return onClose(); }, disabled: isLoading }, { children: onCancelText })), _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: function () { return onConfirm(); }, disabled: isLoading }, { children: confirmText }))] })] }) })) })) })) }));
}
