import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from "./styles";
import PeriodPicker from "../../../../../../../components/DateRangePicker";
import { AllowanceDaysOption, } from "./components/AllowanceDaysOption";
import { ThirteenthSalaryOption } from "./components/ThirteenthSalaryOption";
import React, { useMemo } from "react";
import { HistoryAlert } from "./components/HistoryAlert";
import { LocalLoading } from "@caju-beneficios/react-web-commons";
import { InputComment } from "./components/InputComment";
import { EmployeeInfo } from "../../../EmployeeInfo";
export var Settings = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var _remainingDays = useMemo(function () {
        if (!(props === null || props === void 0 ? void 0 : props.summary))
            return 0;
        if (props.allowanceDays)
            return (props === null || props === void 0 ? void 0 : props.summary.remainingBalance) - props.daysToSell;
        return props === null || props === void 0 ? void 0 : props.summary.remainingBalance;
    }, [props === null || props === void 0 ? void 0 : props.summary, props.allowanceDays, props.daysToSell]);
    if (props.isLoadingSummary) {
        return _jsx(LocalLoading, { show: true });
    }
    return (_jsxs(S.Container, { children: [_jsx(EmployeeInfo, { requester: props.requester, showResetButton: props.showResetButton, onClickReset: props.onClickReset }), _jsx(HistoryAlert, { isVisible: props.isHistory }), _jsxs(S.Period, { children: [_jsx("h5", { children: "Per\u00EDodo" }), _jsx("p", { children: "Indique os dias de in\u00EDcio e t\u00E9rmino do per\u00EDodo de f\u00E9rias." }), _jsx(PeriodPicker, { isHistory: props.isHistory, shouldEnablePicker: Boolean((_a = props === null || props === void 0 ? void 0 : props.summary) === null || _a === void 0 ? void 0 : _a.employee.id), period: props.period, rules: {
                            limitToStart: (_c = (_b = props === null || props === void 0 ? void 0 : props.summary) === null || _b === void 0 ? void 0 : _b.limitToStart) !== null && _c !== void 0 ? _c : "",
                            overFourteenDays: (_e = (_d = props === null || props === void 0 ? void 0 : props.summary) === null || _d === void 0 ? void 0 : _d.overFourteenDays) !== null && _e !== void 0 ? _e : false,
                            remainingBalance: _remainingDays,
                            shouldRequestOverFourteenDays: (_g = (_f = props === null || props === void 0 ? void 0 : props.summary) === null || _f === void 0 ? void 0 : _f.shouldRequestOverFourteenDays) !== null && _g !== void 0 ? _g : false,
                            concessionPeriod: (_h = props === null || props === void 0 ? void 0 : props.summary) === null || _h === void 0 ? void 0 : _h.concessionPeriod,
                            configurations: (_j = props.summary) === null || _j === void 0 ? void 0 : _j.configurations,
                            acquistionPeriod: (_k = props.summary) === null || _k === void 0 ? void 0 : _k.completedAcquisitionPeriod,
                        }, onChange: function (startDate, endDate, daysSelected) {
                            props.onChangePeriod(startDate, endDate, daysSelected);
                        }, isEmployee: props.requester === "EMPLOYEE", scheduling: props.scheduling, typeVacation: props.typeVacation })] }), _jsx(AllowanceDaysOption, { disableReason: props.disabledReasonAllowance, onChange: props.onChangeAllowance, shouldDisable: props.shouldEnableAllowanceOption, value: props.allowanceDays !== null
                    ? props.allowanceDays
                        ? "Sim"
                        : "Não"
                    : null }), _jsx(ThirteenthSalaryOption, { onChange: function (value) { return props.onChangeThirteenSalary(value); }, value: props.thirteenthSalaryAdvance ? "Sim" : "Não", shouldDisable: (_l = props.summary) === null || _l === void 0 ? void 0 : _l.thirteenthSalaryAdvance }), _jsx(InputComment, { onBlur: props.onBlurComment })] }));
});
