import { jsx as _jsx } from "react/jsx-runtime";
import { Tokens } from "@caju-beneficios/sponsor-v2-lib/dist/configs/tokens/tokens";
import { AccessTime, BackspaceOutlined, CheckRounded, } from "@material-ui/icons";
var commonCssProperties = { width: "18px", height: "18px" };
export var flagProperties = {
    pending: {
        backgroundColor: Tokens.feedbackColor.warning.light,
        color: Tokens.feedbackColor.warning.dark,
        icon: _jsx(AccessTime, { style: commonCssProperties }),
    },
    completed: {
        backgroundColor: Tokens.feedbackColor.success.light,
        color: Tokens.feedbackColor.success.dark,
        icon: _jsx(CheckRounded, { style: commonCssProperties }),
    },
    expired: {
        backgroundColor: Tokens.feedbackColor.error.light,
        color: Tokens.feedbackColor.error.dark,
        icon: _jsx(BackspaceOutlined, { style: commonCssProperties }),
    },
};
