import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InfoDays } from "./components/InfoDays";
import { InfoPeriodDate } from "./components/InfoPeriodDate";
import * as S from "./styles";
import { formatToBrazilianDate } from "../../../../../../../utils";
import { AnticipationChip } from "../../../../../AnticipationChip";
export var Details = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var getFinalBalance = function () {
        var _a, _b;
        if (!props.selectedDays && !props.allowanceDays)
            return 0;
        return (((_b = (_a = props.summary) === null || _a === void 0 ? void 0 : _a.remainingBalance) !== null && _b !== void 0 ? _b : 0) -
            props.selectedDays -
            props.allowanceDays);
    };
    var admissionDateFormatted = formatToBrazilianDate((_b = (_a = props.summary) === null || _a === void 0 ? void 0 : _a.employee) === null || _b === void 0 ? void 0 : _b.admittanceDate);
    var limitDateFormatted = formatToBrazilianDate((_c = props.summary) === null || _c === void 0 ? void 0 : _c.limitToStart);
    var completedAcquisitionPeriodFormatted = "".concat(formatToBrazilianDate((_e = (_d = props.summary) === null || _d === void 0 ? void 0 : _d.completedAcquisitionPeriod) === null || _e === void 0 ? void 0 : _e.startDate), " - ").concat(formatToBrazilianDate((_g = (_f = props.summary) === null || _f === void 0 ? void 0 : _f.completedAcquisitionPeriod) === null || _g === void 0 ? void 0 : _g.endDate));
    var isAnticipation = props.typeVacation === "ANTICIPATION";
    return (_jsxs(S.Container, { children: [isAnticipation ? _jsx(AnticipationChip, {}) : null, _jsxs(S.DaysInfo, { children: [_jsx("h2", { children: "Detalhes da sele\u00E7\u00E3o" }), _jsx(InfoDays, { variant: "balance", value: (_j = (_h = props.summary) === null || _h === void 0 ? void 0 : _h.remainingBalance) !== null && _j !== void 0 ? _j : 0 }), _jsx(InfoDays, { variant: "selected", value: props.selectedDays }), _jsx(InfoDays, { variant: "allowance", value: props.allowanceDays }), _jsx(InfoDays, { variant: "remaining", value: getFinalBalance() })] }), _jsxs(S.PeriodInfo, { children: [_jsx("h2", { children: "Per\u00EDodo aquisitivo" }), _jsx(InfoPeriodDate, { title: "Data de admiss\u00E3o", value: admissionDateFormatted }), _jsx(InfoPeriodDate, { title: "Limite para in\u00EDcio das f\u00E9rias", value: limitDateFormatted }), _jsx(InfoPeriodDate, { title: "Per\u00EDodo aquisitivo conclu\u00EDdo", value: completedAcquisitionPeriodFormatted })] })] }));
};
