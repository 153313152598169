var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { VacationFollowUpStatus } from "../../../../services/vacations/followUp/types";
import { Chip } from "@material-ui/core";
import styled, { css } from "styled-components";
var getColors = function (status) {
    switch (status) {
        case VacationFollowUpStatus.AWAITING_APPROVAL:
            return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        background-color: ", ";\n        color: ", ";\n      "], ["\n        background-color: ", ";\n        color: ", ";\n      "])), Tokens.categoryColor.food.light, Tokens.neutralColor.dark[100]);
        case VacationFollowUpStatus.PENDING_REVIEW:
            return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        background-color: ", ";\n        color: ", ";\n      "], ["\n        background-color: ", ";\n        color: ", ";\n      "])), Tokens.categoryColor.transportation.light, Tokens.neutralColor.dark[100]);
        case VacationFollowUpStatus.APPROVED_IN_VACATION:
            return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        background-color: ", ";\n        color: ", ";\n      "], ["\n        background-color: ", ";\n        color: ", ";\n      "])), Tokens.categoryColor.health.light, Tokens.neutralColor.dark[100]);
        case VacationFollowUpStatus.APPROVED_SCHEDULED:
            return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        background-color: ", ";\n        color: ", ";\n      "], ["\n        background-color: ", ";\n        color: ", ";\n      "])), Tokens.categoryColor.culture.light, Tokens.neutralColor.dark[100]);
        case VacationFollowUpStatus.DENIED:
            return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        background-color: ", ";\n        color: ", ";\n      "], ["\n        background-color: ", ";\n        color: ", ";\n      "])), Tokens.feedbackColor.error.light, Tokens.neutralColor.dark[100]);
        default:
            return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n        background-color: ", ";\n        color: ", ";\n      "], ["\n        background-color: ", ";\n        color: ", ";\n      "])), Tokens.categoryColor.health.light, Tokens.neutralColor.dark[100]);
    }
};
export var Flag = styled(Chip)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: 24px 0;\n  ", ";\n"], ["\n  margin: 24px 0;\n  ", ";\n"])), function (props) { return getColors(props.status); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
