var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import moment from "moment-timezone";
import { useCallback, useState } from "react";
import { isLastThreeDaysOfWeek, atLeastFiveDays, exceedsAvailableDays, exceedMinimumReaminingDays, isOverLimit, isOutsideConcessionPeriod, isPastDate, isFutureDate, isLastTwoDaysOfWeek, isBeforeMinimumDays, } from "./helpers";
import { checkHoliday } from "../../../utils/checkHoliday";
export var DateValidationStatus;
(function (DateValidationStatus) {
    DateValidationStatus[DateValidationStatus["MINIMUM_DAYS"] = 0] = "MINIMUM_DAYS";
    DateValidationStatus[DateValidationStatus["NOT_ALLOWED"] = 1] = "NOT_ALLOWED";
    DateValidationStatus[DateValidationStatus["MINIMUM_REMAINING"] = 2] = "MINIMUM_REMAINING";
    DateValidationStatus[DateValidationStatus["VALID"] = 3] = "VALID";
    DateValidationStatus[DateValidationStatus["VIEW"] = 4] = "VIEW";
})(DateValidationStatus || (DateValidationStatus = {}));
function differenceInDays(date1, date2) {
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);
    var diffInMs = Math.abs(date1.getTime() - date2.getTime());
    return diffInMs / (1000 * 60 * 60 * 24);
}
export function isNearHoliday(day, holidays) {
    return holidays
        .filter(function (holiday) { return day.toDate() < holiday.start; })
        .some(function (holiday) {
        var diffDays = differenceInDays(holiday.start, day.toDate());
        return diffDays === 1 || diffDays === 2;
    });
}
export var usePickerRules = function (props) {
    var _a;
    var isAnticipation = props.typeVacation === "ANTICIPATION";
    var validateDayOutsideConcessionPeriod = useCallback(function (day) {
        if (isAnticipation) {
            return isOutsideConcessionPeriod(day, props.acquistionPeriod);
        }
        else {
            return isOutsideConcessionPeriod(day, props.concessionPeriod);
        }
    }, [isAnticipation, props.acquistionPeriod, props.concessionPeriod]);
    var _b = __read(useState(false), 2), isHoveringDay = _b[0], setIsHoveringDay = _b[1];
    var shouldDisableDay = useCallback(function (day) {
        var _a, _b, _c, _d;
        if ((_a = props.startDate) === null || _a === void 0 ? void 0 : _a.isValid)
            return false;
        var holiday = checkHoliday(day.toDate(), props.holidays);
        if (!((_b = props.startDate) === null || _b === void 0 ? void 0 : _b.isValid) && holiday)
            return true;
        if (!((_c = props.startDate) === null || _c === void 0 ? void 0 : _c.isValid) && isNearHoliday(day, props.holidays))
            return true;
        if (!props.isHistory && isPastDate(day))
            return true;
        if (props.isHistory && isFutureDate(day))
            return true;
        if (validateDayOutsideConcessionPeriod(day))
            return true;
        if (props.isEmployee && isBeforeMinimumDays(day, props.config))
            return true;
        if (!((_d = props.startDate) === null || _d === void 0 ? void 0 : _d.isValid)) {
            if (isLastTwoDaysOfWeek(day))
                return true;
        }
        if (isLastThreeDaysOfWeek(day, props.config))
            return true;
        return false;
    }, [
        (_a = props.startDate) === null || _a === void 0 ? void 0 : _a.isValid,
        props.holidays,
        props.isHistory,
        props.isEmployee,
        props.config,
        validateDayOutsideConcessionPeriod,
    ]);
    var getTooltipSettings = useCallback(function (day) {
        var _a, _b, _c, _d, _e, _f;
        var holiday = checkHoliday(day.toDate(), props.holidays);
        var selectedDays = day.diff(props.startDate, "days") + 1;
        var hasStartDate = (_a = props.startDate) === null || _a === void 0 ? void 0 : _a.isValid();
        var hasEndDate = (_b = props.endDate) === null || _b === void 0 ? void 0 : _b.isValid();
        var isDayDisabled = shouldDisableDay(day);
        var startAndEndSelected = ((_c = props.startDate) === null || _c === void 0 ? void 0 : _c.isValid) && ((_d = props.endDate) === null || _d === void 0 ? void 0 : _d.isValid);
        var selectAllDays = selectedDays === props.availableDays;
        var isToday = day.isSame(moment(), "day");
        if (props.viewMode) {
            return {
                title: "",
                validationStatus: DateValidationStatus.VIEW,
            };
        }
        if (!((_e = props.startDate) === null || _e === void 0 ? void 0 : _e.isValid) && holiday) {
            return {
                validationStatus: DateValidationStatus.NOT_ALLOWED,
                title: "N\u00E3o \u00E9 poss\u00EDvel selecionar (".concat(holiday.name, ")"),
            };
        }
        if (validateDayOutsideConcessionPeriod(day)) {
            return {
                validationStatus: DateValidationStatus.NOT_ALLOWED,
                title: "Não é possível selecionar datas fora do período concessivo.",
            };
        }
        if (props.isHistory && !isPastDate(day)) {
            return {
                validationStatus: DateValidationStatus.NOT_ALLOWED,
                title: "Não é possível selecionar datas futuras para adição de historico",
            };
        }
        if (!props.isHistory && isPastDate(day)) {
            return {
                validationStatus: DateValidationStatus.NOT_ALLOWED,
                title: "Não é possível selecionar datas retroativas para um solicitação de férias",
            };
        }
        if (isDayDisabled && isNearHoliday(day, props.holidays)) {
            return {
                validationStatus: DateValidationStatus.NOT_ALLOWED,
                title: "N\u00E3o \u00E9 permitido iniciar f\u00E9rias at\u00E9 2 dias antes de um feriado.",
            };
        }
        if (hasStartDate && !hasEndDate) {
            if (exceedsAvailableDays(props.startDate, day, props.availableDays) &&
                !selectAllDays) {
                return {
                    validationStatus: DateValidationStatus.NOT_ALLOWED,
                    title: "A seleção não pode ser maior que o saldo de dias disponíveis",
                };
            }
            if (!atLeastFiveDays(props.startDate, day) && !selectAllDays)
                return {
                    validationStatus: DateValidationStatus.MINIMUM_DAYS,
                    title: "É necessário selecionar pelo menos 5 dias",
                };
            if (props.shouldRequestOverFourteenDays && selectedDays < 14) {
                return {
                    validationStatus: DateValidationStatus.MINIMUM_DAYS,
                    title: "É necessário selecionar pelo menos 14 dias",
                };
            }
            if (!props.overFourteenDays &&
                selectedDays < 14 &&
                props.availableDays - selectedDays < 14) {
                return {
                    validationStatus: DateValidationStatus.MINIMUM_REMAINING,
                    title: "A sobra de saldo não pode ser menor que 14 dias",
                };
            }
            if (exceedMinimumReaminingDays(props.startDate, day, props.availableDays) &&
                !selectAllDays) {
                return {
                    validationStatus: DateValidationStatus.MINIMUM_REMAINING,
                    title: "A sobra de saldo não pode ser menor que 5 dias",
                };
            }
        }
        if (startAndEndSelected && isHoveringDay && isLastThreeDaysOfWeek(day)) {
            return {
                validationStatus: DateValidationStatus.NOT_ALLOWED,
                title: "Não é possível selecionar como data de início",
            };
        }
        if (!isToday &&
            isBeforeMinimumDays(day, props.config) &&
            props.isEmployee) {
            return {
                validationStatus: DateValidationStatus.NOT_ALLOWED,
                title: "A solicita\u00E7\u00E3o precisa ser feita com ".concat((_f = props.config) === null || _f === void 0 ? void 0 : _f.minimumRequestTime, " dias de anteced\u00EAncia "),
            };
        }
        if (!hasStartDate && !isDayDisabled) {
            if (isToday) {
                return {
                    validationStatus: DateValidationStatus.VALID,
                    title: "Hoje",
                };
            }
            return {
                validationStatus: DateValidationStatus.VALID,
                title: "Selecionar a data de início",
            };
        }
        if (day.isSame(props.startDate))
            return {
                validationStatus: DateValidationStatus.VALID,
                title: "Ínicio",
            };
        if (isLastThreeDaysOfWeek(day, props.config) && !hasStartDate)
            return {
                validationStatus: DateValidationStatus.NOT_ALLOWED,
                title: "Não é possível selecionar como data de início",
            };
        if (day.isAfter(props.endDate) || day.isBefore(props.startDate)) {
            return {
                validationStatus: DateValidationStatus.VALID,
                title: "",
            };
        }
        if (!hasStartDate &&
            isOverLimit(day, props.limitToStart, props.availableDays)) {
            return {
                validationStatus: DateValidationStatus.NOT_ALLOWED,
                title: "Dia ultrapassa a data limite para ínicio",
            };
        }
        if (day.isBetween(props.startDate, props.endDate))
            return {
                validationStatus: DateValidationStatus.VALID,
                title: "".concat(selectedDays, " dias"),
            };
        return {
            validationStatus: DateValidationStatus.VALID,
            title: "".concat(selectedDays, " dias"),
        };
    }, [
        props.holidays,
        props.startDate,
        props.endDate,
        props.availableDays,
        props.viewMode,
        props.isHistory,
        props.config,
        props.isEmployee,
        props.limitToStart,
        props.shouldRequestOverFourteenDays,
        props.overFourteenDays,
        shouldDisableDay,
        validateDayOutsideConcessionPeriod,
        isHoveringDay,
    ]);
    var getMaxDate = useCallback(function () {
        var _a;
        var limitDayFromStartDate = (_a = props.startDate) === null || _a === void 0 ? void 0 : _a.add(props.availableDays);
        return limitDayFromStartDate !== null && limitDayFromStartDate !== void 0 ? limitDayFromStartDate : null;
    }, [props.startDate, props.availableDays]);
    var handleIsHoveringDay = useCallback(function (hovering) {
        setIsHoveringDay(hovering);
    }, []);
    return {
        getTooltipSettings: getTooltipSettings,
        shouldDisableDay: shouldDisableDay,
        handleIsHoveringDay: handleIsHoveringDay,
        getMaxDate: getMaxDate,
        validateDayOutsideConcessionPeriod: validateDayOutsideConcessionPeriod,
    };
};
