import { makeStyles } from "@material-ui/core";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
export var useStyles = makeStyles({
    title: {
        fontWeight: 600,
        color: Tokens.neutralColor.dark[100],
        fontSize: "16px",
    },
    email: {
        fontSize: "12px",
        fontWeight: 500,
        color: Tokens.neutralColor.medium[400],
        marginTop: "8px",
    },
});
