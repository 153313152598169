export var EmployeeDocumentCategory;
(function (EmployeeDocumentCategory) {
    EmployeeDocumentCategory["DEFAULT"] = "DEFAULT";
    EmployeeDocumentCategory["CORE_RH"] = "CORE_RH";
})(EmployeeDocumentCategory || (EmployeeDocumentCategory = {}));
export var SignatureStatus;
(function (SignatureStatus) {
    SignatureStatus["PENDING"] = "PENDING";
    SignatureStatus["COMPLETED"] = "COMPLETED";
    SignatureStatus["EXPIRED"] = "EXPIRED";
})(SignatureStatus || (SignatureStatus = {}));
