var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { makeStyles } from "@material-ui/core";
import styled from "styled-components";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var CardContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 42px 42px 1fr;\n  align-items: center;\n  gap: 16px;\n"], ["\n  display: grid;\n  grid-template-columns: 42px 42px 1fr;\n  align-items: center;\n  gap: 16px;\n"])));
export var Icon = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 8px;\n  width: 42px;\n  height: 42px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  background-color: ", ";\n  border-radius: 8px;\n  width: 42px;\n  height: 42px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), Tokens.interfaceColor.primary.light);
export var Texts = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  h5 {\n    font-size: 20px;\n    font-weight: 600;\n  }\n\n  p {\n    font-size: 14px;\n    color: ", ";\n  }\n"], ["\n  h5 {\n    font-size: 20px;\n    font-weight: 600;\n  }\n\n  p {\n    font-size: 14px;\n    color: ", ";\n  }\n"])), Tokens.neutralColor.medium[400]);
export var useStyles = makeStyles({
    rootCard: {
        padding: 16,
        borderRadius: "16px",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: Tokens.neutralColor.light[900],
        },
        "&:active": {
            backgroundColor: Tokens.neutralColor.light[800],
        },
    },
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
