var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Grid, Typography, makeStyles, } from "@material-ui/core";
import { Juca } from "./components/Juca";
import * as S from "./styles";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
var useStyles = makeStyles({
    rootCard: {
        minHeight: "146px",
        width: "512px",
        display: "flex",
        alignItems: "flex-end",
        gap: 16,
        justifyContent: "flex-start",
        borderRadius: Tokens.borderRadius.medium,
        paddingLeft: "20px"
    },
});
/**
 * @example
 * <EmptyState
 *  sizeJuca='md'
 *  title='Nada por aqui…'
 *  description="Clique no botão “Solicitar férias” para fazer uma solicitação."
 * />
 * ---
 * @name EmptyState
 * @param title?: string;
 * @param description: string;
 * @param sizeJuca: SizJuca;
 */
export function EmptyState(_a) {
    var _b = _a.title, title = _b === void 0 ? 'Nada por aqui…' : _b, description = _a.description, _c = _a.sizeJuca, sizeJuca = _c === void 0 ? 'md' : _c, additionalAction = _a.additionalAction;
    var classes = useStyles();
    var isSmallJuca = sizeJuca === 'sm';
    return (_jsx(Grid, __assign({ item: true, xs: 10, "data-testid": "empty-state" }, { children: _jsxs(Card, __assign({ variant: "outlined", className: classes.rootCard }, { children: [isSmallJuca ? _jsx(Juca.SM, {}) : _jsx(Juca.MD, {}), _jsxs(S.CardContent, __assign({ style: { paddingTop: isSmallJuca ? 0 : 30 } }, { children: [_jsx(Typography, __assign({ variant: "subtitle2", style: { color: Tokens.neutralColor.dark[100], marginBottom: "16px" } }, { children: title })), _jsx(Typography, __assign({ variant: "caption", style: { color: Tokens.neutralColor.medium[400], fontSize: "14px" } }, { children: description })), additionalAction] }))] })) })));
}
