var Observable = /** @class */ (function () {
    function Observable() {
        this.subscriptions = [];
    }
    Observable.prototype.emit = function (data) {
        this.subscriptions.forEach(function (subscription) {
            subscription.observer(data);
        });
    };
    Observable.prototype.subscribe = function (observer) {
        var _this = this;
        var id = crypto.randomUUID();
        var length = this.subscriptions.push({
            id: id,
            observer: observer,
            unsubscribe: function () { return _this.unsubscribe(id); }
        });
        return this.subscriptions[length - 1];
    };
    Observable.prototype.unsubscribe = function (id) {
        this.subscriptions = this.subscriptions.filter(function (sub) { return sub.id !== id; });
    };
    return Observable;
}());
export default Observable;
