var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import styled from "styled-components";
export var ContainerActionButtons = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
export var Title = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 20px;\n  font-weight: 600;\n"], ["\n  font-size: 20px;\n  font-weight: 600;\n"])));
export var Description = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 400;\n  font-size: 16px;\n  color:", "\n"], ["\n  font-weight: 400;\n  font-size: 16px;\n  color:", "\n"])), Tokens.neutralColor.medium[400]);
var templateObject_1, templateObject_2, templateObject_3;
