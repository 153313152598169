var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Card from "@material-ui/core/Card";
import { Button, makeStyles, Typography } from "@material-ui/core";
import * as S from "./styles";
import { Tokens } from "@caju-beneficios/sponsor-v2-lib/dist/configs/tokens/tokens";
import { LocalLoading } from "@caju-beneficios/react-web-commons";
import { MdOutlineClose, MdCheck } from "react-icons/md";
var useStyles = makeStyles({
    rootCard: {
        width: "375px",
        height: "342px",
        padding: 32,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
    },
    outlinedSuccess: {
        borderColor: Tokens.feedbackColor.success.pure,
        color: Tokens.feedbackColor.success.pure,
    },
});
export function SaveEditCard(_a) {
    var onClose = _a.onClose, onConfirm = _a.onConfirm, onClickBack = _a.onClickBack, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b;
    var classes = useStyles();
    return (_jsx("div", { children: _jsx(Card, __assign({ className: classes.rootCard }, { children: isLoading ? (_jsx(LocalLoading, { show: true })) : (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "subtitle1", style: {
                            fontWeight: 600,
                            fontSize: 20,
                            color: Tokens.neutralColor.dark[100],
                        } }, { children: "Deseja salvar a edi\u00E7\u00E3o?" })), _jsx("div", { style: { marginTop: 8 } }), _jsx(Typography, __assign({ variant: "body1", style: { fontSize: 16, color: Tokens.neutralColor.medium[400] } }, { children: "Voc\u00EA tem altera\u00E7\u00F5es nesta aba que n\u00E3o foram salvas." })), _jsx("div", { style: { marginTop: 32 } }), _jsxs(S.Actions, { children: [_jsx(Button, __assign({ variant: "outlined", className: classes.outlinedSuccess, onClick: function () { return onConfirm(); }, startIcon: _jsx(MdCheck, {}) }, { children: "Salvar edi\u00E7\u00E3o" })), _jsx(Button, __assign({ variant: "outlined", color: "secondary", onClick: function () { return onClose(); }, startIcon: _jsx(MdOutlineClose, {}) }, { children: "Continuar sem salvar" })), _jsx(Button, __assign({ variant: "text", color: "primary", onClick: function () { return onClickBack(); }, startIcon: _jsx("i", { className: "uil uil-trash-alt", style: {
                                        fontSize: 20,
                                        color: Tokens.neutralColor.light[1000],
                                    } }) }, { children: "Voltar para edi\u00E7\u00E3o" }))] })] })) })) }));
}
