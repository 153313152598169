import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from "@material-ui/core";
import { KeyboardArrowDownRounded } from "@material-ui/icons";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  isExpanded: boolean;
  title: string | ReactNode;
}

const useStyles = makeStyles({
  accordionSummary: {
    padding: 0,
    "& p": {
      fontSize: "16px",
      fontWeight: 600,
      color: Tokens.neutralColor.dark[300],
    },
  },
  accordionDetailsRoot: {
    padding: 0,
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'column',
    "& > div": {
      width: "100%",
    },
  },
});

export default function CustomAccordion({
  isExpanded,
  children,
  title
}: Props) {
  const classes = useStyles();

  return (
    <Accordion
      style={{ backgroundColor: Tokens.neutralColor.light[1000] }}
      defaultExpanded={isExpanded}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownRounded />}
        classes={{
          root: classes.accordionSummary,
        }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.accordionDetailsRoot,
        }}
      >
        {children}
      </AccordionDetails>
      <div style={{ marginTop: "24px" }} />
    </Accordion>
  )
}