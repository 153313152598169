import { makeStyles } from "@material-ui/core/styles";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
export var useStyles = makeStyles(function (_a) {
    var palette = _a.palette;
    return ({
        tableContainer: {
            borderRadius: Tokens.borderRadius.medium,
            border: "1px solid ".concat(Tokens.neutralColor.light[800]),
            overflow: 'hidden'
        },
        table: {
            borderRadius: "inherit",
            "& .MuiTableCell-root": {
                borderRight: "1px solid ".concat(Tokens.neutralColor.light[800]),
            },
            "& .MuiTableCell-root:last-child": {
                borderRight: 'none',
            },
            "& tr:last-child .MuiTableCell-body": {
                borderBottom: 'none'
            }
        },
        header: {
            '& .MuiTableCell-root': {
                backgroundColor: Tokens.neutralColor.light["900"]
            }
        },
        cell: {
            color: Tokens.neutralColor.dark["300"],
            background: palette.background.paper
        }
    });
});
