var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip, IconButton, makeStyles, createStyles, } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
var useStyles = makeStyles(function () {
    return createStyles({
        root: {
            padding: 0,
            marginLeft: 16,
            borderRadius: "50%",
            color: Tokens.neutralColor.medium[400],
            "&:hover": {
                background: "transparent",
            },
        },
    });
});
export default function FieldTooltip(props) {
    var classes = useStyles();
    return (_jsx(Tooltip, __assign({ arrow: true, title: props.message, className: classes.root }, { children: _jsx(IconButton, __assign({ "aria-label": "info", color: "primary" }, { children: _jsx(InfoOutlinedIcon, {}) })) })));
}
